import { isSome } from 'helpers/utils';
import { NOTES } from 'modules/common';

/*******************
 * Action Types
 *******************/

/*******************
 * Action Creators
 *******************/

export const appendNote = (noteId: Numberish) => ({
  type: NOTES.APPEND_NOTE,
  payload: noteId,
});

export const setNotesOrder = (notes: Numberish[]) => ({
  type: NOTES.SET_ORDER,
  payload: notes,
});

export interface NotesCollectionAction {
  type: string;
  payload: Numberish | Numberish[];
}

/*******************
 * Reducer
 *******************/

export const notesCollectionReducer = (
  state: Numberish[],
  action: NotesCollectionAction
) => {
  switch (action.type) {
    case NOTES.APPEND_NOTE: {
      if (!Array.isArray(action.payload) && !state.includes(action.payload)) {
        return [...state, action.payload];
      }
      return state;
    }
    case NOTES.SET_ORDER: {
      return action.payload;
    }
    default: {
      return isSome(state) ? state : [];
    }
  }
};
