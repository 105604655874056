/**
 * @enum {string}
 */
export const MESSAGES = {
  ERROR: {
    ARCHIVE: {
      FILE: 'Network Error. Unable to archive file.',
      PROJECT: 'Network Error. Unable to archive Project.',
      READONLY: 'Cannot archive a read-only file',
    },
    UNSUPPORTED: 'This feature is not supported by your browser.',
    FILES_ONLY: 'Only Files Are Allowed.',
    DISPLAY: {
      QUARANTINED:
        'images are still being scanned or have been flagged by the antivirus scanner.\nPlease wait as they should appear soon.',
      BAD: 'images are no longer available. You can try to replace them.',
      UNKNOWN: 'images could not be displayed due to an unknown error.',
    },
    GET: {
      PROJECT: 'Network Error. Unable to view Projects.',
      ARCHIVED_PROJECTS: 'Network Error. Unable to view archived Projects.',
      MODEL: 'Network Error. Unable to view model.',
      MEDIA: 'Network Error. Unable to view files.',
      METRICS: 'Unable to retrieve metrics.',
      ARCHIVED_MEDIA: 'Network Error. Unable to view archived files.',
      MEDIA_ID: 'Network Error. Unable to view file.',
      MODEL_NOTE: 'Network Error. Unable to retrieve model note',
      PROJECT_ID: 'Network Error. Unable to view project.',
      MODIFIED_MEDIA: 'Network Error. Unable to view modified media.',
      USER_DATA: 'Network Error. Unable to view your account information.',
      USER_DETAILS: 'Network Error. Unable to retrieve user details.',
      COLLECTION: 'Network Error. Failed to load more records.',
      VERSION: 'Invalid version.',
      ORG_ADMINS: 'Unable to retrieve organization admins.',
      ORG_USERS: 'Unable to retrieve organization users.',
      GUILD_USERS: 'Unable to retrieve guild users.',
      VALIDATION: 'Unable to retrieve model validation information.',
    },
    DOWNLOAD: {
      MEDIA: 'Network Error. Failed to download media.',
    },
    IMPORT: {
      TOO_MANY_MODEL_PROPS:
        'More than two columns were imported, extra columns were ignored.',
    },
    INIT: {
      WORKFLOW: 'Link not recognized or expired.',
    },
    DELETE: {
      API_KEY: 'Failed to delete API Keys.',
      FILE: 'Network Error. Unable to delete file.',
      PROJECT: 'Network Error. Unable to delete Project.',
      READONLY: 'Cannot delete a read-only file.',
    },
    CANCEL: {
      FILE: 'Unable to cancel file upload. It may have already finished.',
    },
    RENAME: {
      MODEL: 'Model with given name already exists.',
      MEDIA: 'Media with given name already exists.',
      READONLY: 'Cannot overwrite a read-only file.',
      NOTE: 'Note names must be unique.',
      GUILD: 'Error. A guild with this name already exists.',
    },
    MOVE: {
      CONFLICT: 'Conflict Error. Failed to move file.',
      FILE: 'Failed to move files: network error.',
      READONLY: 'Cannot move a read-only file.',
    },
    RESTORE: {
      FILE: 'Network Error. Unable to restore file.',
      PROJECT: 'Network Error. Unable to restore Project.',
    },
    UPDATE: 'Network Error. Unable to save changes.',
    CREATE: {
      API_KEY: 'Failed to create api key. Try again.',
      DEVICE: 'Something went wrong. Try again.',
      GUILD: 'Error. A guild with this name already exists.',
      FOLDER: 'Failed to create folder.',
      FOLDER_IN_PROTECTED_PROJECT:
        'Cannot create folder. The target project is protected. Please choose a different project.',
      PROJECT: 'Network Error. Unable to create Project.',
      PROTECTED_PROJECT:
        'Cannot create or save model. The target project is protected. Please choose a different project.',
      MODEL: {
        CLIENT_OUT_OF_DATE:
          'Could not create model: your client is out of date. Refresh the tab and try again.',
        GENERIC_ERROR: 'Network Error. Unable to create model.',
        SAVE_ERROR:
          'Failed to save model: model data does not match latest schema.',
      },
      PASSWORD: 'Invalid Token.',
      TOTP: 'Could not confirm your code. Check it and try again.',
      USER: 'Something went wrong. Try again.',
    },
    SESSION: 'Your session has ended. Sign in to continue working.',
    CAC: 'Your HSPD-12 credentials are not associated with any account.',
    MFA: 'You must verify with a code from your MFA device to continue.',
    CLIPBOARD: {
      COPY: 'Error copying elements',
      CUT: 'Error cutting elements',
      PASTE: 'Error pasting elements',
      PERMISSION: 'Permission to access clipboard is not granted',
      FORMAT: 'Invalid Paste Format',
    },
    RENEW: {
      API_KEY: 'Failed to Renew API Key.',
    },
    GRAPH_LAYOUT: {
      FAILURE: 'Unable to apply layout.',
    },
  },

  INFO: {
    MODEL_MODIFIED: 'Someone else has modified this model.',
    DOWNLOADING: 'Preparing files for download...',
    USER: 'User is being created...',
  },

  SESSION: {
    IDLE_OUT: 'Your session has expired due to inactivity.',
    MAX_AGE: 'Your session has surpassed the maximum time limit.',
  },

  SUCCESS: {
    ARCHIVE: {
      FILE: 'This file is moving to Archives.  You can always restore this file from Archives.',
      PROJECT:
        'This project is moving to Archives.  You can always restore this project from Archives.',
    },
    RESTORE: {
      FILE: 'File restored.',
      PROJECT: 'Project restored.',
    },
    DELETE: {
      API_KEY: 'API Key successfully deleted.',
      PROJECT: 'Project deleted.',
      FILE: 'File deleted.',
    },
    RENAME: {
      MODEL: 'Model name updated.',
    },
    UPDATE: 'All changes saved.',
    UPDATE_OWNER: 'Owner updated.',
    CREATE: {
      API_KEY: 'Create api key successfully.',
      GUILD: 'Guild Created Successfully.',
      PASSWORD: 'Password Registered Successfully.',
      PROJECT: 'Project Created Successfully.',
      TOTP: 'Authenticator Verified.',
      USER: 'User Created Successfully.',
    },
    CONTACT: 'Message sent.',
    MOVE: {
      INITIATED: 'Your files are being moved.',
    },
    COPY: {
      INITIATED: 'Your files are being copied.',
    },
    RENEW: {
      API_KEY: 'Successfully renewed API Key.',
    },
  },

  WARNING: {
    CREATE: {
      EDGE: {
        SAME_NODE: 'Please choose a different Node.',
      },
    },
    GET: {
      MODEL: {
        SHAPE_ASSET:
          'Problem loading a media item into the model; replaced by default node',
      },
      MEDIA: {
        VIRUS:
          'Selected folder contains a virus. The virus file has been excluded from download.',
      },
    },
    SELECT: {
      MEDIA: {
        UNSAFE: 'Selected media is unsafe for download.',
      },
    },
  },
};
