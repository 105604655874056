/*
  We set gid when the model loads to the highest unused number in the model.
  That way we can call gid() to get unique integer IDs for new items in the model reducer
*/

import type { RootReducer } from 'types';

export type GID = string;

let _gid = 1;

export const setGid = (value: number) => {
  _gid = value;
};

export const gid = () => {
  return `g${++_gid}`;
};

export const newestGid = () => {
  return `g${_gid}`;
};

const gidRx = /^g\d+$/;
export const findHighestGid = (state: Record<string, any>, highest = 0) => {
  for (const key in state) {
    if (gidRx.test(key)) {
      const num = Number(key.slice(1));
      if (num > highest) {
        highest = num;
      }
    }

    const value = state[key];
    if (typeof value === 'object' && !Array.isArray(value)) {
      highest = findHighestGid(value, highest);
    }
  }

  return highest;
};
