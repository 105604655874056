/* eslint-disable no-console */
import { isEqual } from 'lodash';
import { EventManager } from 'metra-events';
import { clear, MODEL_HISTORY } from 'modules/model/history/actions';
import type { Middleware } from 'redux';
import { RootReducer } from 'types';
import { ENGINE, GLOBAL_EVENT, MODEL } from 'utils/constants';
import { modelHistory } from 'utils/model-history';
import { pruneModel } from 'utils/utils-extra';

export const UNIVERSAL_UNDO = 'universalUndo';
export const UNIVERSAL_REDO = 'universalUndo';

export const history: Middleware<{}, RootReducer> =
  (store) => (next) => (action) => {
    switch (action.type) {
      case MODEL.PIXI_READY: {
        // initialize history with the first snapshot of modelState
        const state = store.getState();
        const modelState = state.modelReducer;
        modelHistory.state = pruneModel(modelState);

        return next(action);
      }
      case MODEL.HISTORY_REMEMBER: {
        const state = store.getState();
        const modelState = state.modelReducer;
        const prunedModelState = pruneModel(modelState);

        // add entry to modelHistory
        if (!isEqual(modelHistory.state, prunedModelState)) {
          modelHistory.state = pruneModel(modelState);

          store.dispatch({
            type: MODEL_HISTORY.CREATE,
            payload: {
              name: UNIVERSAL_UNDO,
              args: [],
            },
          });
        }

        return next(action);
      }
      case MODEL_HISTORY.UNDO: {
        const state = store.getState();
        const modelState = state.modelReducer;
        const undoActions = modelState.history.undoActions;
        const undoAction = undoActions[undoActions.length - 1];

        if (undoAction) {
          const isUniversalUndo = undoAction.name === UNIVERSAL_UNDO;

          if (isUniversalUndo && modelHistory.pastSize && modelHistory.state) {
            modelHistory.undo();
            store.dispatch({
              type: MODEL_HISTORY.UNIVERSAL,
              payload: modelHistory.state,
            });
            EventManager.emit(GLOBAL_EVENT.HISTORY_UNDO);
            EventManager.emit(ENGINE.REBUILD, modelHistory.state, modelState);
          }

          // TODO: handle saved dispatch action
          if (!isUniversalUndo) {
            // const action = undoableActions[undoAction.name];
            // if (action) {
            //   store.dispatch(undoAction(...undoAction.args))
            // }
          }
        }

        // forward the current action
        return next(action);
      }
      case MODEL_HISTORY.REDO: {
        const state = store.getState();
        const modelState = state.modelReducer;
        const redoActions = modelState.history.redoActions;
        const redoAction = redoActions[redoActions.length - 1];

        if (redoAction) {
          const isUniversalRedo = redoAction.name === UNIVERSAL_REDO;

          if (
            isUniversalRedo &&
            modelHistory.futureSize &&
            modelHistory.state
          ) {
            modelHistory.redo();
            store.dispatch({
              type: MODEL_HISTORY.UNIVERSAL,
              payload: modelHistory.state,
            });
            EventManager.emit(GLOBAL_EVENT.HISTORY_REDO);
            EventManager.emit(ENGINE.REBUILD, modelHistory.state, modelState);
          }

          // TODO: handle saved dispatch action
          if (!isUniversalRedo) {
            // const action = undoableActions[redoAction.name];
            // if (action) {
            //   store.dispatch(action(...redoAction.args))
            // }
          }
        }

        // forward the current action to the reducer
        return next(action);
      }
      case MODEL_HISTORY.CLEAR: {
        modelHistory.clear();

        return next(action);
      }
      case MODEL.RESET: {
        store.dispatch(clear());

        return next(action);
      }
      default: {
        return next(action);
      }
    }
  };
