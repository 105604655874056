import { MetraMedia, MetraRouteParams } from 'types';
import { globalGetState } from './utils-extra';
import { OrgContext } from './OrganizationContext';
import { DOWNLOAD_URL } from './settings';
import { isFolder, isModel, isSome } from 'helpers/utils';
import { match } from 'react-router-dom';
import { Immutable } from 'seamless-immutable';
import { t3dev } from 't3dev';
import { URL_SEARCH_PARAM_NAMES } from './constants';
import { modelPathMatch } from 'modules/model/modelLinks/helpers';

export const buildHelpUrl = () => {
  return globalGetState().configReducer?.helpDocsUrl;
};

export const buildSupportCenterUrl = () => {
  if (t3dev().environment === 'staging') {
    return 'https://staging-support.metrahub.com/';
  }

  return `https://idp-bridge.metrahub.us/${OrgContext.organization}`;
};

export const buildOrgUrl = () => {
  return `/org/${OrgContext.organization}`;
};

export const buildGuildUrl = (cname?: Option<string>) => {
  return `${buildOrgUrl()}/guild/${cname ? cname : OrgContext.guild}`;
};

export const buildContextUrl = () =>
  OrgContext.guild ? buildGuildUrl() : buildOrgUrl();

export const buildAllGuildsUrl = () => {
  return `${buildOrgUrl()}/guilds`;
};

export const buildProjectsUrl = (cname?: Option<string>) => {
  return `${buildGuildUrl(cname)}/projects`;
};

export const buildProjectUrl = (projectId: Numberish, cname?: Option<string>) =>
  `${buildProjectsUrl(cname)}/${projectId}`;

export const buildFilesUrl = (
  projectId: Numberish,
  filePath?: Option<string>,
  cname?: Option<string>
) =>
  filePath
    ? `${buildProjectUrl(projectId, cname)}/files${filePath}`
    : `${buildProjectUrl(projectId, cname)}/files`;

export const buildModelListUrl = (projectId: Numberish) =>
  `${buildProjectUrl(projectId)}/modelList`;

export interface BuildModelUrlArgs {
  modelId?: Option<Numberish>;
  versionId?: Option<Numberish>;
  searchText?: string;
  expressionEnabled?: boolean;
}

export const buildModelsUrl = ({
  modelId,
  versionId,
  searchText,
  expressionEnabled,
}: BuildModelUrlArgs) => {
  let path =
    buildOrgUrl() +
    '/models' +
    (modelId !== null && modelId !== undefined ? `/${modelId}` : '') +
    (versionId ? `/version/${versionId}` : '');
  if (searchText && searchText.trim().length > 0) {
    const params = new URLSearchParams();
    params.append(URL_SEARCH_PARAM_NAMES.SEARCH_TEXT, searchText.trim());
    if (expressionEnabled) {
      params.append(URL_SEARCH_PARAM_NAMES.IS_EXPRESSION_SEARCH, 't');
    }
    path = path + '?' + params.toString();
  }
  return path;
};
/**
 *
 * @param projectId
 * @param modelId
 * @param cname
 */
export const buildNewModelUrl = ({ projectId }: { projectId: Numberish }) => {
  return (
    buildOrgUrl() + `/models/0?projectId=${projectId}&guild=${OrgContext.guild}`
  );
};

export const modelUrlRegex = new RegExp(
  /\/org\/[^/]+\/(guild\/[^/]+\/projects\/\d+\/)?models\/\d+(\/version\/\d+)?$/
);

export const folderUrlRegex = new RegExp(
  /\/org\/.+\/guild\/.+\/projects\/\d+\/files(\/\d+)*?$/
);

export const buildDownloadUrl = (upload: Numberish, cname?: string) => {
  return `${DOWNLOAD_URL}${buildGuildUrl(cname)}/uploads/${upload}/_download`;
};

export const buildDownloadLandingPageUrl = (
  upload: Numberish,
  cname?: string
) => {
  return `${buildGuildUrl(cname)}/uploads/${upload}/_download`;
};

export const mediaDownloadUrl = (mediaId: Numberish, cname: Option<string>) => {
  return `${DOWNLOAD_URL}${buildGuildUrl(cname)}/media/${mediaId}/_download`;
};

/**
 * accepts a string value to determine if URL is a metra library file
 * @param urlString - value to check if string is a metra library file URL
 * @returns true if metraHub file download url
 */
export function isMetraFileDownloadURL(urlString: string): boolean {
  let url;
  try {
    url = new URL(urlString.toLowerCase());
  } catch (_) {
    // if we couldn't turn it into a URL, it's not a URL
    return false;
  }

  const path = `${url.origin}${url.pathname}`;
  // first we make sure the base path matches our download URL
  // The above url is created with the urlString set to lowercase
  // Passing in the "i" flag as a second argument to the RegExp
  // constructor so the matching process is case-insensitive
  const flags = 'i';
  const mediaIdDownloadRegex = new RegExp(
    (buildDownloadUrl('.+', '.+') + '$').replace('uploads', 'media'),
    flags
  );
  if (!mediaIdDownloadRegex.test(path)) return false;
  // then we check that inline isn't set, because inline links don't count
  const inline = url.searchParams.get('inline');
  if (inline && inline !== 'false') return false;

  return true;
}

/**
 * accepts a string value to determine if URL is a metra library file
 * @param urlString - value to check if string is a metra library file URL
 * @returns true if metraHub folder url
 */
export function isMetraFolderURL(urlString: string): boolean {
  let url;
  try {
    url = new URL(urlString.toLowerCase());
  } catch (_) {
    // if we couldn't turn it into a URL, it's not a URL
    return false;
  }
  const path = `${url.origin}${url.pathname}`;
  return folderUrlRegex.test(path);
}

/**
 * accepts a string value to determine if URL is a metra model file
 * @param urlString - value to check if string is a metra model file URL
 * @return returns true if metraHub model url
 */
export function isMetraModelURL(urlString: string): boolean {
  let url;
  try {
    url = new URL(urlString.toLowerCase());
  } catch (_) {
    // if we couldn't turn it into a URL, it's not a URL
    return false;
  }
  return isSome(modelPathMatch(url));
}

//passively force-update the model url
export const updateModelUrl = (
  projectId: Numberish,
  mediaId: Numberish,
  versionId: Numberish,
  searchText?: string,
  isExpressionSearch?: boolean
) => {
  let modelUrl = buildModelsUrl({
    modelId: mediaId,
    versionId,
  });
  if (searchText && searchText.trim().length > 0) {
    const searchParams = new URLSearchParams();
    searchParams.append(URL_SEARCH_PARAM_NAMES.SEARCH_TEXT, searchText);
    if (isExpressionSearch) {
      searchParams.append(URL_SEARCH_PARAM_NAMES.IS_EXPRESSION_SEARCH, 't');
    }
    modelUrl = modelUrl + '?' + searchParams.toString();
  }
  window.history.replaceState('', '', modelUrl);
};

/**
 * Constructs a URI string pointing to the supplied media's latest version.
 * If media has no versions, return null.
 *
 *  @param  media - A media object. This might be a file, model or chart type.
 *  @return  The URI string for the media object latest version or null if no versions exist.
 */
export const getMediaLink = (
  media: MetraMedia,
  match: match<MetraRouteParams>,
  useLandingPage = false
): Option<string> => {
  const isChart = media.name.trim().endsWith('.chart');
  let link: Option<string> = null;

  if (isModel(media)) {
    link = buildModelsUrl({ modelId: media.id });
  } else if (isChart) {
    link = buildProjectUrl(media.project).concat(`/charts/${media.id}`);
  } else if (isFolder(media)) {
    link = `${match.url}/${media.id}`;
  } else {
    //this is a regular media
    if (useLandingPage) {
      link = buildDownloadLandingPageUrl(media.id);
    } else if (media.versions?.length) {
      const upload = media.versions[0];
      link = buildDownloadUrl(upload);
    }
  }

  return link;
};

/**
 *
 * @param media
 */
export const getMediaAssetURL = (media: MetraMedia | Immutable<MetraMedia>) => {
  return Object.values(media).length > 0
    ? `${window.location.origin}/api${buildGuildUrl()}/media/${
        media.id
      }/_download`
    : 'default';
};

export function buildMediaTagURL(
  mediaId: Numberish,
  cname?: Option<string>
): string {
  return `/api${buildGuildUrl(cname)}/media/${mediaId}/tags`;
}

export function getEntityAssetURLs(
  assetURL: string
): [normal: string, small: string, large: string] {
  const smallURL = new URL(assetURL.toLowerCase());
  smallURL.search = new URLSearchParams({
    size_maximum: 'small',
  }).toString();
  const largeURL = new URL(assetURL.toLowerCase());
  largeURL.search = new URLSearchParams({
    size_maximum: 'large',
  }).toString();

  return [assetURL, smallURL.href, largeURL.href];
}
