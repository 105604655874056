// import type { AnimatedGIF } from '@pixi/gif';
import {
  AnimatedSprite,
  Sprite as PSprite,
  Container as PContainer,
  Text as PText,
  Rectangle,
  Graphics,
  DisplayObject,
} from 'pixi.js';
import {
  PixiEventTypes,
  AspectRatio,
  AssetType,
  PointLike,
  LabelPosition,
} from 'types';
import { Vector2 } from 'utils/vector';
import type { Entity } from 'ecs/Entity';
import { Component, ValueComponent } from 'ecs/Component';
import type { QuadTree } from './quadtree';
import type { LineRange, RectangleRange } from './range';
import { AssetLoadState, ThumbnailMode } from './constants';
import { GRID, SELECTION_EMPHASIS } from 'utils/constants';
import { HEX_COLOR } from 'utils/constants';
import { QTree as QTree2 } from 'ecs2/utils/quadtree2';

export class Alpha extends ValueComponent<number> {
  _value = this.prop(1);
}

// This is the actual alpha value that displays
// after user alpha, muting, selection, filters, etc.
// It will be added automatically by the alpha system
export class DerivedAlpha extends ValueComponent<number> {
  _value = this.prop(0);
}

export class Arrowhead extends Component {
  value!: string;
}

export class Aspect extends ValueComponent<AspectRatio> {
  _value = this.prop<AspectRatio>({
    width: 1,
    height: 1,
    ratio: 1,
    scale: { x: 1, y: 1 },
  });
}

export class Asset extends ValueComponent<string> {
  assetType = this.prop<AssetType>('preset');
  thumbnailMode = this.prop(ThumbnailMode.NONE);
}

export class PendingEdgeTarget extends Component {}

export class Assigned extends ValueComponent<number> {}

export class SpriteEdge extends Component {}

export class SegmentSprite extends ValueComponent<PSprite> {
  _value = this.prop(new PSprite());
}

export class Background extends Component {}

export class Hovered extends ValueComponent<Option<UUID>> {}

export class Bindable extends Component {}

export class Bindings extends Component {
  bindingName!: string;
  component!: AssetComponentTypes;
  events!: PixiEventTypes[];
}

export class Bounds extends ValueComponent<Rectangle> {
  _value = this.prop(new Rectangle(0, 0, 0, 0));
}

// add this to get OrientedBounds for a rotated entity
export class Oriented extends Component {
  ref = this.prop<DisplayObject>(undefined as unknown as DisplayObject);
}

// oriented bounds for accurately calculating
// collisions with rotated entity
export class OrientedBounds extends Component {
  a = this.prop(new Vector2());
  b = this.prop(new Vector2());
  c = this.prop(new Vector2());
  d = this.prop(new Vector2());

  toArray() {
    return [this.a.val, this.b.val, this.c.val, this.d.val];
  }
}

// BoundingBox uses 4 positional coordinates to form a rect
// unlike Bounds, which uses 2 coordinates and width+height
export class BoundingBox extends Component {
  min = this.prop(Vector2.zero);
  max = this.prop(Vector2.zero);

  // managed by SysUpdateQTree
  tree: Option<QTree2<BoundingBox>>;

  clear() {
    this.min.val = Vector2.zero;
    this.max.val = Vector2.zero;
  }

  // useful when dealing with starting and ending points
  // and it's unknown which is the min and which is the max
  fromPoints(a: Vector2, b: Vector2) {
    this.min.val.x = Math.min(a.x, b.x);
    this.min.val.y = Math.min(a.y, b.y);
    this.max.val.x = Math.max(a.x, b.x);
    this.max.val.y = Math.max(a.y, b.y);
    this.update();
  }

  // x+y+w+h getters

  get x() {
    return this.min.val.x;
  }

  get y() {
    return this.min.val.y;
  }

  get width() {
    return this.max.val.x - this.min.val.x;
  }

  get height() {
    return this.max.val.y - this.min.val.y;
  }

  // for x+y+w+h setters, we adjust all values so that
  // setting x+y does not change width or height
  // and setting w+h does not change x+y position

  set x(val) {
    const diff = val - this.min.val.x;
    this.min.val.x = val;
    this.max.val.x += diff;
    this.update();
  }

  set y(val) {
    const diff = val - this.min.val.y;
    this.min.val.y = val;
    this.max.val.y += diff;
    this.update();
  }

  set width(val) {
    if (val < 0)
      throw new Error(`BoundingBox cannot have negative width: ${val}`);
    this.max.val.x = this.min.val.x + val;
    this.update();
  }

  set height(val) {
    if (val < 0)
      throw new Error(`BoundingBox cannot have negative height: ${val}`);
    this.max.val.y = this.min.val.y + val;
    this.update();
  }

  // setters and getters for t+l+b+r
  // in this paradigm, changing any corner causes
  // a change in the size of the bounding box

  get top() {
    return this.min.val.y;
  }

  get left() {
    return this.min.val.x;
  }

  get bottom() {
    return this.max.val.y;
  }

  get right() {
    return this.max.val.x;
  }

  set top(val) {
    this.min.val.y = val;
    this.update();
  }

  set left(val) {
    this.min.val.x = val;
    this.update();
  }

  set bottom(val) {
    this.max.val.y = val;
    this.update();
  }

  set right(val) {
    this.max.val.x = val;
    this.update();
  }
}

// a bit hacky but for now we try to detect changes to clumpable
// so we know when to reclump.
// in the future, all components will be signals like this
// and systems will react only to dirty components
export class Clumpable extends Component {
  _sprites!: [string, PSprite | AnimatedSprite, number][];
  _dirty = false;

  set sprites(val) {
    this._sprites = val;
    this._dirty = true;
  }

  get sprites() {
    return this._sprites;
  }
}

export class BringIntoView extends Component {}

export class CollapseButton extends Component {}

export class Collapsed extends Component {}

export class CollapsedSet extends Component {
  setId!: string;
  nestings = 0;
}

export class Color extends ValueComponent<number> {
  _value = this.prop(0);
}

export class Container extends Component {
  asset!: PContainer;
}

export class ContextClickable extends Component {}

export class ControlPoint extends Component {
  x = this.prop(-1);
  y = this.prop(-1);
  radius = this.prop(0);
  // The key from CONTROL_POINTS constant that this ControlPoint represents.
  cpKey = this.prop('');
  offset = this.prop(new Vector2(0, 0));
}

export class DarkMode extends Component {}

//IDEA: 🤔🤔🤔🤔🤔
//       should we just use Bounds component?
//       should it be de-conflicted with it?
export class Dimensions extends Component {
  width!: number;
  height!: number;
  radius!: number;
}

// entity in a selection that is being dragged
export class DragTarget extends Component {}

export class AlwaysInteractive extends Component {}

// Interactive components are added to the Quadtree
export class Interactive extends Component {}

//IDEA: export internals into `Anchor` component,
//      Edge becomes a dataless tagging component
export class Edge extends Component {
  /** Node where the edge terminates. */
  inNodeId!: string;
  /** Node where the edge originates. */
  outNodeId!: string;
  /** 
  Shape where the edge terminates on screen - may be inNode, or may be a 
  collapsed set containing inNode. 
  */
  inAnchorId!: string;
  /** 
  Shape where the edge originates on screen - may be outNode, or may be a 
  collapsed set containing outNode. 
  */
  outAnchorId!: string;
}

export class EdgeLabelsVisibility extends Component {}

export class EdgeTarget extends Component {}

export class EdgeTargetHighlight extends Component {
  asset!: Graphics;
}
export class Evaluateable extends Component {}

export class Evaluated extends Component {}

export class Finalized extends Component {}

export class GIFSprite extends Component {
  // another hack because we're pretending these are mandatory when we leave them undefined at construction
  asset = this.prop<PSprite | AnimatedSprite>(undefined as unknown as PSprite);
  textureUrl = this.prop('');
  baseWidth = this.prop(0);
  baseHeight = this.prop(0);
}

export class Graphic extends Component {
  asset!: Graphics;
  tag!: string;
  value!: string;
}

/**
 * represents an entity being explicitly hidden
 */
export class Hidden extends Component {}

export class Interactable extends Component {}

export class Muted extends Component {
  alpha = this.prop(0.15);
}

export class Label extends ValueComponent<string> {
  asset!: PText;
}

export class LabelRef extends ValueComponent<Entity> {
  position = this.prop<LabelPosition>('above');
  content = this.prop<UUID>(GRID.NAME_COLUMN.NODES);
}

export class LoadAsset extends Component {
  name!: string;
  url!: string;
  status!: AssetLoadState;
}

export class Locked extends Component {}

export class MetraLayer extends ValueComponent<number> {
  _value = this.prop(-1);
}

export class MetraShape extends Component {
  value!: number;
}

export class MinScaledSize extends ValueComponent<number> {
  override = this.prop<Option<number>>(undefined);
}

export class Moveable extends Component {}

export class Moving extends Component {
  offset = this.prop(Vector2.zero);
  initialPosition = this.prop(Vector2.zero);
}

export class Move extends Component {
  offset!: Vector2;
}

// legacy MultiSelect is still tied up in our code
export class MultiSelect extends Component {}

// new multi-select box that appears around selected shapes
export class MultiSelectBox extends Component {
  lineWidth = this.prop(3);
}

export class Grid extends Component {
  rowSize;
  columnSize;
  lineWidth = this.prop(1);
  lineColor = this.prop(HEX_COLOR.GRID_LINE);
  lineAlpha = this.prop(1);

  constructor({ width = 43, height = 43 } = {}) {
    super();

    this.rowSize = this.prop(height);
    this.columnSize = this.prop(width);
  }
}

export class NeedsEvaluation extends Component {}

export class Node extends Component {}

export class Offset extends ValueComponent<Vector2> {
  _value = this.prop(Vector2.zero);
}

export class Order extends ValueComponent<number> {
  _value = this.prop(0);
}

export class PendingEdge extends Component {
  from!: Entity;
}

export class Mouse extends Component {}

export class Position extends ValueComponent<Vector2> {
  _value = this.prop(Vector2.zero);
}

export class Related extends ValueComponent<Entity> {}

/**
 * Represents whether an entity is allowed to be included in the draw group when on-screen.
 * If an entity that is within the view of the camera and has both a Container and a Renderable component, it will be drawn.
 * The component is data-less, and is therefore added and removed from the entity to reflect whether the entity is “Renderable”.
 */
export class Renderable extends Component {}

export class Resource extends Component {}

export class Zoom extends ValueComponent<Vector2> {
  _value = this.prop(new Vector2(1.0, 1.0));

  inverse() {
    return new Vector2(1, 1).div(this.val);
  }
}

export declare interface ResizeableComponent extends Component {
  value: Vector2;
}

/** entity capable of being resized */
export class Resizeable extends Component {
  componentId!: number;
}

/** carries resize meta-data */
export class Resize extends Component {
  delta!: Vector2;
  locked!: boolean;
  control!: string;
}

/** flags an entity whose control points were grabbed to do a resize */
export class ResizeTarget extends Component {}

/** flags a waypoint that is being moved to reshape an edge */
export class ReshapeWaypoint extends Component {}

/** for entities in the process of resizing */
export class Resizing extends Component {
  initMouse!: Vector2;
  initPos!: Vector2;
  initScale!: Vector2;
  initBounds!: Rectangle;
  direction!: Vector2;
  complete = false;
}

export class RestartGif extends Component {}

// tracks whether mouse is panning
export class Panning extends Component {}

export class Rotation extends Component {
  value!: number;
}

export class Scale extends ValueComponent<Vector2> {
  _value = this.prop(Vector2.zero);
}

export class Selectable extends Component {}

export class Selected extends Component {}

export class Selection extends Component {
  asset!: Graphics;
}

export class SelectionEmphasis extends ValueComponent<number> {
  _value = this.prop(SELECTION_EMPHASIS);
}

export class Selector extends Component {
  graphics = this.prop(new Graphics());
}

export class Drawing extends Component {
  startPos = this.prop(new Vector2());
  angle = this.prop(0);
  length = this.prop(0);
  strokeWidth = this.prop(0);
  width = this.prop(0);
  color = this.prop('');
  drawingType = this.prop('');
}
export class DrawingBox extends Component {
  boxHeight = this.prop(0);
  boxWidth = this.prop(0);
  fillColor = this.prop('');
  fillAlpha = this.prop(1);
}

export class CreatingDrawing extends Component {}

// IDEA: rename to `Sets`
export class SetMember extends Component {
  sets!: string[];
  // values!: Entity[]
}

export class MetraSet extends Component {}

/**
 * IDEA: just use Color on a `Set` component
 *       convert `hidden` to be represented by `Visible` component
 * Set colors that are in a state which would make them viewable
 */
export class SetColors extends Component {
  values!: number[];
  hidden!: boolean;
}

// for nodes and edges, which only display one set at a time
export class ActiveSetColor extends ValueComponent<Option<number>> {}

export class ShapeUUID extends ValueComponent<string> {}

export class Segment extends ValueComponent<number> {
  /**
   * the index of the segment
   */
  _value = this.prop(0);
  from = this.prop(Vector2.zero);
  to = this.prop(Vector2.zero);
  first = this.prop(false);
  last = this.prop(false);
}

export class Segments extends ValueComponent<Entity[]> {
  _value = this.prop([] as Entity[]);

  get values() {
    return this.value;
  }

  set values(val) {
    this.value = val;
  }
}

export class ShapeStyle extends Component {
  lineWidth!: number;
  lineColor!: number;
}

export class Spatial extends Component {
  // HACK: want to make this more memory efficient, i.e., an ID ref perhaps
  //       probably should make the ECS mode "quadrant"-based and utilze that
  parents: QuadTree[] = [];
  point!: PointLike;
  range!: RectangleRange;
  // rectilinear edge segments, can have two sub-lines
  line!: LineRange[];
}

// IDEA: move `baseWidth` and `baseHeight` into `Dimensions`
export class Sprite extends Component {
  asset!: PSprite;
  baseWidth!: number;
  baseHeight!: number;
}

export class Style extends Component {
  value!: number;
}

export class PixiText extends Component {
  asset!: PText;
}

export class Text extends Component {
  textString = this.prop('');
  fill = this.prop('');
  fontSize = this.prop(16);
  stroke = this.prop('');
  strokeThickness = this.prop(0);
  strokeAlpha = this.prop(1);
}

export class Update extends Component {
  components!: (typeof Component)[];
  target!: Entity;
}

// export class Updating extends Component {}

/**
 * Mirrors the state of “visibility” in the Metra Model.
 */
export class Visible extends Component {}

export class Waypoints extends ValueComponent<Entity[]> {
  _value = this.prop([] as Entity[]);

  get values() {
    return this.value;
  }

  set values(val) {
    this.value = val;
  }
}

export class Waypoint extends ValueComponent<number> {
  /**
   * the index of the waypoint
   */
  _value = this.prop(-1);
}

// IDEA: things should use `Dimensions` or a `Style` component
export class Width extends ValueComponent<number> {
  _value = this.prop(1);
}

export class QTree extends ValueComponent<QTree2<BoundingBox>> {}

export class DebugTools extends Component {
  showQTree = this.prop(false);
}

// used to visualize quadtree in debug mode
export class DebugQTree extends Component {
  tree!: QTree2<any>;
}

// derived types
export declare type AssetComponentTypes =
  | typeof Container
  | typeof GIFSprite
  | typeof Graphic
  | typeof Sprite;
