import { EventManager } from 'metra-events';
import { AnyAction, Dispatch, Middleware } from 'redux';
import { ThunkAPI } from 'types';

export function createMetraThunkMiddleware<E>(extraArgs?: E) {
  const middleware = (
    api: ThunkAPI<Dispatch<AnyAction>, any, E>
  ): ReturnType<Middleware> => {
    return (next: Dispatch<AnyAction>) => (action: any) => {
      if (typeof action === 'function') {
        const { dispatch, getState } = api;
        return action(dispatch, getState, extraArgs);
      }
      return next(action);
    };
  };
  return middleware;
}

export const premadeMiddleware = createMetraThunkMiddleware({
  emit: EventManager.emit,
}) as Middleware;
