import {
  ActionSelectType,
  CollapsedConfig,
  MetraThunkAction,
  MetraThunkDispatch,
  ModelReducer,
  RootReducer,
  ThunkAction,
  ThunkActionFunc,
} from 'types';
import {
  changeAllEdgesAlpha,
  changeAllNodesAlpha,
  changeAllSetsAlpha,
  deselectAll,
  deselectObjects,
  remember,
  selectExactly,
  selectObjects,
  setIsSearching,
} from 'modules/model/base/actions';
import { isNone, isSome } from 'helpers/utils';
import { applyFilter, clearFilter } from 'modules/model/table/utils';
import { ACTIONS, ENGINE, SCHEMA_CATEGORY, SHAPE } from 'utils/constants-extra';
import { updateSet } from 'modules/model/set';
import { updateShapeByConfig, updateShapesByConfig } from 'modules/model/pixi';
import { PROPERTY_SELECT } from 'utils/constants';
import { clearSelection } from 'modules/model/properties';
import { CellResult } from 'modules/basicSearch/engine';
import { clearModelSearchResults } from 'modules/model/modelSearchResults';

/**
 * Checks if every member of a set is completely contained within another set
 * @param containedSetId - target set to check
 * @param containingSetId - potential containing set
 * @param [modelState] - current model state
 * @returns
 */
function actionsOnlySetIsContained(
  containedSetId: string,
  containingSetId: string,
  modelState: ModelReducer
): boolean {
  const containingSet = modelState.sets?.[containingSetId];
  const containedSet = modelState.sets?.[containedSetId];

  if (
    isNone(containedSet) ||
    (containedSet?.nodeIds.length === 0 && containedSet?.edgeIds.length === 0)
  ) {
    return false;
  }

  const containingNodeIdsMap = new Set(containingSet.nodeIds);
  if (!containedSet.nodeIds.every((nodeId) => containingNodeIdsMap.has(nodeId)))
    return false;

  const containingEdgeIdsMap = new Set(containingSet.edgeIds);
  if (!containedSet.edgeIds.every((edgeId) => containingEdgeIdsMap.has(edgeId)))
    return false;

  return true;
}

/**
 * Finds any set that's associated with any member of a given set
 * @param  setId
 * @returns  - A list of unique set ids
 */
function actionsOnlyFindAssociatedSets(
  setId: string,
  model: ModelReducer
): string[] {
  const associatedSets = new Set<string>();

  const set = model.sets[setId];

  set.nodeIds.forEach((nodeId) => {
    const node = model.nodes[nodeId];
    node.setIds.forEach((sid) => associatedSets.add(sid));
  });

  set.edgeIds.forEach((edgeId) => {
    const edge = model.edges[edgeId];
    edge.setIds.forEach((sid) => associatedSets.add(sid));
  });

  associatedSets.delete(setId);

  return Array.from(associatedSets);
}

/**
 * NOTE: this function duplicates funcionality ON PURPOSE to fix a
 * very hard to resolve dependency cycle with `helpers/sets`
 */
export function actionsOnlyGetSetShapeIds(setIds: UUID[]): ThunkAction<UUID[]> {
  return (_dispatch, getState) => {
    const model = getState().modelReducer;
    const {
      sets,
      base: { collapsed },
    } = model;
    const selectedSets = setIds.map((sid) => sets[sid]).filter(Boolean);
    let shapeIds: UUID[] = [];
    selectedSets.forEach((set) => {
      shapeIds = [
        ...shapeIds,
        ...set.nodeIds,
        ...set.edgeIds,
        collapsed.sets[set.id],
      ];
    });

    return shapeIds;
  };
}

/**
 * Add objects to the current selection. Valid types of selection:
 * shapes - graph shapes such as nodes, edges and backgrounds
 * sets - entities representing collections of nodes and edges
 * @param ids - The objects to add to the selection
 * @param type - The type of selection
 * @param table - select table [default: false]
 **/
export function issueSelect(
  rawIds: UUID[],
  type: 'shapes' | 'sets' | 'both' = 'shapes',
  table = false
): ThunkAction<void> {
  return (dispatch, getState, { emit }) => {
    // this is used to know which Ids are in the request as well as type
    emit(ACTIONS.SELECT, rawIds, type, table);
    // we do this so we can have reference to the ORIGINAL ids passed
    // that is because this value gets mutated and extended as we
    // progress down the function
    let ids = rawIds;
    if (!table) dispatch(clearSelection());
    if (type === 'both') {
      const {
        modelReducer: { sets, shapes },
      } = getState();
      const initialData: [UUID[], UUID[]] = [[], []];
      // map ids that are sets to setIds
      const [shapeIds, setIds] = ids.reduce(([shapeIds, setIds], id) => {
        if (id in sets) setIds.addItem(id);
        if (id in shapes) shapeIds.addItem(id);
        return [shapeIds, setIds];
      }, initialData);
      if (setIds.length) {
        dispatch(selectObjects(setIds, 'sets'));
        shapeIds.add(dispatch(actionsOnlyGetSetShapeIds(setIds)));
      }

      if (shapeIds.length) {
        ids = shapeIds;
      }
    } else if (type === 'sets') {
      dispatch(selectObjects(ids, 'sets'));
      ids = dispatch(actionsOnlyGetSetShapeIds(ids));
    }
    // always select the resultant shapes
    dispatch(selectObjects(ids, 'shapes'));
    // update ids list for consistency
    ids = getState().modelReducer.base.selected.shapes;
    if (ids.length > 1) {
      emit(ACTIONS.SELECT_MULTI, ids);
    } else {
      emit(ACTIONS.SELECT_ONE, ids);
    }
  };
}

export function separateShapesFromSets(
  ids: UUID[],
  state: RootReducer
): { shapeIds: UUID[]; setIds: UUID[] } {
  const {
    modelReducer: { sets, shapes },
  } = state;
  let separatedValues: { shapeIds: UUID[]; setIds: UUID[] } = {
    shapeIds: [],
    setIds: [],
  };
  return ids.reduce(({ shapeIds, setIds }, id) => {
    const maybeSet = sets[id];
    const maybeShape = shapes[id];
    maybeSet && setIds.push(maybeSet.id);
    maybeShape && shapeIds.push(maybeShape.id);
    return { shapeIds, setIds };
  }, separatedValues);
}

/**
 * Sets the objects as the current selection. Any previously
 * selected objects not present in ids will be deselected.
 * Valid types of selection:
 *    shapes - graph shapes such as nodes, edges and backgrounds
 *    sets - entities representing collections of nodes and edges
 * @param {string[]} ids - The objects to establish as the selection
 * @param {'shapes' | 'sets' | 'both'} [type] - The type of selection [default: 'shapes']
 * @param {boolean} [table] - select table [default: false]
 **/
export function issueSelectExactly(
  ids: UUID[],
  type: 'shapes' | 'sets' | 'both' = 'shapes',
  table = false
): MetraThunkAction<unknown, unknown, void> {
  return (dispatch, getState, { emit }) => {
    const getOldSelection =
      () => (_dispatch: MetraThunkDispatch, _getState: () => RootReducer) => {
        return _getState().modelReducer.base.selected;
      };
    const oldSelection = dispatch(getOldSelection());

    // selecting in the graph clears the table selection
    if (!table && (oldSelection.sets.length || oldSelection.shapes.length))
      dispatch(clearSelection());

    const newSelection: { sets: UUID[]; shapes: UUID[] } = {
      sets: [],
      shapes: [],
    };

    if (type === 'both') {
      const { shapeIds, setIds } = separateShapesFromSets(ids, getState());
      newSelection.shapes = shapeIds;
      newSelection.sets = setIds;
    } else if (type === 'sets' || type === 'shapes') {
      newSelection[type] = ids;
    }
    // expand the collection of things to be selected to include things
    // contained in sets that are selected but not collapsed (set was
    // probably selected in the MPT?).
    const uncollapsedSelectedSetIds: UUID[] = [];
    const collapsedSelectedSetIds: UUID[] = [];
    const model = getState().modelReducer;
    newSelection.sets.forEach((selectedSetId) => {
      if (model.sets[selectedSetId].collapsed) {
        collapsedSelectedSetIds.push(selectedSetId);
      } else {
        uncollapsedSelectedSetIds.push(selectedSetId);
      }
    });

    let collapsedSelectedSetShapesIds: string[] = [];
    collapsedSelectedSetIds.forEach((setId) => {
      const collapsedShapeId = Object.keys(model.shapes).find(
        (shape) =>
          model.shapes[shape].type === 'shape/COLLAPSED_SET' &&
          (model.shapes[shape] as CollapsedConfig).setId === setId
      );
      if (isSome(collapsedShapeId)) {
        collapsedSelectedSetShapesIds.push(collapsedShapeId);
      }
    });
    const uncollapsedSetContents = dispatch(
      actionsOnlyGetSetShapeIds(uncollapsedSelectedSetIds)
    );

    const separatedSetContents = separateShapesFromSets(
      uncollapsedSetContents,
      getState()
    );
    newSelection.shapes = Array.from(
      new Set([
        ...newSelection.shapes,
        ...separatedSetContents.shapeIds,
        ...collapsedSelectedSetShapesIds,
      ])
    );
    newSelection.sets = Array.from(
      new Set([...newSelection.sets, ...separatedSetContents.setIds])
    );

    dispatch(selectExactly(newSelection.shapes, newSelection.sets));

    const deselectedSets = oldSelection.sets.filter(
      (set) => !newSelection.sets.includes(set)
    );
    if (deselectedSets.length) {
      deselectObjects(deselectedSets, 'sets');
      emit(ACTIONS.DESELECT_SETS_IDS, deselectedSets);
    }

    const selMap: Record<string, boolean> = {};
    for (let shape of newSelection.shapes) {
      selMap[shape] = true;
    }

    const deselectedShapes = oldSelection.shapes.filter(
      (shape) => !selMap[shape]
    );

    if (deselectedShapes.length) {
      deselectObjects(deselectedShapes, 'shapes');
      emit(ACTIONS.DESELECT_SHAPES_IDS, deselectedShapes);
    }

    if (newSelection.shapes.length > 1) {
      emit(ACTIONS.SELECT_MULTI, newSelection.shapes);
    } else {
      emit(ACTIONS.SELECT_ONE, newSelection.shapes);
    }
    emit(ENGINE.UPDATE.MULTI_SELECT);
  };
}

export function issueSelectAll(
  rawIds: UUID[],
  type: 'shapes' | 'sets' = 'shapes',
  table = false
): ThunkAction<void> {
  return (dispatch, getState, { emit }) => {
    // this is used to know which Ids are in the request as well as type
    emit(ACTIONS.SELECT_ALL, rawIds, type, table);
    let ids = rawIds;
    if (!table) dispatch(clearSelection());
    dispatch(selectObjects(ids, type));
    ids = getState().modelReducer.base.selected.shapes;
    if (ids.length > 1) {
      emit(ACTIONS.SELECT_MULTI, ids);
    } else {
      emit(ACTIONS.SELECT_ONE, ids);
    }
  };
}

export function issueDeselect(
  rawIds: UUID[],
  type: ActionSelectType = 'shapes'
): ThunkAction<void> {
  return (dispatch, getState, { emit }) => {
    // this is used to know which Ids are in the request as well as type
    emit(ACTIONS.DESELECT, rawIds, type);
    let ids = rawIds;

    // emit event that tells us EXACTLY what was deselected
    if (type === 'both' || type === 'sets') {
      const model = getState().modelReducer;
      const [sets, shapes] = ids.reduce(
        ([sets, shapes], id) => {
          if (id in model.sets) sets.addItem(id);
          if (id in model.shapes) shapes.addItem(id);
          return [sets, shapes];
        },
        [[], []] as [UUID[], UUID[]]
      );
      if (sets.length) {
        dispatch(deselectObjects(sets, 'sets'));
        // add the collapsed set shapes to the de-selection
        shapes.add(dispatch(actionsOnlyGetSetShapeIds(sets)));
        emit(ACTIONS.DESELECT_SETS_IDS, sets, 'sets');
      }
      if (shapes.length) {
        dispatch(deselectObjects(shapes, 'shapes'));
        emit(ACTIONS.DESELECT_SHAPES_IDS, shapes, 'shapes');
      }
    } else {
      dispatch(deselectObjects(ids, type));
      emit(ACTIONS.DESELECT_SHAPES_IDS, ids, type);
    }
  };
}

export function issueDeselectAll(): ThunkAction<void> {
  return (dispatch, getState, { emit }) => {
    // this is used to know which Ids are in the request as well as type
    emit(ACTIONS.DESELECT_ALL);

    const selected = getState().modelReducer.base.selected;
    // emit event that tells us EXACTLY what was deselected
    emit(ACTIONS.DESELECT_SHAPES_ALL, selected.shapes);
    emit(ACTIONS.DESELECT_SETS_ALL, selected.sets);
    dispatch(deselectAll());
  };
}

export function issueGetSelected(
  type: ActionSelectType = 'shapes'
): ThunkAction<UUID[]> {
  return (_, getState) => {
    if (type === 'both') {
      const selected = getState().modelReducer.base.selected;
      return selected.shapes.concat(selected.sets);
    } else {
      return getState().modelReducer.base.selected?.[type] ?? [];
    }
  };
}

export const issueApplyFilter: ThunkActionFunc<
  [
    filterAlpha?: number,
    filterIds?: UUID[],
    filterSchemas?: UUID[],
    filterUnpopulated?: boolean
  ],
  void
> = (filterAlpha, filterIds, filterSchemas, filterUnpopulated) => {
  return (dispatch, _gs, { emit }) => {
    const entities = dispatch(
      applyFilter(filterAlpha, filterIds, filterSchemas, filterUnpopulated)
    ).payload.entities;

    if (entities.length < 1) {
      // filter is now empty, there should be nothing to isolate on; so
      // unisolate everything.
      emit(ENGINE.UPDATE.SHAPES.UNISOLATE);
    }
  };
};

export function issueClearFilter(): ThunkAction<void> {
  return (dispatch, _gs, { emit }) => {
    emit(ACTIONS.CLEAR_FILTER);
    dispatch(clearFilter());
    emit(ENGINE.UPDATE.SHAPES.UNISOLATE);
  };
}

export function selectFilterElements(): ThunkAction<void> {
  return (dispatch, getState, { emit }) => {
    const filterEntities = getState().modelReducer.tableFilter.entities;
    dispatch(issueSelectExactly(filterEntities, 'shapes'));
    if (filterEntities.length < 1) {
      // filter is now empty, there should be nothing to isolate on; so
      // unisolate everything.
      emit(ENGINE.UPDATE.SHAPES.UNISOLATE);
    }
  };
}

export function issueChangeSetAlpha(
  id: UUID,
  alpha: number
): ThunkAction<void> {
  return (dispatch, getState, { emit }) => {
    const { sets } = getState().modelReducer;
    dispatch(updateSet({ ...sets[id], alpha }));

    const shapeIds = dispatch(actionsOnlyGetSetShapeIds([id]));
    emit(ENGINE.UPDATE.SHAPES.ALPHA.IN_PLACE, shapeIds);
    dispatch(remember());
  };
}

export const issueResetSetAlphas: ThunkActionFunc<[alpha?: number], void> =
  (alpha = 1) =>
  (d, gs, { emit }) => {
    emit(ACTIONS.RESET_SET_ALPHA, alpha);

    const { sets } = gs().modelReducer;

    // reset alpha for individual sets
    Object.values(sets).forEach((set) => {
      d(updateSet({ ...set, alpha }));
    });

    // reset alpha for "All Sets" as well
    d(changeAllSetsAlpha(alpha));

    //rerender set shapes
    const shapeIds = d(actionsOnlyGetSetShapeIds(Object.keys(sets)));
    emit(ENGINE.UPDATE.SHAPES.ALPHA.BY_IDS, alpha, shapeIds);
    d(remember());
  };

export const issueChangeAllSetsAlpha: ThunkActionFunc<[alpha?: number], void> =
  (alpha = 1) =>
  (d, gs, { emit }) => {
    emit(ACTIONS.CHANGE_ALL_SETS_ALPHA, alpha);
    const { sets } = gs().modelReducer;
    const shapeIds = d(actionsOnlyGetSetShapeIds(Object.keys(sets)));
    d(changeAllSetsAlpha(alpha));
    emit(ENGINE.UPDATE.SHAPES.ALPHA.IN_PLACE, shapeIds);
    d(remember());
  };

/**
 * Set the alpha (visibility) for the node
 * @param id - The ID of the node to change
 * @param alpha - The alpha value (between 0 and 1, inclusive)
 */
export const issueChangeNodeAlpha: ThunkActionFunc<
  [id: string, alpha: number],
  void
> =
  (id, alpha) =>
  (dispatch, gs, { emit }) => {
    emit(ACTIONS.CHANGE_NODE_ALPHA, id, alpha);
    const nodes = gs().modelReducer.shapes;
    emit(ENGINE.UPDATE.SHAPES.ALPHA.BY_CONFIGS, alpha, [nodes[id]]);
    dispatch(updateShapeByConfig({ ...nodes[id], alpha }));
    dispatch(remember());
  };

/**
 * Reset all node alphas to the same value (default 1)
 * @param [alpha] - The alpha value to which all nodes will be reset
 * (between 0 and 1, inclusive)
 **/
export const issueResetNodeAlphas: ThunkActionFunc<[alpha: number], void> =
  (alpha = 1) =>
  (d, gs, { emit }) => {
    emit(ACTIONS.RESET_NODE_ALPHA, alpha);
    const model = gs().modelReducer;
    // reset alpha for "All Nodes"
    d(changeAllNodesAlpha(alpha));

    // reset alpha for individual nodes
    const updatedShapes = Object.values(model.nodes).map((node) => ({
      ...model.shapes[node.id],
      alpha,
    }));
    emit(ENGINE.UPDATE.SHAPES.ALPHA.BY_CONFIGS, alpha, updatedShapes);
    d(updateShapesByConfig(updatedShapes));
    d(remember());
  };

/**
 * Changes the "All Nodes" alpha, which is an alpha value
 * applied multiplicatively to each node when rendering.
 * This value is stored separately and does not affect the
 * individual alphas of each node.
 * @param [alpha] - The alpha value
 **/
export const issueChangeAllNodesAlpha: ThunkActionFunc<
  [alpha?: number],
  void
> =
  (alpha = 1) =>
  (d, gs, { emit }) => {
    emit(ACTIONS.CHANGE_ALL_NODES_ALPHA, alpha);
    const nodes = Object.values(gs().modelReducer.shapes).filter(
      (shape) => shape.type === SHAPE.NODE
    );
    const nodeIds = nodes.map((node) => node.id);
    d(changeAllNodesAlpha(alpha));
    emit(ENGINE.UPDATE.SHAPES.ALPHA.IN_PLACE, nodeIds);
    d(remember());
  };

/**
 * Set the alpha (visibility) for the edge
 * @param id - The ID of the edge to change
 * @param alpha - The alpha value (between 0 and 1, inclusive)
 **/
export const issueChangeEdgeAlpha: ThunkActionFunc<
  [id: string, alpha: number],
  void
> =
  (id, alpha) =>
  (d, gs, { emit }) => {
    emit(ACTIONS.CHANGE_EDGE_ALPHA, id, alpha);
    const edges = gs().modelReducer.shapes;
    emit(ENGINE.UPDATE.SHAPES.ALPHA.BY_CONFIGS, alpha, [edges[id]]);
    d(updateShapeByConfig({ ...edges[id], alpha }));
    d(remember());
  };

/**
 * Reset all edge alphas to the same value (default 1)
 * @param [alpha] - The alpha value to which all edges will be reset
 * (between 0 and 1, inclusive)
 **/
export const issueResetEdgeAlphas: ThunkActionFunc<[alpha?: number], void> =
  (alpha = 1) =>
  (d, gs, { emit }) => {
    emit(ACTIONS.RESET_EDGE_ALPHA, alpha);

    const model = gs().modelReducer;
    // reset alpha for "All Edges"
    d(changeAllEdgesAlpha(alpha));

    // reset alpha for individual edges
    const updatedShapes = Object.values(model.edges).map((edge) => ({
      ...model.shapes[edge.id],
      alpha,
    }));

    emit(ENGINE.UPDATE.SHAPES.ALPHA.BY_CONFIGS, alpha, updatedShapes);
    //rerender edges
    d(updateShapesByConfig(updatedShapes));
    d(remember());
  };

/**
 * Changes the "All Edges" alpha, which is an alpha value
 * applied multiplicatively to each edge when rendering.
 * This value is stored separately and does not affect the
 * individual alphas of each edge.
 * @param [alpha] - The alpha value
 **/
export const issueChangeAllEdgesAlpha: ThunkActionFunc<[alpha: number], void> =
  (alpha = 1) =>
  (d, gs, { emit }) => {
    emit(ACTIONS.CHANGE_ALL_EDGES_ALPHA, alpha);

    const edges = Object.values(gs().modelReducer.shapes).filter(
      (shape) => shape.type === SHAPE.EDGE
    );
    const edgeIds = edges.map((edge) => edge.id);
    d(changeAllEdgesAlpha(alpha));
    emit(ENGINE.UPDATE.SHAPES.ALPHA.IN_PLACE, edgeIds);
    d(remember());
  };

/**
 * adds a selection to the existing selections
 * @param {import('types').CellSelection[]} cells - array of active cell selections
 **/
export const setSelection =
  (
    cells: CellResult[] = [],
    shapeResults: string[] = [],
    setResults: string[] = []
  ) =>
  async (dispatch: MetraThunkDispatch, getState: () => RootReducer) => {
    const model = getState().modelReducer;

    // do not select cells when the table is closed
    if (!model.base.showPropsPanel) return;

    // only select cells on the current tab
    const visibleCells = cells.filter(
      (cell) => cell[0] === model.tableView.type
    );

    const sel = shapeResults.concat(setResults);

    if (
      [SCHEMA_CATEGORY.MODELPROPS, SCHEMA_CATEGORY.MODELCALCS].includes(
        model.tableView.type
      )
    ) {
      if (!model.base.isSearching) dispatch(issueDeselectAll());
      else {
        dispatch(issueSelectExactly(sel, 'both'));
        dispatch(setIsSearching(true));
      }
    }

    return dispatch({
      type: PROPERTY_SELECT.SET.SELECTION,
      payload: { cells: visibleCells, tableItems: model.base.tableItems },
    });
  };

export const clearSearchResults: ThunkActionFunc = () => (dispatch) => {
  // will only clear count and blue text, not the actual search text
  dispatch(clearModelSearchResults());
  dispatch(setIsSearching(false));
};
