import { combineReducers } from 'redux';
import { MEDIA_TYPE, METRA } from 'utils/constants';
import { DEV_PROXY } from 'utils/settings';
import { buildDownloadUrl } from 'utils/url-builders';
import { dispatcher } from 'utils/utils-extra';
import {
  apiGet,
  apiPost,
  apiDelete,
  apiCancel,
  apiPatch,
  MEDIA,
  ENTITIES,
  ARCHIVE,
  NO_OP,
  TOASTS,
  genericValueReducer,
  TASKS,
} from 'modules/common';
import { MESSAGES } from 'modules/ui/messages';
import { fetchMedia, setSelected } from 'modules/library/library-extra';
import { getProject } from 'modules/project/project-extra';
import { loadMore } from 'modules/entities/collections';
import { createToastMessage } from 'modules/ui/toasts';
import { uploadFile, getMedia } from './actions';
import { tagCacheReducer } from './tagCache';
import { downloadCacheReducer } from './downloadCache';
import { finishTask, getMediaByID } from './media-extra';
import { SCHEMA_NAME } from './constants';
import { makeDeleteSuccess } from 'modules/entities/utils';
import OrgContext from 'utils/OrganizationContext';
import { resetSelected } from 'modules/archive/archive-table-helpers';

/*
 * ACTIONS
 */

export const getProjectMedia = (projectId, query) =>
  getMedia({
    projectId,
    belongsTo: '',
    query,
    collection: 'projectMedia',
    guildCName: OrgContext.guild,
  });

/**
 *
 * @param {PropertyKey} projectId
 * @param {import('types').Option<number>} belongsTo
 * @param {Partial<import('types').MediaQuery>} query,
 * @returns {import('types').MetraThunkAction<
 * unknown,
 * unknown,
 * Promise<import('types').MetraApiAction<
 *   import('types').NormalizedResult<'media'>,
 *   void
 * >>>}
 */
export const getModelMedia = (projectId, belongsTo, query) =>
  getMedia({ projectId, belongsTo, query, collection: 'modelMedia' });

/**
 *
 * @param {} projectId
 * @param {} query
 */
export const getProjectModels = (projectId, query = {}) =>
  getMedia({
    /* eslint-disable camelcase */
    projectId,
    belongsTo: '',
    query: { name__endswith: METRA.EXTENSION, ...query },
    collection: 'projectModels',
    guildCName: OrgContext.guild,
    /* eslint-enable camelcase */
  });

/**
 *
 * @param {string} projectId
 * @returns {import('types').MetraThunkAction<
 * void,
 * import('types').NormalizedResult,
 * Promise<
 * import('types').MetraMedia[]>>}
 */
export const getProjectMediaImages = (projectId) => async (dispatch) => {
  const results = await dispatch(
    getMedia({
      projectId,
      query: { name__endswith: METRA.IMAGES },
      collection: 'projectMedia',
      guildCName: OrgContext.guild,
    })
  );

  const expectedFileCount = results?.payload?.count;
  let mediaImageFiles = Object.values(results?.payload?.entities?.media);

  while (
    expectedFileCount > mediaImageFiles.length &&
    results.payload.next !== null
  ) {
    const additionalFiles = await dispatch(loadMore('projectMedia'));
    if (additionalFiles.error) break;
    mediaImageFiles.push(
      ...Object.values(additionalFiles?.payload?.entities?.media)
    );
  }
  return mediaImageFiles;
};

export const getArchivedMedia = (projectId = 0) => {
  return apiGet({
    entity: SCHEMA_NAME,
    // eslint-disable-next-line camelcase
    params: { project: projectId, show_archived: true },
    types: [ARCHIVE.GET_SUCCESS, ARCHIVE.GET_FAILURE],
    error: MESSAGES.ERROR.GET.ARCHIVED_MEDIA,
  });
};

/**
 * Returns if a version is valid
 *
 * @param {number} mediaId
 * @param {number} projectId
 * @param {number} versionId
 *
 * @returns {bool}
 */
export const asyncGetVersionByID = async (versionId) => {
  await dispatcher(getVersionByID(versionId));
};

/**
 * @template P
 * @template E
 * @template S
 * @param {Numberish} id
 * @param {string} mediaType
 * @param {Partial<import('types').ApiRequest<P,E,S>>} [opts]
 * @return {import('types').MetraThunkAction<
 *   void,
 *   import('types').NormalizedResult,
 *   Promise<import('types').MetraApiAction<import('types').NormalizedResult<'versions'|'media'>>>
 * >}
 */
export const getVersionByID =
  (id, mediaType, opts = {}) =>
  (dispatch) => {
    return dispatch(
      apiGet({
        entity: 'uploads',
        record: `${id}`,
        params: { media_type: mediaType },
        types: [ENTITIES.ACTION_SUCCESS, MEDIA.GET_VERSION_FAILURE],
        error: MESSAGES.ERROR.GET.VERSION,
        ...opts,
      })
    );
  };

/**
 * gets path of media
 * @param {Numberish} id
 * @returns {import('types').ThunkAction<
 * Promise<
 * import('types').MetraApiAction<
 *   import('types').PathResult,
 *   void
 * >>>}
 */
export const getMediaPath = (id) => {
  return apiGet({
    entity: SCHEMA_NAME,
    record: `${id}/_path`,
    types: [NO_OP.SUCCESS, MEDIA.GET_FAILURE],
    error: MESSAGES.ERROR.GET.MEDIA_ID,
  });
};

/**
 *
 * @param {Numberish} folderId
 * @param {
 *   | AsRecord<import('types').MetraMedia>
 *   | import('seamless-immutable').Immutable<AsRecord<import('types').MetraMedia>>
 * } media - the current media record state
 * @returns {string}
 */
export function folderPath(folderId, media) {
  const folder = media[folderId];
  if (folder == null) return 'Files';
  if (folder.belongs_to == null) return `Files/${folder.name}`;
  const parentId = media[folder.belongs_to]?.id;
  if (parentId == null) return `.../${folder.name}`; // parent not loaded yet
  const parentName = folderPath(parentId, media);
  return `${parentName}/${folder.name}`;
}

export const createNewMedia = ([file], project, belongsTo) => {
  return async (dispatch) => {
    const upload = await dispatch(uploadFile({ file, project, belongsTo }));
    if (upload.error)
      return dispatch({ type: MEDIA.CREATE_FAILURE, payload: upload.payload });
  };
};

/**
 *
 * @param {Numberish} id
 * @returns {import('types').MetraThunkAction<
 *   void,
 *   import('types').NormalizedResult,
 *   Promise<import('types').MetraApiAction<import('types').NormalizedResult<'media'>>>
 * >>}
 */
export const cancelFile = (id) => {
  return apiCancel({
    entity: `${SCHEMA_NAME}/${id}/_cancel`,
    types: [ENTITIES.ACTION_SUCCESS, MEDIA.CANCEL_FAILURE],
    meta: { record: id, schema: 'media' },
    error: MESSAGES.ERROR.CANCEL.FILE,
  });
};

/**
 * DOWNLOAD UTILITIES
 */

/**
 * Modify the path to download a zip of a folder, or multiple files and/or models
 * When developing locally, need to swap client domain for api domain
 * Fragile to port changes
 * @param {string} signedUrl raw version of download media url
 * @returns {string} url where local versions of links are redirected to the api
 */
export const downloadMediaLink = (signedUrl) =>
  signedUrl.replace('http://localhost:3000/', DEV_PROXY);

/**
 * Use the last portion of the download url as the name of the download
 * @param {string} signedUrl
 * @returns {string} name of the file to download
 */
export const downloadMediaName = (signedUrl) => signedUrl.split('/').pop();

/**
 * Make a Post request of file versions and folder ids
 * @param {Array<number>} fileVersionIds versions of files or models
 * @param {Array<number} folderIds ids of folders
 * @returns {Promise<import('types/modules/api').MetraApiAction<unknown>>} error or payload response from api
 */
export const postDownloadMedia = (fileVersionIds, folderIds) => {
  return apiPost({
    entity: 'uploads/_download',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ids: fileVersionIds,
      folders: folderIds,
    }),
    types: [MEDIA.DOWNLOAD_SUCCESS, MEDIA.DOWNLOAD_FAILURE],
    error: [MESSAGES.ERROR.DOWNLOAD.MEDIA],
  });
};

/**
 * Generate a link to download a single file or model
 * @param {number} versionId version of file or model
 * @param {boolean} mediaIsModel whether the media is a file or model
 * @returns {string} link to download resource from API
 */
export const downloadVersionLink = (versionId, mediaIsModel = false) =>
  buildDownloadUrl(versionId) + `?tar=${mediaIsModel}`;

/**
 * Generate a link element and then click it, to download a resource from the api
 * @param {string} href url path to resource to be downloaded
 * @param {string} name
 * @modifies {DOM} creates and clicks an 'a' element on the DOM
 */
export const clickDownloadLink = (href, name) => {
  const a = document.createElement('a');
  a.href = href;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  if (sessionStorage.getItem('unsavedIgnoreNextRedirect'))
    sessionStorage.removeItem('unsavedIgnoreNextRedirect');
};

/**
 * archives the media with the given id
 * @param {number|string} id
 */
export const archiveFile = (id, projectId, successMessage) => {
  return async (dispatch) => {
    const result = await dispatch(
      apiPatch({
        entity: SCHEMA_NAME,
        record: id.toString(),
        body: JSON.stringify({
          // eslint-disable-next-line camelcase
          archived_at: new Date().toISOString(),
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        types: [ENTITIES.ACTION_SUCCESS, MEDIA.ARCHIVE_FAILURE],
        meta: { record: id, schema: 'media' },
        success: successMessage,
        undo: () => {
          dispatch(restoreFile(id, projectId));
        },
      })
    );
    if (result.error) {
      const message =
        result.payload.status === 403
          ? MESSAGES.ERROR.ARCHIVE.READONLY
          : MESSAGES.ERROR.ARCHIVE.FILE;
      dispatch(createToastMessage(TOASTS.ERROR, message));
    }
    return result;
  };
};

export const deleteFile = (id) => {
  return async (dispatch, getState) => {
    const result = await dispatch(
      apiDelete({
        entity: SCHEMA_NAME,
        record: id.toString(),
        types: [ENTITIES.ACTION_SUCCESS, MEDIA.DELETE_FAILURE],
        meta: { record: id, schema: 'media' },
        success: MESSAGES.SUCCESS.DELETE.FILE,
      })
    );
    if (result.error) {
      const message =
        result.payload.status === 403
          ? MESSAGES.ERROR.DELETE.READONLY
          : MESSAGES.ERROR.DELETE.FILE;
      dispatch(createToastMessage(TOASTS.ERROR, message));
    } else {
      // file might have been selected - if so, remove it from selection
      const selectedFiles = getState().libraryReducer.selected;
      if (selectedFiles?.ids?.includes(id)) {
        const index = selectedFiles.ids.indexOf(id);
        const newSelectedIds = [...selectedFiles.ids];
        newSelectedIds.splice(index, 1);
        await dispatch(setSelected(newSelectedIds));
      }
      await dispatch(makeDeleteSuccess({ id: id }, 'media'));
    }
  };
};

export const deleteArchiveFile = (id) => {
  return async (dispatch) => {
    const result = await dispatch(
      apiDelete({
        entity: SCHEMA_NAME,
        record: id.toString(),
        types: [ENTITIES.ACTION_SUCCESS, MEDIA.DELETE_FAILURE],
        meta: { record: id, schema: 'media' },
        success: MESSAGES.SUCCESS.DELETE.FILE,
      })
    );
    if (result.error) {
      const message =
        result.payload.status === 403
          ? MESSAGES.ERROR.DELETE.READONLY
          : MESSAGES.ERROR.DELETE.FILE;
      dispatch(createToastMessage(TOASTS.ERROR, message));
    } else {
      dispatch(resetSelected());
      dispatch(makeDeleteSuccess({ id: id }, 'media'));
    }
  };
};

export const restoreFile = (id, projectId) => {
  return async (dispatch) => {
    const result = await dispatch(
      apiPatch({
        entity: SCHEMA_NAME,
        record: `${id}`,
        body: JSON.stringify({
          // eslint-disable-next-line camelcase
          archived_at: null,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        types: [ENTITIES.ACTION_SUCCESS, MEDIA.RESTORE_FAILURE],
        meta: { record: id },
        error: MESSAGES.ERROR.RESTORE.FILE,
        success: MESSAGES.SUCCESS.RESTORE.FILE,
      })
    );
    dispatch(fetchMedia());
    // re-fetch the project - the tags might have changed when the
    // media was restored
    dispatch(getProject(projectId));
    return result;
  };
};

export const checkModifiedMedia = (recordId) => {
  return async (dispatch, getState) => {
    const record = await dispatch(
      apiGet({
        entity: SCHEMA_NAME,
        record: recordId,
        types: [MEDIA.MODIFIED_CHECK_SUCCESS, MEDIA.MODIFIED_CHECK_FAILURE],
        error: MESSAGES.ERROR.GET.MODIFIED_MEDIA,
      })
    );
    //if there was not an error, check to see if the upload length has changed
    const media = getState().entityReducer[SCHEMA_NAME];
    if (
      !record.error &&
      media &&
      media[recordId] &&
      media[recordId].uploads &&
      record.payload.uploads &&
      record.payload.uploads.length !== media[recordId].uploads.length
    ) {
      return true;
    } else {
      return false;
    }
  };
};

/**
 * @type {import('types').MetraActionFunc<[
 *   version: 'Exit' | 'Return' | 'None'
 * ],
 *   'Exit' | 'Return' | 'None' >}
 */
export const setFileImportModalVersion = (version) => {
  return (dispatch) => {
    dispatch({
      type: MEDIA.MODAL_IMPORT_VERSION,
      payload: version, // "Exit" | "Return" | "None"
    });
  };
};

/**
 *
 * @param {} id
 * @param {} data
 * @returns {import('types/common').MetraThunkAction<
 *   import('types/modules/api').MetraApiAction<import('types/modules/api').NormalizedResult>
 *   Promise<
 *     import('types/modules/api').MetraApiAction<import('types/modules/api').NormalizedResult>
 * >>}
 */
export const updateMedia = (id, data) => {
  return (dispatch) =>
    dispatch(
      apiPatch({
        entity: SCHEMA_NAME,
        record: `${id}`,
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
        types: [ENTITIES.ACTION_SUCCESS, MEDIA.UPDATE_FAILURE],
        error: MESSAGES.ERROR.UPDATE,
      })
    );
};

export const renameMedia = (id, data) => {
  return (dispatch) =>
    dispatch(
      apiPatch({
        entity: SCHEMA_NAME,
        record: `${id}`,
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
        types: [ENTITIES.ACTION_SUCCESS, MEDIA.UPDATE_FAILURE],
      })
    );
};

export const setMediaTags = (tagIds) => ({
  type: MEDIA.FILTER_TAGS,
  payload: tagIds,
});

/**
 *
 * @param {Numberish} projectId
 * @param {Option<Numberish>}[parent]
 * @param {string} [name]
 * @returns {import('types').MetraThunkAction<
 *   void,
 *   import('types').MetraMedia,
 *   Promise<import('types').MetraApiAction<import('types').MetraMedia>>
 * >}
 */
export const createFolder =
  (projectId, parent = null, name = '') =>
  async (dispatch, getState) => {
    // to create folders across guilds, we need the guild for this project
    const entities = getState().entityReducer;
    const gid = entities.projects[projectId.toString()].guild;
    const cname = entities.guilds[gid].cname;

    const folder = await dispatch(
      apiPost({
        entity: `guild/${cname}/${SCHEMA_NAME}`,
        body: JSON.stringify({
          name: name,
          project: projectId,
          // eslint-disable-next-line
          belongs_to: !!parent ? parent : '',
          versions: [],
          tags: [],
          media_type: MEDIA_TYPE.FOLDER,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        meta: {
          excludeGuild: true,
          schema: SCHEMA_NAME,
        },
        types: [ENTITIES.ACTION_SUCCESS, MEDIA.CREATE_FAILURE],
      })
    );

    if (folder.error) {
      let message = MESSAGES.ERROR.CREATE.FOLDER;
      if (
        folder.payload.status === 400 &&
        'error' in folder.payload.response &&
        typeof folder.payload.response.error === 'object' &&
        folder.payload.response.error &&
        'project' in folder.payload.response.error &&
        folder.payload.response.error.project
      ) {
        const protected_proj = [
          ...Object.values(folder.payload.response.error.project),
        ];
        if (
          protected_proj.find((s) =>
            s.includes('You do not have permission to modify project')
          )
        ) {
          message = MESSAGES.ERROR.CREATE.FOLDER_IN_PROTECTED_PROJECT;
        }
      }
      return dispatch(createToastMessage(TOASTS.ERROR, message));
    }

    return folder;
  };

export const copyMedia =
  (file, replacing, modelId, projectId) => async (dispatch) => {
    const copy = await dispatch(
      apiPost({
        entity: 'media/_cp',
        body: JSON.stringify({
          media: file,
          project: projectId,
          belongs_to: modelId,
          overwrite_existing: replacing,
        }),
        headers: { 'Content-Type': 'application/json' },
        types: [MEDIA.CREATE_SUCCESS, MEDIA.CREATE_FAILURE],
        collection: 'modelMedia',
      })
    );
    if (copy.error) {
      return dispatch({ type: MEDIA.CREATE_FAILURE, payload: copy.payload });
    }

    const task = copy.payload.task[0];

    if (task.status >= 400) {
      return dispatch({ type: MEDIA.CREATE_FAILURE, payload: copy.payload });
    }

    const finished = await dispatch(finishTask(task.response.id));

    if (finished.status === TASKS.FAILED) {
      return dispatch({ type: MEDIA.CREATE_FAILURE, payload: finished });
    }

    const media = await dispatch(getMediaByID(finished.resource_id));

    // make the getMedia look like we just created the media
    // this way copyMedia interface stays the same as before tasks
    media.type = MEDIA.CREATE_SUCCESS;
    return media;
  };

/**
 * SCHEMAS
 */

/**
 * @type {import('types').MediaReducer>}
 */
export const initialMediaState = {
  fileImportModalVersion: 'None', //'Exit' || 'Return' || 'None'
  filterTags: [],
  tagCache: {},
  downloadCache: {},
};

/**
 * @type {import('types').Reducer<
 *   import('types').MediaReducer,
 *   import('types').MetraAction<any, any, any, import('types').MediaReducer>
 * >}
 */
export const mediaReducer = combineReducers({
  fileImportModalVersion: genericValueReducer(
    MEDIA.MODAL_IMPORT_VERSION,
    initialMediaState.fileImportModalVersion
  ),
  filterTags: genericValueReducer(
    MEDIA.FILTER_TAGS,
    initialMediaState.filterTags
  ),
  tagCache: tagCacheReducer,
  downloadCache: downloadCacheReducer,
});
