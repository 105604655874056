import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faCog,
  faEllipsisH,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faHome,
  faInfoCircle,
  faQuestionCircle,
  faSignInAlt,
  faSignOutAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import {
  faBars,
  faExternalLinkAlt,
  faGripHorizontal,
  faUser,
  faSearch,
  faSpinner,
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/pro-solid-svg-icons';
import ModelAssetsArray from 'assets';
import { ReactComponent as AlertTriangleSVG } from 'assets/alert-triangle.svg';
import { ReactComponent as ArchiveSVG } from 'assets/archive.svg';
import { ReactComponent as BrokenLinkSVG } from 'assets/link-broken.svg';
import { ReactComponent as CheckmarkSVG } from 'assets/checkmark.svg';
import { ReactComponent as UnarchiveSVG } from 'assets/unarchive.svg';
import { ReactComponent as MaximizeSVG } from 'assets/maximize.svg';
import { ReactComponent as MetraSVG } from 'assets/METRA_White.svg';
import { ReactComponent as OpenYourDataSVG } from 'assets/OpenYourData.svg';
import { ReactComponent as FilterSVG } from 'assets/filter.svg';
import { ReactComponent as FilterOnSVG } from 'assets/filter-on.svg';
import { ReactComponent as FilterOffSVG } from 'assets/filter-off.svg';
import { ReactComponent as SaveFilterSVG } from 'assets/save-filter.svg';
import { ReactComponent as ChangeEdgeDirection } from 'assets/Change_Edge_Direction.svg';
import { ReactComponent as ClearFilterSVG } from 'assets/clear_filter.svg';
import { ReactComponent as DragTextureSVG } from 'assets/DragTexture_icon.svg';
import { ReactComponent as TableOrientationHorizontal } from 'assets/view-mpt-potrait.svg';
import { ReactComponent as TableOrientationVertical } from 'assets/view-mpt-landscape.svg';
import { ReactComponent as TableResizeCells } from 'assets/cell-size.svg';
import { ReactComponent as ListView } from 'assets/list.svg';
import { ReactComponent as OpacitySVG } from 'assets/icon_opacity.svg';
import { ReactComponent as TrashSVG } from 'assets/trash.svg';
import { ReactComponent as CardView } from 'assets/card.svg';
import { ReactComponent as ShapesSVG } from 'assets/shapes.svg';
import { ReactComponent as InformationSVG } from 'assets/information.svg';
import { ReactComponent as HomeSVG } from 'assets/home.svg';
import { ReactComponent as EdgeSizesSVG } from 'assets/dropdown-icon_lineWeight.svg';
import { ReactComponent as EdgeStylesSVG } from 'assets/edgeStyle_strokes.svg';
import { ReactComponent as edgeToolSVG } from 'assets/edge-tool.svg';
import { ReactComponent as labelPositionSVG } from 'assets/label-position.svg';
import { ReactComponent as labelContentSVG } from 'assets/label-content.svg';
import { ReactComponent as imageToolSVG } from 'assets/icons-Toolbar_image.svg';
import { ReactComponent as AdminUserIconSVG } from 'assets/adminUserIcon.svg';
import { ReactComponent as HotKeyIconSVG } from 'assets/icon_hotKey.svg';
import { ReactComponent as IconCopy } from 'assets/icon_copy.svg';
import { ReactComponent as IconCoordinates } from 'assets/icon_coordinates.svg';
import { ReactComponent as FileScanInProgressSVG } from 'assets/file-scan-in-progress.svg';
import { ReactComponent as InvalidSVG } from 'assets/invalid.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/magnifying-glass.svg';
import { ReactComponent as PlusSVG } from 'assets/plus.svg';
import { ReactComponent as PointerLeftSVG } from 'assets/pointer-left.svg';
import { ReactComponent as PointerRightSVG } from 'assets/pointer-right.svg';
import { ReactComponent as ProtectedSVG } from 'assets/protected.svg';
import { ReactComponent as ProtectedBackgroundSVG } from 'assets/protectedBackground.svg';
import { ReactComponent as ReadOnlySVG } from 'assets/read-only.svg';
import { ReactComponent as SelectionArrowIcon } from 'assets/icons/selection-arrow.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as SortUpSVG } from 'assets/sort-up.svg';
import { ReactComponent as SortDownSVG } from 'assets/sort-down.svg';
import { ReactComponent as StylePanelIconSVG } from 'assets/style-panel.svg';
import { ReactComponent as SquareArrowRightSVG } from 'assets/square-arrow-right.svg';
import { ReactComponent as DoubleChevronLeft } from 'assets/double-chevron-left.svg';
import { ReactComponent as DoubleChevronRight } from 'assets/double-chevron-right.svg';
import { ReactComponent as SingleChevronLeft } from 'assets/chevron-left.svg';
import { ReactComponent as TagIconEmpty } from 'assets/icon_tag-empty.svg';
import { ReactComponent as TagIconSolid } from 'assets/icon_tag-solid.svg';
import { ReactComponent as TOCsvg } from 'assets/TOC.svg';
import { ReactComponent as RedSpinner } from 'assets/spinner_red.svg';
import { ReactComponent as Redo } from 'assets/redo.svg';
import { ReactComponent as Undo } from 'assets/undo.svg';
import { ReactComponent as EqualSign } from 'assets/equal-sign.svg';
import { ReactComponent as CursorPointer } from 'assets/cursor-pointer.svg';
import { ReactComponent as PanHand } from 'assets/hand.svg';
import { ReactComponent as NodeSVG } from 'assets/circle.svg';
import { ReactComponent as EdgeDirection } from 'assets/edge-direction.svg';
import { ReactComponent as EdgeReassign } from 'assets/edge-reassign.svg';
import { ReactComponent as Lock } from 'assets/Lock.svg';
import { ReactComponent as Unlock } from 'assets/lock-unlock.svg';
import { ReactComponent as QuestionCircle } from 'assets/circle-questionmark.svg';
import { ReactComponent as ExternalLink } from 'assets/external-link.svg';
import { ReactComponent as ExitLarge } from 'assets/x-mark-large.svg';
import { ReactComponent as PermissionsKey } from 'assets/permissions-keys.svg';
import { ReactComponent as PencilSVG } from 'assets/Pencil_1.svg';
import { ReactComponent as TextSVG } from 'assets/text.svg';
import { ReactComponent as LineSVG } from 'assets/Line.svg';
import { ReactComponent as BoxSVG } from 'assets/Box.svg';
import { ReactComponent as Edit } from 'assets/edit.svg';
import { ReactComponent as EyeOff } from 'assets/eye-off.svg';
import { ReactComponent as EyeOn } from 'assets/eye-on.svg';
import { ReactComponent as CopySVG } from 'assets/copy.svg';
import { ReactComponent as FolderPlusSVG } from 'assets/folder-plus.svg';
import { ReactComponent as DownloadSVG } from 'assets/download.svg';
import { ReactComponent as UploadSVG } from 'assets/upload.svg';
import { ReactComponent as ImageIcon } from 'assets/icon_image.svg';
import { ReactComponent as EdgeStyleSolid } from 'assets/edgeStyle__solid.svg';
import { ReactComponent as EdgeStyleShortDash } from 'assets/edgeStyle__dash.svg';
import { ReactComponent as EdgeStyleLongDash } from 'assets/edgeStyle__longDash.svg';
import { ReactComponent as EdgeStyleSquareDotted } from 'assets/edgeStyle__square.svg';
import { ReactComponent as EdgeStyleSquareLongDash } from 'assets/edgeStyle__dash_square_dash.svg';
import { ReactComponent as EdgeStyleDot } from 'assets/edgeStyle__dot.svg';
import { ReactComponent as EdgeArrowHeadCirclePlus } from 'assets/icon-Arrowheads_circleCross-open.svg';
import { ReactComponent as EdgeArrowHeadCurved } from 'assets/icon-Arrowheads_arrow-curved-solid.svg';
import { ReactComponent as EdgeArrowHeadCurvedFilledDot } from 'assets/icon-Arrowheads_arrowCircle-solid.svg';
import { ReactComponent as EdgeArrowHeadFilled } from 'assets/icon-Arrowheads_arrow-straight-solid.svg';
import { ReactComponent as EdgeArrowHeadUnfilled } from 'assets/icon-Arrowheads_triangle-open.svg';
import { ReactComponent as EdgeArrowHeadNone } from 'assets/icon-Arrowheads_none.svg';
import { ReactComponent as EdgeArrowHeadFilledDot } from 'assets/icon-Arrowheads_circle-solid.svg';
import { ReactComponent as EdgeArrowHeadFilledDiamond } from 'assets/icon-Arrowheads_diamond-solid.svg';
import { ReactComponent as EdgeArrowHeadUnfilledDiamond } from 'assets/icon-Arrowheads_diamond_open.svg';
import { ReactComponent as ColorSVG } from 'assets/color.svg';
import { ReactComponent as CircleSVG } from 'assets/circle.svg';
import { ReactComponent as SquareSVG } from 'assets/square.svg';
import { ReactComponent as TriangleSVG } from 'assets/triangle.svg';
import { ReactComponent as GripIconSVG } from 'assets/grip-icon.svg';
import { ReactComponent as ColorAlert } from 'assets/____icon_alert-triangle_copy.svg';
import { ReactComponent as LogOutSVG } from 'assets/log-out.svg';
import { ReactComponent as FolderCloseChevron } from 'assets/folder-close-chevrons.svg';
import { ReactComponent as FolderOpenChevron } from 'assets/folder-open-chevrons.svg';
import { ReactComponent as FileTypeCsv } from 'assets/file-type/file-csv.svg';
import { ReactComponent as FileTypeDocument } from 'assets/file-type/file-document.svg';
import { ReactComponent as FileTypeExcel } from 'assets/file-type/file-excel.svg';
import { ReactComponent as FileTypeHtml } from 'assets/file-type/file-html.svg';
import { ReactComponent as FileTypeImage } from 'assets/file-type/file-image.svg';
import { ReactComponent as FileTypeJson } from 'assets/file-type/file-json.svg';
import { ReactComponent as FileTypeMarkdown } from 'assets/file-type/file-markdown.svg';
import { ReactComponent as FileTypeMetraNote } from 'assets/file-type/file-metraNote.svg';
import { ReactComponent as FileTypeMetraModel } from 'assets/file-type/file-model.svg';
import { ReactComponent as FileTypePdf } from 'assets/file-type/file-pdf.svg';
import { ReactComponent as FileTypePowerpoint } from 'assets/file-type/file-powerpoint.svg';
import { ReactComponent as FileTypeScript } from 'assets/file-type/file-script.svg';
import { ReactComponent as FileTypeUnknown } from 'assets/file-type/file-unknown.svg';
import { ReactComponent as FileTypeWord } from 'assets/file-type/file-word.svg';
import { ReactComponent as FileTypeZip } from 'assets/file-type/file-zip.svg';
import { ReactComponent as CircleArrowUp } from 'assets/circle-arrow-up.svg';
import { ReactComponent as MiniNode } from 'assets/QPV_icons_node.svg';
import { ReactComponent as MiniEdge } from 'assets/QPV_icons_edge.svg';
import { ReactComponent as ChevronUp } from 'assets/chevron-up.svg';
import { ReactComponent as ChevronDown } from 'assets/chevron-down.svg';
import T3 from 'assets/Tenet3.png';
import T3_BLUE_AND_WHITE from 'assets/Tenet3__BluW.svg';
import T3White from 'assets/Tenet3white.png';
import ChangeDirection from 'assets/Change-Edge-Direction.png';
import Circle from 'assets/circle.png';
import CACGrey from 'assets/CAC_Graphic_grey.png';
import NO_MATCH from 'assets/404.png';

const ModelAssets = Object.fromEntries(
  ModelAssetsArray.map((item) => [item.name, item.url])
);

library.add(
  // regular
  faAngleDown,
  faAngleUp,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faChevronLeft,
  faCog,
  faEllipsisH,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faHome,
  faInfoCircle,
  faQuestionCircle,
  faSignInAlt,
  faSort,
  faSortUp,
  faSortDown,
  // light
  faTimes,
  // solid
  faBars,
  faExternalLinkAlt,
  faGripHorizontal,
  faUser,
  faSearch,
  faSpinner
);

export const ASSETS = {
  ADMIN_USER_ICON: 'assets/ADMIN_USER_ICON',
  ALERT_TRIANGLE: 'assets/ALERT_TRIANGLE',
  ARCHIVE: {
    ARCHIVE: 'asset/archive/ARCHIVE',
    UNARCHIVE: 'asset/archive/UNARCHIVE',
  },
  BROKEN_LINK: 'asset/BROKEN_LINK',
  CAC_GREY: 'asset/CAC_GREY',
  CAC_WHITE: 'asset/CAC_WHITE',
  CHANGE_EDGE_DIRECTION: 'asset/CHANGE_EDGE_DIRECTION',
  CHECKMARK: 'asset/CHECKMARK',
  CIRCLE: 'asset/CIRCLE',
  COPY: 'asset/COPY',
  QUESTION_CIRCLE: 'assets/QUESTION_CIRCLE',
  CLEAR_FILTER: 'asset/CLEAR_FILTER',
  DOWNLOAD: 'asset/DOWNLOAD',
  DRAG_TEXTURE: 'asset/DRAG_TEXTURE',
  INFORMATION: 'asset/INFORMATION',
  MAXIMIZE: 'asset/MAXIMIZE',
  METRA: 'asset/METRA',
  NO_MATCH: 'asset/NO_MATCH',
  OPEN_YOUR_DATA: 'asset/OPEN_YOUR_DATA',
  SPINNER_RED: 'asset/SPINNER_RED',
  TENET3: 'asset/TENET3',
  TENET3_BLUE_AND_WHITE: 'asset/TENET3blue_and_white',
  TENET3_WHITE: 'asset/TENET3white',
  TRASH: 'asset/trash',
  ICONS: {
    SELECTION_ARROW: 'asset/icon/SELECTION_ARROW',
    HOME: 'asset/icon/HOME',
  },
  FA: {
    REGULAR: {
      ANGLE_DOWN: 'asset/ANGLE_DOWN',
      ANGLE_UP: 'asset/ANGLE_UP',
      CHECK: {
        MARK: 'asset/fa/check/REGULAR',
        CIRCLE: 'asset/fa/check/CIRCLE',
      },
      CHEVRON: {
        DOWN: 'asset/fa/chevron/DOWN',
        RIGHT: 'asset/fa/chevron/RIGHT',
        LEFT: 'asset/fa/chevron/LEFT',
        UP: 'asset/fa/chevron/UP',
      },
      COG: '/asset/fa/COG',
      ENVELOPE: 'asset/fa/ENVELOPE',
      EXCLAMATION: {
        CIRCLE: 'asset/fa/exclamation/CIRCLE',
        TRIANGLE: 'asset/fa/exclamation/TRIANGLE',
      },
      EXTERNAL_LINK: 'asset/fa/external/link/REGULAR',
      HOME: 'asset/fa/HOME',
      INFO: {
        CIRCLE: 'asset/fa/info/CIRCLE',
      },
      SIGN_IN: {
        ALT: 'asset/fa/SIGN_IN/ALT',
      },
      SIGN_OUT: {
        ALT: 'asset/fa/SIGN_OUT/ALT',
      },
      USER_PLUS: 'assets/fa/USER_PLUS',
    },
    LIGHT: {
      TIMES: 'asset/fa/times/LIGHT',
    },
    SOLID: {
      BARS: 'asset/fa/bars/SOLID',
      EXTERNAL_LINK: 'asset/fa/external/link/SOLID',
      GRIP: {
        HORIZONTAL: 'asset/fa/grid/horizontal/SOLID',
      },
      USER: 'asset/fa/user/SOLID',
      SEARCH: 'asset/fa/search/SOLID',
      SPINNER: 'asset/fa/spinner/SOLID',
      SORT: 'asset/fa/SORT',
      SORT_UP: 'asset/fa/SORT_UP',
      SORT_DOWN: 'asset/fa/SORT_DOWN',
    },
  },
  FILTER: 'asset/FILTER',
  FILTER_ON: 'asset/FILTER_ON',
  FILTER_OFF: 'asset/FILTER_OFF',
  SAVE_FILTER: 'asset/SAVE_FILTER',
  FOLDER_PLUS: 'asset/FOLDER_PLUS',
  CARDVIEW: 'asset/CARDVIEW',
  LISTVIEW: 'asset/LISTVIEW',
  OPACITY: 'asset/OPACITY',
  EDGES: {
    ARROWHEADS: {
      CIRCLE_PLUS_WITH_ARROW: 'assets/edges/arrowheads/CIRCLE_PLUS_WITH_ARROW',
      CURVED_ARROWHEAD_FILLED_DOT_WITH_ARROW:
        'assets/edges/arrowheads/CURVED_ARROWHEAD_FILLED_DOT_WITH_ARROW',
      UNFILLED_ARROW_WITH_ARROW:
        'assets/edges/arrowheads/UNFILLED_ARROW_WITH_ARROW',
      FILLED_DOT_WITH_ARROW: 'assets/edges/arrowheads/FILLED_DOT_WITH_ARROW',
      CURVED_ARROWHEAD_WITH_ARROW:
        'assets/edges/arrowheads/CURVED_ARROWHEAD_WITH_ARROW',
      FILLED_ARROW_WITH_ARROW:
        'assets/edges/arrowheads/FILLED_ARROW_WITH_ARROW',
      FILLED_DIAMOND_WITH_ARROW:
        'assets/edges/arrowheads/FILLED_DIAMOND_WITH_ARROW',
      UNFILLED_DIAMOND_WITH_ARROW:
        'assets/edges/arrowheads/UNFILLED_DIAMOND_WITH_ARROW',
    },
    STYLE: {
      ARROWHEADS: {
        NONE: 'assets/EDGES/STYLES/ARROWHEADS/NONE',
        CIRCLE_PLUS: 'assets/EDGES/STYLES/ARROWHEADS/CIRCLE_PLUS',
        CURVED: 'assets/EDGES/STYLES/ARROWHEADS/CURVED',
        CURVED_FILLED_DOT: 'assets/EDGES/STYLES/ARROWHEADS/CURVED_FILLED_DOT',
        FILLED: 'assets/EDGES/STYLES/ARROWHEADS/FILLED',
        UNFILLED: 'assets/EDGES/STYLES/ARROWHEADS/UNFILLED',
        FILLED_DOT: 'assets/EDGES/STYLES/ARROWHEADS/FILLED_DOT',
        FILLED_DIAMOND: 'assets/EDGES/STYLES/ARROWHEADS/FILLED_DIAMOND',
        UNFILLED_DIAMOND: 'assets/EDGES/STYLES/ARROWHEADS/UNFILLED_DIAMOND',
      },
      SOLID: 'assets/EDGES/STYLES/SOLID',
      SHORT_DASH: 'assets/EDGES/STYLES/SHORT_DASH',
      LONG_DASH: 'assets/EDGES/STYLES/LONG_DASH',
      DOT: 'assets/EDGES/STYLES/DOT',
      SQUARE_DOTTED: 'assets/EDGES/STYLES/SQUARE_DOTTED',
      SQUARE_LONG_DASH: 'assets/EDGES/STYLES/SQUARE_LONG_DASH',
    },
    SIZES: 'assets/edges/SIZES',
    STROKES: 'assets/edges/STROKES',
    SOLID: 'assets/edges/SOLID',
    DOTTED: 'assets/edges/DOTTED',
    SHORT_DASH: 'assets/edges/SHORT_DASH',
    LONG_DASH: 'assets/edges/LONG_DASH',
    SQUARE_DOTTED: 'assets/edges/SQUARE_DOTTED',
    SQUARE_LONG_DASH: 'assets/edges/SQUARE_LONG_DASH',
  },
  EDIT: 'assets/EDIT',
  EYE_OFF: 'assets/EYE_OFF',
  EYE_ON: 'assets/EYE_ON',
  SHAPES: 'asset/SHAPES',
  FILE_SCAN_IN_PROGRESS: 'asset/FILE_SCAN_IN_PROGRESS',
  FILE_TYPE: {
    CSV: 'asset/FILE_TYPE/CSV',
    DOCUMENT: 'asset/FILE_TYPE/DOCUMENT',
    EXCEL: 'asset/FILE_TYPE/EXCEL',
    HTML: 'asset/FILE_TYPE/HTML',
    IMAGE: 'asset/FILE_TYPE/IMAGE',
    JSON: 'asset/FILE_TYPE/JSON',
    MARKDOWN: 'asset/FILE_TYPE/MARKDOWN',
    METRA_NOTE: 'asset/FILE_TYPE/METRA_NOTE',
    METRA_MODEL: 'asset/FILE_TYPE/METRA_MODEL',
    PDF: 'asset/FILE_TYPE/PDF',
    POWERPOINT: 'asset/FILE_TYPE/POWERPOINT',
    SCRIPT: 'asset/FILE_TYPE/SCRIPT',
    UNKNOWN: 'asset/FILE_TYPE/UNKNOWN',
    WORD: 'asset/FILE_TYPE/WORD',
    ZIP: 'asset/FILE_TYPE/ZIP',
  },
  FOLDER_CHEVRONS: {
    OPEN: 'asset/FOLDER_CHEVRONS/OPEN',
    CLOSE: 'asset/FOLDER_CHEVRONS/CLOSE',
  },
  GRIP_ICON: 'asset/GRIP_ICON',
  HOME: 'asset/HOME',
  HOTKEY: 'asset/HOTKEY',
  ICON: {
    CHEVRON_UP: 'asset/icon/CHEVRON_UP',
    CHEVRON_DOWN: 'asset/icon/CHEVRON_DOWN',
    CIRCLE_ARROW_UP: 'asset/icon/CIRCLE_ARROW_UP',
    COLOR_ALERT: 'asset/icon/COLOR_ALERT',
    COPY: 'asset/icon/COPY',
    COORDINATES: 'asset/icon/COORDINATES',
    CURSOR: 'assets/icon/CURSOR',
    DOUBLE_CHEVRON_LEFT: 'asset/icon/DOUBLE_CHEVRON_LEFT',
    DOUBLE_CHEVRON_RIGHT: 'asset/icon/DOUBLE_CHEVRON_RIGHT',
    SINGLE_CHEVRON_LEFT: 'asset/icon/SINGLE_CHEVRON_LEFT',
    EQUAL: 'asset/icon/EQUAL',
    EXTERNAL_LINK: 'assets/icon/EXTERNAL_LINK',
    TAG: {
      EMPTY: 'asset/icon/tag/EMPTY',
      SOLID: 'asset/icon/tag/SOLID',
    },
    TOC: 'asset/icon/TOC',
    ICON_IMAGE: 'asset/icon/ICON_IMAGE',
    CIRCLE: 'asset/icon/CIRCLE',
    SQUARE: 'asset/icon/SQUARE',
    TRIANGLE: 'asset/icon/TRIANGLE',
    COLOR: 'asset/icon/COLOR',
    LOG_OUT: 'asset/icon/LOG_OUT',
    MINI_NODE: 'asset/icon/MINI_NODE',
    MINI_EDGE: 'asset/icon/MINI_EDGE',
  },
  INVALID: 'asset/INVALID',
  EXIT_LARGE: 'asset/EXIT_LARGE',
  MAGNIFYING_GLASS: 'asset/MAGNIFYING_GLASS',
  PERMISSIONS_KEY: 'asset/PERMISSIONS_KEY',
  PLUS: 'asset/PLUS',
  POINTER_LEFT: 'asset/POINTER_LEFT',
  POINTER_RIGHT: 'asset/POINTER_RIGHT',
  PROTECTED: 'asset/PROTECTED',
  PROTECTED_BACKGROUND: 'asset/PROTECTED_BACKGROUND',
  READ_ONLY: 'asset/READ_ONLY',
  SORT_UP: 'asset/SORT_UP',
  SORT_DOWN: 'asset/SORT_DOWN',
  STYLE_PANEL_ICON: 'asset/STYLE_PANEL_ICON',
  SQUARE_ARROW_RIGHT: 'asset/SQUARE_ARROW_RIGHT',
  TABLE: {
    HORIZONTAL: 'asset/TABLE/HORIZONTAL',
    VERTICAL: 'asset/TABLE/VERTICAL',
    RESIZE_CELLS: 'asset/TABLE/RESIZE_CELLS',
  },
  STYLE: {
    LABEL_POSITION: 'asset/style/LABEL_POSITION',
    LABEL_CONTENT: 'asset/style/LABEL_CONTENT',
  },
  TOOLBAR: {
    ICON: {
      NODE: 'asset/TOOLBAR/ICON/NODE',
      EDGE: 'asset/TOOLBAR/ICON/EDGE',
      IMAGE: 'asset/TOOLBAR/ICON/IMAGE',
      PAN: 'asset/TOOLBAR/ICON/PAN',
      PENCIL: 'asset/TOOLBAR/PENCIL',
      LINE: 'asset/TOOLBAR/LINE',
      BOX: 'asset/TOOLBAR/BOX',
      EDGE_DIRECTION: 'asset/TOOLBAR/ICON/EDGE_DIRECTION',
      EDGE_REASSIGN: 'asset/TOOLBAR/ICON/EDGE_REASSIGN',
      LOCK: 'asset/TOOLBAR/ICON/LOCK',
      TEXT: 'asset/TOOLBAR/ICON/TEXT',
      UNLOCK: 'asset/TOOLBAR/ICON/UNLOCK',
    },
    REDO: 'asset/TOOLBAR/REDO',
    UNDO: 'asset/TOOLBAR/UNDO',
  },
  UPLOAD: 'assets/UPLOAD',
};

export const assets = {
  [ASSETS.ICONS.HOME]: { svg: HomeIcon },
  [ASSETS.ICONS.SELECTION_ARROW]: { svg: SelectionArrowIcon },
  [ASSETS.ADMIN_USER_ICON]: { svg: AdminUserIconSVG },
  [ASSETS.ALERT_TRIANGLE]: { svg: AlertTriangleSVG },
  [ASSETS.ARCHIVE.ARCHIVE]: { svg: ArchiveSVG },
  [ASSETS.ARCHIVE.UNARCHIVE]: { svg: UnarchiveSVG },
  [ASSETS.BROKEN_LINK]: { svg: BrokenLinkSVG },
  [ASSETS.CAC_GREY]: { src: CACGrey },
  [ASSETS.CAC_WHITE]: { src: '/assets/CAC_Graphic_white.png' },
  [ASSETS.MAXIMIZE]: { svg: MaximizeSVG },
  [ASSETS.METRA]: { svg: MetraSVG },
  [ASSETS.NO_MATCH]: { src: NO_MATCH },
  [ASSETS.OPEN_YOUR_DATA]: { svg: OpenYourDataSVG },
  [ASSETS.CARDVIEW]: { svg: CardView },
  [ASSETS.CHANGE_EDGE_DIRECTION]: {
    src: ChangeDirection,
    svg: ChangeEdgeDirection,
  },
  [ASSETS.CHECKMARK]: { svg: CheckmarkSVG },
  [ASSETS.CIRCLE]: { src: Circle },
  [ASSETS.CLEAR_FILTER]: { svg: ClearFilterSVG },
  [ASSETS.COPY]: { svg: CopySVG },
  [ASSETS.DOWNLOAD]: { svg: DownloadSVG },
  [ASSETS.DRAG_TEXTURE]: { svg: DragTextureSVG },
  [ASSETS.FA.REGULAR.ANGLE_DOWN]: { icon: ['far', 'angle-down'] },
  [ASSETS.FA.REGULAR.ANGLE_UP]: { icon: ['far', 'angle-up'] },
  [ASSETS.FA.SOLID.SORT]: { icon: ['fas', 'sort'] },
  [ASSETS.FA.SOLID.SORT_UP]: { icon: ['fas', 'sort-up'] },
  [ASSETS.FA.SOLID.SORT_DOWN]: { icon: ['fas', 'sort-down'] },
  [ASSETS.FA.REGULAR.CHECK.MARK]: { icon: ['far', 'check'] },
  [ASSETS.FA.REGULAR.CHECK.CIRCLE]: { icon: ['far', 'check-circle'] },
  [ASSETS.FA.REGULAR.CHEVRON.DOWN]: { icon: ['far', 'chevron-down'] },
  [ASSETS.FA.REGULAR.CHEVRON.RIGHT]: { icon: ['far', 'chevron-right'] },
  [ASSETS.FA.REGULAR.CHEVRON.LEFT]: { icon: ['far', 'chevron-left'] },
  [ASSETS.FA.REGULAR.CHEVRON.UP]: { icon: ['far', 'chevron-up'] },
  [ASSETS.FA.REGULAR.COG]: { icon: ['far', 'cog'] },
  [ASSETS.FA.REGULAR.ENVELOPE]: { icon: ['far', 'envelope'] },
  [ASSETS.FA.REGULAR.EXCLAMATION.CIRCLE]: {
    icon: ['far', 'exclamation-circle'],
  },
  [ASSETS.FA.REGULAR.EXCLAMATION.TRIANGLE]: {
    icon: ['far', 'exclamation-triangle'],
  },
  [ASSETS.FA.REGULAR.EXTERNAL_LINK]: { icon: ['far', 'external-link'] },
  [ASSETS.FA.REGULAR.HOME]: { icon: ['far', 'home'] },
  [ASSETS.FA.REGULAR.INFO.CIRCLE]: { icon: ['far', 'info-circle'] },
  [ASSETS.FA.REGULAR.SIGN_IN.ALT]: { icon: ['far', 'sign-in-alt'] },
  [ASSETS.FA.REGULAR.USER_PLUS]: { icon: ['far', 'plus'] },
  [ASSETS.FA.LIGHT.TIMES]: { icon: ['fal', 'times'] },
  [ASSETS.FA.SOLID.BARS]: { icon: ['fas', 'bars'] },
  [ASSETS.FA.SOLID.GRIP.HORIZONTAL]: { icon: ['fas', 'grip-horizontal'] },
  [ASSETS.FA.SOLID.USER]: { icon: ['fas', 'user'] },
  [ASSETS.FA.SOLID.SEARCH]: { icon: ['fas', 'search'] },
  [ASSETS.FA.SOLID.SPINNER]: { icon: ['fas', 'spinner'] },
  [ASSETS.FILTER]: { svg: FilterSVG },
  [ASSETS.FILTER_ON]: { svg: FilterOnSVG },
  [ASSETS.FILTER_OFF]: { svg: FilterOffSVG },
  [ASSETS.FOLDER_PLUS]: { svg: FolderPlusSVG },
  [ASSETS.LISTVIEW]: { svg: ListView },
  [ASSETS.EDIT]: { svg: Edit },
  [ASSETS.EYE_OFF]: { svg: EyeOff },
  [ASSETS.EYE_ON]: { svg: EyeOn },
  [ASSETS.EDGES.ARROWHEADS.CIRCLE_PLUS_WITH_ARROW]: {
    src: ModelAssets.circlePlusArrowWithArrowhead,
  },
  [ASSETS.EDGES.ARROWHEADS.CURVED_ARROWHEAD_FILLED_DOT_WITH_ARROW]: {
    src: ModelAssets.curvedArrowheadFilledDotArrowWithArrowhead,
  },
  [ASSETS.EDGES.ARROWHEADS.UNFILLED_ARROW_WITH_ARROW]: {
    src: ModelAssets.unfilledArrowArrowWithArrowhead,
  },
  [ASSETS.EDGES.ARROWHEADS.FILLED_DOT_WITH_ARROW]: {
    src: ModelAssets.filledDotArrowWithArrowhead,
  },
  [ASSETS.EDGES.ARROWHEADS.CURVED_ARROWHEAD_WITH_ARROW]: {
    src: ModelAssets.curvedArrowheadArrowWithArrowhead,
  },
  [ASSETS.EDGES.ARROWHEADS.FILLED_ARROW_WITH_ARROW]: {
    src: ModelAssets.filledArrowArrowWithArrowhead,
  },
  [ASSETS.EDGES.ARROWHEADS.FILLED_DIAMOND_WITH_ARROW]: {
    src: ModelAssets.filledDiamondArrowWithArrowhead,
  },
  [ASSETS.EDGES.ARROWHEADS.UNFILLED_DIAMOND_WITH_ARROW]: {
    src: ModelAssets.unfilledDiamondArrowWithArrowhead,
  },
  [ASSETS.EDGES.STYLE.SOLID]: { svg: EdgeStyleSolid },
  [ASSETS.EDGES.STYLE.SHORT_DASH]: { svg: EdgeStyleShortDash },
  [ASSETS.EDGES.STYLE.LONG_DASH]: { svg: EdgeStyleLongDash },
  [ASSETS.EDGES.STYLE.DOT]: { svg: EdgeStyleDot },
  [ASSETS.EDGES.STYLE.SQUARE_DOTTED]: { svg: EdgeStyleSquareDotted },
  [ASSETS.EDGES.STYLE.SQUARE_LONG_DASH]: { svg: EdgeStyleSquareLongDash },
  [ASSETS.EDGES.STYLE.ARROWHEADS.NONE]: { svg: EdgeArrowHeadNone },
  [ASSETS.EDGES.STYLE.ARROWHEADS.CIRCLE_PLUS]: { svg: EdgeArrowHeadCirclePlus },
  [ASSETS.EDGES.STYLE.ARROWHEADS.CURVED]: { svg: EdgeArrowHeadCurved },
  [ASSETS.EDGES.STYLE.ARROWHEADS.CURVED_FILLED_DOT]: {
    svg: EdgeArrowHeadCurvedFilledDot,
  },
  [ASSETS.EDGES.STYLE.ARROWHEADS.FILLED]: { svg: EdgeArrowHeadFilled },
  [ASSETS.EDGES.STYLE.ARROWHEADS.UNFILLED]: { svg: EdgeArrowHeadUnfilled },
  [ASSETS.EDGES.STYLE.ARROWHEADS.FILLED_DOT]: { svg: EdgeArrowHeadFilledDot },
  [ASSETS.EDGES.STYLE.ARROWHEADS.FILLED_DIAMOND]: {
    svg: EdgeArrowHeadFilledDiamond,
  },
  [ASSETS.EDGES.STYLE.ARROWHEADS.UNFILLED_DIAMOND]: {
    svg: EdgeArrowHeadUnfilledDiamond,
  },

  [ASSETS.EDGES.SIZES]: { svg: EdgeSizesSVG },
  [ASSETS.EDGES.STROKES]: { svg: EdgeStylesSVG },
  [ASSETS.OPACITY]: { svg: OpacitySVG },
  [ASSETS.EDGES.SOLID]: { src: ModelAssets.solidLine },
  [ASSETS.EDGES.DOTTED]: { src: ModelAssets.dottedLine },
  [ASSETS.EDGES.SHORT_DASH]: { src: ModelAssets.shortDashLine },
  [ASSETS.EDGES.LONG_DASH]: { src: ModelAssets.longDashLine },
  [ASSETS.EDGES.SQUARE_DOTTED]: { src: ModelAssets.sqDashLine },
  [ASSETS.EDGES.SQUARE_LONG_DASH]: { src: ModelAssets.sqLongDashLine },
  [ASSETS.SHAPES]: { svg: ShapesSVG },
  [ASSETS.STYLE_PANEL_ICON]: { svg: StylePanelIconSVG },
  [ASSETS.FILE_SCAN_IN_PROGRESS]: { svg: FileScanInProgressSVG },
  [ASSETS.FOLDER_CHEVRONS.CLOSE]: { svg: FolderCloseChevron },
  [ASSETS.FOLDER_CHEVRONS.OPEN]: { svg: FolderOpenChevron },
  [ASSETS.FILE_TYPE.CSV]: { svg: FileTypeCsv },
  [ASSETS.FILE_TYPE.DOCUMENT]: { svg: FileTypeDocument },
  [ASSETS.FILE_TYPE.EXCEL]: { svg: FileTypeExcel },
  [ASSETS.FILE_TYPE.HTML]: { svg: FileTypeHtml },
  [ASSETS.FILE_TYPE.IMAGE]: { svg: FileTypeImage },
  [ASSETS.FILE_TYPE.JSON]: { svg: FileTypeJson },
  [ASSETS.FILE_TYPE.MARKDOWN]: { svg: FileTypeMarkdown },
  [ASSETS.FILE_TYPE.METRA_NOTE]: { svg: FileTypeMetraNote },
  [ASSETS.FILE_TYPE.METRA_MODEL]: { svg: FileTypeMetraModel },
  [ASSETS.FILE_TYPE.PDF]: { svg: FileTypePdf },
  [ASSETS.FILE_TYPE.POWERPOINT]: { svg: FileTypePowerpoint },
  [ASSETS.FILE_TYPE.SCRIPT]: { svg: FileTypeScript },
  [ASSETS.FILE_TYPE.UNKNOWN]: { svg: FileTypeUnknown },
  [ASSETS.FILE_TYPE.WORD]: { svg: FileTypeWord },
  [ASSETS.FILE_TYPE.ZIP]: { svg: FileTypeZip },
  [ASSETS.GRIP_ICON]: { svg: GripIconSVG },
  [ASSETS.HOTKEY]: { svg: HotKeyIconSVG },
  [ASSETS.HOME]: { svg: HomeSVG },
  [ASSETS.ICON.COPY]: { svg: IconCopy },
  [ASSETS.ICON.CIRCLE_ARROW_UP]: { svg: CircleArrowUp },
  [ASSETS.ICON.COLOR_ALERT]: { svg: ColorAlert },
  [ASSETS.ICON.COORDINATES]: { svg: IconCoordinates },
  [ASSETS.ICON.CURSOR]: { svg: CursorPointer },
  [ASSETS.ICON.CHEVRON_UP]: { svg: ChevronUp },
  [ASSETS.ICON.CHEVRON_DOWN]: { svg: ChevronDown },
  [ASSETS.ICON.DOUBLE_CHEVRON_LEFT]: { svg: DoubleChevronLeft },
  [ASSETS.ICON.DOUBLE_CHEVRON_RIGHT]: { svg: DoubleChevronRight },
  [ASSETS.ICON.SINGLE_CHEVRON_LEFT]: { svg: SingleChevronLeft },
  [ASSETS.ICON.EQUAL]: { svg: EqualSign },
  [ASSETS.ICON.ICON_IMAGE]: { svg: ImageIcon },
  [ASSETS.ICON.CIRCLE]: { svg: CircleSVG },
  [ASSETS.ICON.SQUARE]: { svg: SquareSVG },
  [ASSETS.ICON.TRIANGLE]: { svg: TriangleSVG },
  [ASSETS.ICON.COLOR]: { svg: ColorSVG },
  [ASSETS.ICON.LOG_OUT]: { svg: LogOutSVG },
  [ASSETS.ICON.MINI_NODE]: { svg: MiniNode },
  [ASSETS.ICON.MINI_EDGE]: { svg: MiniEdge },
  [ASSETS.ICON.TAG.EMPTY]: { svg: TagIconEmpty },
  [ASSETS.ICON.TAG.SOLID]: { svg: TagIconSolid },
  [ASSETS.ICON.TOC]: { svg: TOCsvg },
  [ASSETS.ICON.EXTERNAL_LINK]: { svg: ExternalLink },
  [ASSETS.INVALID]: { svg: InvalidSVG },
  [ASSETS.QUESTION_CIRCLE]: { svg: QuestionCircle },
  [ASSETS.PERMISSIONS_KEY]: { svg: PermissionsKey },
  [ASSETS.PLUS]: { svg: PlusSVG },
  [ASSETS.POINTER_LEFT]: { svg: PointerLeftSVG },
  [ASSETS.POINTER_RIGHT]: { svg: PointerRightSVG },
  [ASSETS.PROTECTED]: { svg: ProtectedSVG },
  [ASSETS.PROTECTED_BACKGROUND]: { svg: ProtectedBackgroundSVG },
  [ASSETS.READ_ONLY]: { svg: ReadOnlySVG },
  [ASSETS.SAVE_FILTER]: { svg: SaveFilterSVG },
  [ASSETS.SORT_UP]: { svg: SortUpSVG },
  [ASSETS.SORT_DOWN]: { svg: SortDownSVG },
  [ASSETS.MAGNIFYING_GLASS]: { svg: MagnifyingGlassSVG },
  [ASSETS.INFORMATION]: { svg: InformationSVG },
  [ASSETS.SPINNER_RED]: { svg: RedSpinner },
  [ASSETS.SQUARE_ARROW_RIGHT]: { svg: SquareArrowRightSVG },
  [ASSETS.TABLE.HORIZONTAL]: { svg: TableOrientationHorizontal },
  [ASSETS.TABLE.VERTICAL]: { svg: TableOrientationVertical },
  [ASSETS.TABLE.RESIZE_CELLS]: { svg: TableResizeCells },
  [ASSETS.TENET3]: { src: T3 },
  [ASSETS.TENET3_BLUE_AND_WHITE]: { src: T3_BLUE_AND_WHITE },
  [ASSETS.TENET3_WHITE]: { src: T3White },
  [ASSETS.STYLE.LABEL_POSITION]: { svg: labelPositionSVG },
  [ASSETS.STYLE.LABEL_CONTENT]: { svg: labelContentSVG },
  [ASSETS.TOOLBAR.ICON.PENCIL]: { svg: PencilSVG },
  [ASSETS.TOOLBAR.ICON.TEXT]: { svg: TextSVG },
  [ASSETS.TOOLBAR.ICON.LINE]: { svg: LineSVG },
  [ASSETS.TOOLBAR.ICON.BOX]: { svg: BoxSVG },
  [ASSETS.TOOLBAR.ICON.NODE]: { svg: NodeSVG },
  [ASSETS.TOOLBAR.ICON.EDGE]: { svg: edgeToolSVG },
  [ASSETS.TOOLBAR.ICON.IMAGE]: { svg: imageToolSVG },
  [ASSETS.TOOLBAR.ICON.PAN]: { svg: PanHand },
  [ASSETS.TOOLBAR.ICON.EDGE_DIRECTION]: { svg: EdgeDirection },
  [ASSETS.TOOLBAR.ICON.EDGE_REASSIGN]: { svg: EdgeReassign },
  [ASSETS.TOOLBAR.ICON.LOCK]: { svg: Lock },
  [ASSETS.TOOLBAR.ICON.UNLOCK]: { svg: Unlock },
  [ASSETS.TOOLBAR.REDO]: { svg: Redo },
  [ASSETS.TOOLBAR.UNDO]: { svg: Undo },
  [ASSETS.TRASH]: { svg: TrashSVG },
  [ASSETS.EXIT_LARGE]: { svg: ExitLarge },
  [ASSETS.UPLOAD]: { svg: UploadSVG },
};

export class AssetsManager {
  static _values = {};

  static initialize() {
    AssetsManager._values = {};
  }

  static destroy() {
    AssetsManager._values = {};
  }

  static remove(memoKey) {
    delete AssetsManager._values[memoKey];
  }

  static makeAsset(key) {
    let asset = assets[key];
    // display-names are mainly used for debugging and
    // are not essential to function or optimization.
    // eslint-disable-next-line react/display-name
    if (!asset) return () => <></>;
    let element = '',
      args = {};
    if (asset.icon) {
      element = FontAwesomeIcon;
      args = { icon: asset.icon };
    } else if (asset.svg) {
      element = asset.svg;
    } else {
      element = 'img';
      args = { src: asset.src };
    }
    // eslint-disable-next-line react/display-name
    return (props) => React.createElement(element, { ...props, ...args });
  }

  static getValue(key) {
    let value = AssetsManager._values[key];
    if (!value) {
      value = AssetsManager.makeAsset(key);
      AssetsManager._values[key] = value;
    }
    return value;
  }
}

/**
 * creates a memoized asset element.
 * safe for use in function and class components
 * @param {string} key - key of ASSET to use.
 * @returns {import('react').JSX.Element<any, any>} a memoized img or FA icon.
 */
export const useAsset = (key) => AssetsManager.getValue(key);
