import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { isApiAction, isSimpleAction } from 'modules/common';
import { Middleware } from 'redux';
import { MetraAction, ThunkAPI } from 'types';
import { METRA } from 'utils/constants';
import {
  SENTRY_DSN,
  RELEASE_VERSION,
  SENTRY_ENV,
  SENTRY_ENABLED,
} from 'utils/settings';

export const ACTION_BREADCRUMB_CATEGORY = 'redux.action';
export const ACTION_BREADCRUMB_TYPE = 'info';
export const MAPIC = 'METRA_API_TYPE_FUNCTION';

export function getBreadcrumbMessageType<P1, P2, E>(
  action: MetraAction<P1, P2, E>
) {
  if (action.type) {
    if (typeof action.type === 'function') {
      return MAPIC;
    }
    return action.type;
  } else if (typeof action === 'function') {
    return 'thunk';
  }
  return 'nonstandard action'; // (not a thunk or RSAA, missing a `type` property)'
}

/**
 * Given an action, returns a copy that has all potentially sensitive data removed from it.
 * @param action - the action to be scrubbed
 */
export function scrubActionData<P1, P2, E>(action: MetraAction<P1, P2, E>) {
  // Make a copy of the action that we can scrub/modify as needed for breadcrumbs
  let breadcrumbData = Object.clone(action);
  // Since so much of our data is so sensitive, entirely omit payloads
  if (isSimpleAction(breadcrumbData) || isApiAction(breadcrumbData)) {
    breadcrumbData.payload = undefined as P1 | P2;
  }

  //just to be helpful, since RSAAs don't have types and are pretty inscruitable
  if (isApiAction(action) && typeof action.type === 'function') {
    breadcrumbData.type = MAPIC;
    // breadcrumbData.method = action.[RSAA].method;
    // breadcrumbData.endpoint = action[RSAA].endpoint;
  }

  return breadcrumbData;
}

/**
 * Builds a sentry breadcrumb config object for redux actions, based on the standards outlined
 * in https://github.com/getsentry/sentry-javascript/blob/master/packages/react/src/redux.ts
 */
export const buildSentryReduxBreadcrumb = (message: string, data: any) => {
  return {
    message,
    data,
    category: ACTION_BREADCRUMB_CATEGORY,
    type: ACTION_BREADCRUMB_TYPE,
  };
};

/**
 * A redux middleware that adds a breadcrumb to sentry for every action
 */
export function createSentryLoggingMiddleware() {
  const middleware =
    (api: ThunkAPI): ReturnType<Middleware> =>
    (next) =>
    (action) => {
      let breadcrumbMessageType = getBreadcrumbMessageType(action);
      let breadcrumbData = scrubActionData(action);
      const breadcrumb = buildSentryReduxBreadcrumb(
        `Recieved ${breadcrumbMessageType}`,
        breadcrumbData
      );

      Sentry.addBreadcrumb(breadcrumb);

      // account for thunks
      if (typeof action === 'function') {
        const { dispatch, getState, extraArgs } = api;
        return action(dispatch, getState, extraArgs);
      }

      return next(action);
    };
  return middleware;
}

type ExtendedBy<A> = A extends infer B ? B : never;

function implementsTKeys<T>(obj: any, keys: (keyof T)[]): obj is T {
  if (!obj || !Array.isArray(keys)) {
    return false;
  }

  return keys.reduce((impl, key) => impl && key in obj, true);
}

export const initSentry = () => {
  if (SENTRY_ENABLED()) {
    Sentry.init({
      dsn: SENTRY_DSN(),
      release: RELEASE_VERSION,
      environment: SENTRY_ENV,
      integrations: (integration) => {
        const bt = new BrowserTracing();
        // we need to use this hack to properly type tracing because for some reason
        // sentry didn't see fit to actually EXPORT the type...
        if (
          implementsTKeys<(typeof integration)[number]>(bt, [
            'name',
            'setupOnce',
          ])
        ) {
          integration.push(bt);
        }

        return integration;
      },
      tracesSampler: () => {
        // Trace 20% for now.  This should be revised in the future after
        // we have some sort of baseline for how often things are traced
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/sampling/
        return 0.2;
      },
    });

    Sentry.configureScope((scope) => {
      scope.setTag('host', new URL(window.origin).host);
      scope.setTag('metra-version', METRA.VERSION);
    });
  }
};

export default initSentry;
