import { createAction } from '@reduxjs/toolkit';

export const createCategory = createAction<{ name: string; sheet: string }>(
  'model/schema/category/CREATE'
);
export const renameCategory = createAction<{ id: string; name: string }>(
  'model/schema/category/RENAME'
);
export const deleteCategory = createAction<{ id: string }>(
  'model/schema/category/DELETE'
);
export const addCategoryMembers = createAction<{
  id: string;
  members: string[];
}>('model/schema/category/member/ADD_MANY');

export const createProperty = createAction<{ name: string; category: string }>(
  'model/schema/property/CREATE'
);
export const renameProperty = createAction<{ id: string; name: string }>(
  'model/schema/property/RENAME'
);
export const deleteProperty = createAction<{ id: string }>(
  'model/schema/property/DELETE'
);
