import { isNone } from 'helpers/utils';
import { NOTES_EDITOR } from 'modules/common';
import {
  NotesEditorReducer,
  MetraSimpleAction,
  NotesEditorSheetState,
  ParsedHeaders,
} from 'types';

export const initialNotesEditorState: NotesEditorReducer = {
  activeNote: '-1',
  scrolledRightCount: 0,
  goToHeader: null,
  sheetState: {},
  showNotesOutlineFlyout: false,
  notesOutlineHeaders: [],
};

const getNoteMediaIdFromName = (
  noteName: string,
  state: NotesEditorReducer
) => {
  const spacelessNoteName = noteName.replaceAll(' ', '-');
  const matchingSheets = Object.keys(state.sheetState).filter(
    (mediaId) =>
      state.sheetState[mediaId].noteName.replaceAll(' ', '-') ===
      spacelessNoteName
  );
  if (matchingSheets.length > 0) {
    return matchingSheets[0];
  }
  return null;
};

export const notesEditorReducer = (
  state = initialNotesEditorState,
  action: MetraSimpleAction<{
    sheetState: Partial<NotesEditorSheetState>;
    goToHeader: string;
    newName: string;
    noteName: string;
    scrolledRightCount: number;
    notesOutlineHeaders: ParsedHeaders[];
    name: string;
    id: string;
  }>
): NotesEditorReducer => {
  const { type } = action;
  const payload = action.payload;
  switch (type) {
    case NOTES_EDITOR.CACHE.UPDATE: {
      return {
        ...state,
        sheetState: {
          ...state.sheetState,
          [payload.id]: {
            ...state.sheetState[payload.id],
            noteMediaId: payload.sheetState.noteMediaId,
            content: payload.sheetState.content,
          },
        },
      };
    }
    case NOTES_EDITOR.HEADER.CLEAR: {
      return {
        ...state,
        goToHeader: null,
      };
    }
    case NOTES_EDITOR.HEADER.GO_TO: {
      const noteMediaId = getNoteMediaIdFromName(payload.noteName, state);
      if (isNone(noteMediaId)) return state;
      const targetNote = Object.keys(state.sheetState).includes(noteMediaId)
        ? noteMediaId
        : state.activeNote;
      return {
        ...state,
        activeNote: targetNote,
        goToHeader: payload.goToHeader,
      };
    }
    case NOTES_EDITOR.INIT: {
      const selectionRange = state.sheetState[payload.id]?.selectionRange ?? {
        index: 0,
        length: 0,
      };
      return {
        ...state,
        sheetState: {
          ...state.sheetState,
          [payload.id]: {
            ...state.sheetState[payload.id],
            uploadingCount: 0,
            hasUploadFailed: false,
            content: null,
            noteMediaId: null,
            noteName: payload.noteName,
            selectionRange,
          },
        },
      };
    }
    case NOTES_EDITOR.SET_SHEET_STATE: {
      return {
        ...state,
        sheetState: {
          ...state.sheetState,
          [payload.id]: payload.sheetState as NotesEditorSheetState,
        },
      };
    }
    case NOTES_EDITOR.SHOW_NOTES_OUTLINE_FLYOUT: {
      return {
        ...state,
        showNotesOutlineFlyout: true,
        notesOutlineHeaders: action.payload.notesOutlineHeaders,
      };
    }
    case NOTES_EDITOR.HIDE_NOTES_OUTLINE_FLYOUT: {
      return {
        ...state,
        showNotesOutlineFlyout: false,
        notesOutlineHeaders: initialNotesEditorState.notesOutlineHeaders,
      };
    }
    case NOTES_EDITOR.UPLOAD.START: {
      const noteMediaId = payload.id;
      if (isNone(noteMediaId)) return state;
      return {
        ...state,
        sheetState: {
          ...state.sheetState,
          [noteMediaId]: {
            ...state.sheetState[noteMediaId],
            uploadingCount: state.sheetState[noteMediaId].uploadingCount + 1,
          },
        },
      };
    }
    case NOTES_EDITOR.UPLOAD.FINISH.FAILED: {
      const noteMediaId = payload.id;
      if (isNone(noteMediaId)) return state;
      return {
        ...state,
        sheetState: {
          ...state.sheetState,
          [noteMediaId]: {
            ...state.sheetState[noteMediaId],
            uploadingCount: Math.max(
              0,
              state.sheetState[noteMediaId].uploadingCount - 1
            ),
            hasUploadFailed: true,
          },
        },
      };
    }
    case NOTES_EDITOR.UPLOAD.FINISH.SUCCEEDED: {
      const noteMediaId = payload.id;
      if (isNone(noteMediaId)) return state;
      return {
        ...state,
        sheetState: {
          ...state.sheetState,
          [noteMediaId]: {
            ...state.sheetState[noteMediaId],
            uploadingCount: Math.max(
              0,
              state.sheetState[noteMediaId].uploadingCount - 1
            ),
            hasUploadFailed: false,
          },
        },
      };
    }
    case NOTES_EDITOR.SET_SELECTION_RANGE: {
      const selectionRange = payload.sheetState.selectionRange ?? {
        index: 0,
        length: 0,
      };
      return {
        ...state,
        sheetState: {
          ...state.sheetState,
          [payload.id]: {
            ...state.sheetState[payload.id],
            selectionRange,
          },
        },
      };
    }
    case NOTES_EDITOR.SET_ACTIVE_FILE: {
      return {
        ...state,
        activeNote: action.payload.id,
      };
    }
    case NOTES_EDITOR.SET_SCROLLED_RIGHT_COUNT: {
      return {
        ...state,
        scrolledRightCount: action.payload.scrolledRightCount,
      };
    }
    case NOTES_EDITOR.CLEAR_SHEET_STATE: {
      return {
        ...state,
        sheetState: {},
      };
    }
    case NOTES_EDITOR.REMOVE_SHEET: {
      const newSheetState = { ...state.sheetState };
      delete newSheetState[payload.id];
      return {
        ...state,
        sheetState: newSheetState,
      };
    }
    default:
      return state;
  }
};
