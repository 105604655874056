import { MetraAction, RootReducer } from 'types';
import { Reducer, ReducersMapObject, combineReducers } from 'redux';
import { initialAdminState, adminReducer } from 'modules/admin/reducer';
import { authReducer } from 'modules/auth/auth';
import { reducer as appReducer } from 'modules/app/reducer';
import { collectionReducer } from 'modules/entities/collections';
import { configReducer } from 'modules/config/config';
import { entityReducer } from './entities/entities';
import { initialLibraryState, libraryReducer } from 'modules/library/reducer';
import { initialRecentsState, recentsReducer } from 'modules/recents/reducer';
import { reducer as loaderReducer } from 'modules/loader/reducer';
import { initialMediaState, mediaReducer } from 'modules/media/media';
import { modelReducer } from 'modules/model/reducer';
import { projectReducer } from 'modules/project/reducer';
import { reducer as orgUsersReducer } from 'modules/orguser/orguser';
import { reducer as userReducer } from 'modules/user/user';
import { reducer as archiveReducer } from 'modules/archive/archive';
import { reducer as toastsReducer } from 'modules/ui/toasts';
import { reducer as sessionReducer } from 'modules/session/session';
import { guildReducer } from 'modules/guild/reducer';
import { initialSearchState, searchReducer } from 'modules/search/reducer';
import { reducer as windowReducer } from 'modules/ui/windowManager/reducer';
import { fileLinkReducer } from 'modules/fileLink/reducer';
import { modelLinksDataReducer } from 'modules/modelLinksData/reducer';
import { reducer as fileDataReducer } from 'modules/fileData/reducer';
import {
  AUTH_FAILURE_PKI,
  AUTH_FAILURE_CREDS,
  AUTH_FULL_RESET,
} from 'modules/common/constants';
import { initialState as initialLoaderState } from './loader/reducer';
import { initialState as initialAppState } from './app/reducer';
import { initialProjectState } from './project/reducer';
import { initialState as initialToastsState } from 'modules/ui/toasts';
import { initialState as initialAuthState } from './auth/auth';
import { initialEntityState } from './entities/entities';
import { initialCollectionState } from './entities/collections';
import { initialConfigState } from './config/config';
import { initialModelState } from './model/initial-state';
import { initialState as initialUserState } from './user/user';
import { initialState as initialArchiveState } from './archive/archive';
import { initialState as initialSessionState } from 'modules/session/session';
import {
  createGuildReducer,
  initialCreateGuildState,
} from './createguild/reducer';
import { initialState as initialGuildState } from './guild/reducer';
import { initialState as initialOrgUsersState } from './orguser/orguser';
import { initialState as initialWindowState } from './ui/windowManager/reducer';
import { initialFileLinkState } from './fileLink/reducer';
import { isApiAction } from './common';
import SeamlessImmutable from 'seamless-immutable';
import { initialModelLinksDataState } from './modelLinksData/reducer';
import { initialState as initialFileDataState } from './fileData/reducer';

export const initialState: RootReducer = {
  adminReducer: initialAdminState,
  createGuildReducer: initialCreateGuildState,
  loaderReducer: initialLoaderState,
  appReducer: initialAppState,
  projectReducer: initialProjectState,
  toastsReducer: initialToastsState,
  authReducer: initialAuthState,
  entityReducer: SeamlessImmutable(initialEntityState),
  modelReducer: initialModelState,
  modelLinksDataReducer: initialModelLinksDataState,
  mediaReducer: initialMediaState,
  archiveReducer: initialArchiveState,
  collectionReducer: initialCollectionState,
  userReducer: initialUserState,
  libraryReducer: initialLibraryState,
  recentsReducer: initialRecentsState,
  sessionReducer: initialSessionState,
  guildReducer: initialGuildState,
  orgUsersReducer: initialOrgUsersState,
  searchReducer: initialSearchState,
  configReducer: initialConfigState,
  windows: initialWindowState,
  fileLinkReducer: initialFileLinkState,
  fileDataReducer: initialFileDataState,
};

export const reducers: ReducersMapObject<RootReducer, any> = {
  adminReducer,
  loaderReducer,
  projectReducer,
  toastsReducer,
  authReducer,
  appReducer,
  entityReducer,
  modelReducer,
  modelLinksDataReducer,
  mediaReducer,
  orgUsersReducer,
  userReducer,
  archiveReducer,
  collectionReducer,
  libraryReducer,
  recentsReducer,
  sessionReducer,
  guildReducer,
  createGuildReducer,
  searchReducer,
  configReducer,
  windows: windowReducer,
  fileLinkReducer,
  fileDataReducer,
};

const topReducer = combineReducers<RootReducer, any>(reducers);

const FULL_CLEAR_ACTION_TYPES = [AUTH_FAILURE_PKI, AUTH_FAILURE_CREDS];

export const rootReducer: Reducer<RootReducer, MetraAction<any, any, any>> = (
  state,
  action
) => {
  let newState: RootReducer | undefined;
  if (!isApiAction(action) && FULL_CLEAR_ACTION_TYPES.includes(action.type)) {
    newState = {} as RootReducer;
  } else if (state && action.type === AUTH_FULL_RESET) {
    // reset everything but certain reducers
    newState = {
      ...initialState,
      authReducer: {
        ...state.authReducer,
      },
      userReducer: {
        ...state.userReducer,
      },
      sessionReducer: {
        ...state.sessionReducer,
      },
      appReducer: {
        ...state.appReducer,
      },
    };
  } else {
    newState = state;
  }

  return topReducer(newState, action);
};
