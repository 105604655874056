import { isNone, isSome } from 'helpers/utils';
import { NOTES_EDITOR } from 'modules/common';
import {
  EditorSelectionRange,
  MetraActionFunc,
  MetraThunk,
  NotesEditorSheetState,
} from 'types';
import { METRA } from 'utils/constants';

export const headerClearNotesEditor: MetraActionFunc = () => ({
  type: NOTES_EDITOR.HEADER.CLEAR,
});

export const setShowNotesOutlineFlyout: MetraActionFunc = (headers) => ({
  type: NOTES_EDITOR.SHOW_NOTES_OUTLINE_FLYOUT,
  payload: { notesOutlineHeaders: headers },
});

export const setHideNotesOutlineFlyout: MetraActionFunc = () => ({
  type: NOTES_EDITOR.HIDE_NOTES_OUTLINE_FLYOUT,
});

export const headerGoToNotesEditor: MetraActionFunc<
  [string, string],
  { noteName: string; goToHeader: string }
> = (noteName, goToHeader) => ({
  type: NOTES_EDITOR.HEADER.GO_TO,
  payload: { noteName, goToHeader },
});

export const initNotesEditor: MetraActionFunc = ({
  noteMediaId,
  media,
  noteName,
}) => {
  const noteMediaName = noteName
    ? noteName
    : media[noteMediaId].name.substring(
        0,
        media[noteMediaId].name.length - METRA.NOTE_EXTENSION.length
      );
  return {
    type: NOTES_EDITOR.INIT,
    payload: { id: noteMediaId, noteName: noteMediaName },
  };
};

export const setSheetState: MetraActionFunc<
  [Numberish, NotesEditorSheetState],
  { id: Numberish; sheetState: NotesEditorSheetState }
> = (noteId, sheetState) => ({
  type: NOTES_EDITOR.SET_SHEET_STATE,
  payload: { id: noteId, sheetState },
});

export const clearNotesEditor = () => ({
  type: NOTES_EDITOR.CLEAR_SHEET_STATE,
  payload: {},
});

export const removeNoteState = (noteId: Numberish) => ({
  type: NOTES_EDITOR.REMOVE_SHEET,
  payload: { id: noteId },
});

export const startUploadNotesEditor: MetraActionFunc = (noteId: Numberish) => ({
  type: NOTES_EDITOR.UPLOAD.START,
  payload: { id: noteId },
});

export const finishUploadFailedNotesEditor: MetraActionFunc = (
  noteId: Numberish
) => ({
  type: NOTES_EDITOR.UPLOAD.FINISH.FAILED,
  payload: { id: noteId },
});

export const finishUploadSucceededNotesEditor: MetraActionFunc = (
  noteId: Numberish
) => ({
  type: NOTES_EDITOR.UPLOAD.FINISH.SUCCEEDED,
  payload: { id: noteId },
});

export const updateCacheNotesEditor: MetraThunk =
  (noteName: string, noteMediaId: Numberish, content: string) =>
  (dispatch, getState) => {
    if (isSome(noteMediaId)) {
      const state = getState();
      const media = state.entityReducer.media[noteMediaId];
      // we do not want to cache a debounced note if the model editor
      // is no longer looking at that model
      if (
        isNone(media) ||
        Number(media.belongs_to) !== Number(state.modelReducer.base.id)
      ) {
        return;
      }
    }

    dispatch({
      type: NOTES_EDITOR.CACHE.UPDATE,
      payload: {
        id: noteMediaId,
        sheetState: { noteMediaId, content },
      },
    });
  };

export const setActiveNote: MetraActionFunc<[Numberish], { id: Numberish }> = (
  noteId
) => ({
  type: NOTES_EDITOR.SET_ACTIVE_FILE,
  payload: { id: noteId },
});

export const setScrolledRightCount: MetraActionFunc<
  [number],
  { scrolledRightCount: number }
> = (scrolledRightCount) => ({
  type: NOTES_EDITOR.SET_SCROLLED_RIGHT_COUNT,
  payload: { scrolledRightCount },
});

export const setNoteSelectionRange: MetraActionFunc<
  [Numberish, EditorSelectionRange],
  {
    id: Numberish;
    sheetState: { selectionRange: EditorSelectionRange };
  }
> = (noteId, selectionRange) => ({
  type: NOTES_EDITOR.SET_SELECTION_RANGE,
  payload: { id: noteId, sheetState: { selectionRange } },
});
