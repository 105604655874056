/* eslint-disable no-console */
import { clamp } from 'lodash';
import * as MathJS from 'mathjs';
import { SIZING } from 'utils/constants';
import { SHAPE } from 'utils/constants-extra';
import { Vector2 } from 'utils/vector';
import { DEVMODE, TESTMODE } from './constants';
import { isNumber } from 'helpers/utils';
import { t3dev } from 't3dev';

/**
 * This file contains the glue between MathJS and our Expressions logic
 * Custom MathJS functions are defined here, though we should pull them out
 * in the future. This is also where we configure MathJS and expose the
 * parts of it we need for use in other classes
 */

const options = {};

// configure mathjs to default to Arrays when there is ambiguity
options.matrix = 'Array';

// expose only the parts of mathjs we need
/** @type {math.MathJsStatic} */
export const mathjs = MathJS.create(MathJS.all, options);
export const parse = mathjs.parse;
export const evaluate = mathjs.evaluate;
export const typed = mathjs.typed;
export const mathImport = mathjs.import;

if (!DEVMODE || TESTMODE) {
  // These functions will be disabled to prevent potential security exploits
  const blacklist = [
    'compile',
    'import',
    'parse',
    'createUnit',
    'evaluate',
    'simplify',
    'derivative',
  ];

  const disabled = blacklist.reduce((toImport, func) => {
    toImport[func] = () => {
      throw new Error(`Function \`${func}()\` is disabled.`);
    };
    return toImport;
  }, {});
  mathImport(disabled, { override: true });
}
// Non-essential, but it lets us use `typeOf(exp)` in logic
// Interpreter will run fine without this
typed.addType({
  name: 'Expression',
  test: (x) => x?.isExpression,
});

mathImport({
  typeOf: typed('typeOf', {
    Expression: () => 'Expression',
  }),
});

export const validateStyle = (id, style, model) => {
  const validStyle = {};
  const shapeType = model.sets[id] ? SHAPE.SET : model.shapes[id].type;

  const propMap = {
    [SHAPE.NODE]: {
      color: 'color',
      location: 'pos',
      opacity: 'alpha',
      scale: 'scale',
      shape: 'asset',
    },
    [SHAPE.EDGE]: {
      color: 'color',
      arrowhead: 'arrowhead',
      opacity: 'alpha',
      size: 'width',
      stroke: 'asset',
    },
    [SHAPE.SET]: { color: 'color', opacity: 'alpha' },
  }[shapeType];

  Object.keys(style).forEach((prop) => {
    const value = style[prop];

    const validProp = propMap[prop];
    if (!validProp) {
      throw new Error(`invalid property for updateStyle: ${prop}`);
    }

    if (prop === 'shape') {
      const shapeMap = {
        circle: 'default',
        triangle: 'triangle',
        square: 'square',
      };
      const validShape = shapeMap[value];
      if (!validShape) {
        throw new Error(`invalid shape type for updateStyle: ${value}`);
      }

      validStyle[validProp] = validShape;
    }

    if (prop === 'stroke') {
      const strokeMap = {
        solid: 'solidLine',
        dotted: 'sqDashLine',
        'short-dash': 'shortDashLine',
        'long-dash': 'longDashLine',
        'dot-dash': 'sqLongDashLine',
      };

      const validStroke = strokeMap[value];
      if (!validStroke) {
        throw new Error(`invalid stroke type for updateStyle: ${value}`);
      }

      validStyle[validProp] = validStroke;
    }

    if (prop === 'arrowhead') {
      const arrowheadMap = {
        curved: 'curvedArrowhead',
        filled: 'filledArrow',
        unfilled: 'unfilledArrow',
        dot: 'filledDot',
        'dot-arrow': 'curvedArrowheadFilledDot',
        'dot-plus': 'circlePlus',
        diamond: 'filledDiamond',
        'unfilled-diamond': 'unfilledDiamond',
      };
      const validArrowhead = arrowheadMap[value];
      if (!validArrowhead) {
        throw new Error(`invalid arrowhead type for updateStyle: ${value}`);
      }

      validStyle[validProp] = validArrowhead;
    }

    if (prop === 'color') {
      if (!`${value}`.match(/^#[0-9a-fA-F]{6}$/)) {
        throw new Error(`invalid color hash for updateStyle: ${value}`);
      }

      validStyle[validProp] = value;
    }

    if (prop === 'location') {
      if (!isNumber(value.x)) {
        throw new Error(`invalid x location value for updateStyle: ${value.x}`);
      }
      if (!isNumber(value.y)) {
        throw new Error(`invalid y location value for updateStyle: ${value.y}`);
      }

      const x = clamp(Number(value.x), -Number.MAX_VALUE, Number.MAX_VALUE);
      const y = clamp(Number(value.y), -Number.MAX_VALUE, Number.MAX_VALUE);

      validStyle[validProp] = new Vector2(x, y);
    }

    if (prop === 'opacity') {
      if (!isNumber(value)) {
        throw new Error(`invalid opacity value for updateStyle: ${value}`);
      }

      const validOpacity = Math.min(1, Math.max(0, Number(value)));
      validStyle[validProp] = validOpacity;
    }

    if (prop === 'scale') {
      if (!isNumber(value)) {
        throw new Error(`invalid scale value for updateStyle: ${value}`);
      }

      const validScale = clamp(
        Number(value),
        SIZING.NODE.MIN_SCALE,
        SIZING.NODE.MAX_SCALE
      );
      validStyle[validProp] = new Vector2(validScale, validScale);
    }

    if (prop === 'size') {
      if (!isNumber(value)) {
        throw new Error(`invalid size value for updateStyle ${value}`);
      }

      const validSize = clamp(
        Math.round(value),
        SIZING.EDGE.WIDTH.MIN,
        SIZING.EDGE.WIDTH.MAX
      );
      validStyle[validProp] = validSize;
    }
  });

  return validStyle;
};

/**
 **/
const memberOf = (args, _math, scope, _wrapped, fnname = 'memberOf') => {
  if (!scope.get('__isPathDiscovery')) {
    throw new Error(
      `${fnname}() can only be called from a Path Discovery filter`
    );
  }

  const [setNameNode, entityNode] = args;

  if (!setNameNode) {
    throw new Error(
      `${fnname}() must take the name of a set as its first argument`
    );
  }

  const state = setNameNode.state;

  const setName = setNameNode.evaluate(scope);
  const entity = entityNode?.evaluate(scope);

  const set = Object.values(state.sets).find((set) => set.label === setName);

  if (!set) {
    throw new Error(
      `${fnname}() could not find a set with name matching: "${setName}"`
    );
  }

  if (entity) {
    return (
      set.edgeIds.includes(entity.__uuid) || set.nodeIds.includes(entity.__uuid)
    );
  }

  if (set.nodeIds.includes(scope.get('source').__uuid)) return true;
  if (set.nodeIds.includes(scope.get('target').__uuid)) return true;
  if (set.edgeIds.includes(scope.get('edge').__uuid)) return true;

  return false;
};
memberOf.rawArgs = true;
mathImport({ memberOf });

const exclude = (args, math, scope, wrapped) =>
  !memberOf(args, math, scope, wrapped, 'exclude');
exclude.rawArgs = true;
mathImport({ exclude });

// const parseExp = (exp) => {
//   let node;

//   // prevent blanks from becoming undefined.
//   // sum(undefined) => error
//   // sum('') => 0
//   if (exp.evaluated === '') {
//     node = parse("''");
//   } else if (exp.isPlain) {
//     // treat plaintext as numeric when possible
//     if (isNumeric(exp.evaluated)) {
//       node = new mathjs.ConstantNode(Number(exp.evaluated));
//     } else {
//       // all other plain text will be a string
//       node = new mathjs.ConstantNode(exp.evaluated);
//     }
//   } else {
//     // if this isn't plain text, parse it to get its value
//     node = parse(serialize(exp.evaluated));
//   }

//   node.exp = exp;
//   return node;
// };

// const isNumeric = (value) => {
//   return value != null && value !== '' && !Number.isNaN(Number(value));
// };

/**
 * zip() combines two arrays into an object by mapping items at each index as
 * key-value pairs. The list of keys is authoritative and extra values will be
 * ignored.
 * @param {Array} keys - the array of items to be used as keys
 * @param {Array} values - the array of items to be used as values
 * @return {Object} result - an object with array items mapped as key-pair values
 */
mathImport({
  zip: typed('zip', {
    'Array, Array': (keys, values) => {
      const zipped = {};
      keys.forEach((key, i) => (zipped[key] = values[i]));
      return zipped;
    },
  }),
});

/**
 * includes(array, item) checks whether an array contains the given item
 * @param {Array} array - the array to check
 * @param item - the item which may or may not be in the array
 * @reutrn {boolean} - whether the given item is in the array
 */
mathImport({
  includes: typed('includes', {
    'Array, any': (arr, item) => {
      return arr.some((i) => i == item);
    },
  }),
});

/**
 * reduce(array, initial, callback) reduce array function
 * can be used to actually reduce an array (EG reduce(values, 0, sum))
 * or to perform arbitrary for loops with some given context object
 */
mathImport({
  reduce: typed('reduce', {
    'Matrix | Array, any, function': (arr, context, callback) => {
      for (const item of arr) {
        context = callback(context, item);
      }

      return context;
    },
  }),
});

// make equals function (==) in mathjs work for string comparison
const oldEqual = mathjs.equal;
mathImport(
  {
    equal: (a, b) => {
      if (typeOf(a) === 'string' && typeOf(b) === 'string') {
        return mathjs.equalText(a, b);
      }
      return oldEqual(a, b);
    },
  },
  { override: true }
);

export const toYaml = (value, { child = false, indent = 0 } = {}) => {
  if (typeOf(value) === 'Array') {
    if (typeOf(value[0]) === 'number') {
      return toYamlArray(value, { child, inline: true });
    }

    return toYamlArray(value, { child, indent });
  }

  if (typeOf(value) === 'Object') {
    return toYamlObject(value, { child, indent });
  }

  return toYamlValue(value, { child });
};

export const toYamlValue = (value, { child = false } = {}) => {
  let start = child ? ' ' : '';
  let text = '';
  if (value === undefined) text = '~';
  else if (value === null) text = 'null';
  else text = value.toString();
  return `${start}${text}`;
};

const toYamlObject = (obj, { indent = 0, child = false } = {}) => {
  let start = child ? '\n' : '';
  const pad = ''.padStart(indent);
  const items = Object.entries(obj)
    .map(([key, value]) => {
      return `${pad}${key}:${toYaml(value, {
        child: true,
        indent: indent + 2,
      })}`;
    })
    .join('\n');
  return `${start}${items}`;
};

const toYamlArray = (
  arr,
  { indent = 0, child = false, inline = false } = {}
) => {
  let start = '';
  if (child && inline) start = ' ';
  if (child && !inline) start = '\n';

  const pad = inline ? '' : ''.padStart(indent);
  const delim = inline ? ', ' : '\n';
  const prefix = inline ? '' : '-';
  const items = arr
    .map(
      (item) =>
        `${pad}${prefix}${toYaml(item, {
          indent: indent + 2,
          child: !inline,
        })}`
    )
    .join(delim);

  if (inline) return `${start}[${items}]`;
  return `${start}${items}`;
};

// make unequals function (!=) in mathjs work for string comparison
const oldUnequal = mathjs.unequal;
mathImport(
  {
    unequal: (a, b) => {
      if (typeOf(a) === 'string' && typeOf(b) === 'string') {
        return !mathjs.equalText(a, b);
      }
      return oldUnequal(a, b);
    },
  },
  { override: true }
);

export const typeOf = mathjs.typeOf;
