import type {
  ActionsConstructor,
  MetraThunkDispatch,
  RootReducer,
} from 'types';
import type { EcsInstance } from 'ecs/EcsInstance';
import type { ModelEngine } from 'engine/engine';
import type { ModalManager } from 'components/ui/modalManager';
import type { EventManager } from 'metra-events';
import type { KeyboardManager } from 'utils/keyboard';
import type { Vector2 } from 'utils/vector';
import type { OrgContext } from 'utils/OrganizationContext';
import type { Interpreter } from 'interpreter/interpreter';
import type { DependencyCache } from 'interpreter/dependencyCache';
import { FeatureFlags } from 'utils/features';
import Cookies from 'js-cookie';

export interface T3DevLogger {
  log: (...args: any[]) => void;
  info: (...args: any[]) => void;
  warn: (...args: any[]) => void;
  error: (...args: any[]) => void;
  trace: (...args: any[]) => void;
  table: (...args: any[]) => void;
}

/**
 * these are the only allowable keys for t3dev
 * they are validated by the type system and at runtime
 */
const T3DEV_OPTIONAL_KEYS = [
  'actions',
  'depCache',
  'ecs',
  'engine',
  'em',
  'getFetchedState',
  'getLockState',
  'getUUIDState',
  'itpr',
  'keyboard',
  'ModalManager',
  'org',
  'pixi',
  'store',
  'vec',
] as const;

type T3DevKeys = IntoUnion<typeof T3DEV_OPTIONAL_KEYS>;

type T3Dev = {
  enabled: boolean;
  testing: boolean;
  log: T3DevLogger;
  decorate: (
    obj: Record<string, any>,
    props: string[],
    printGet: boolean
  ) => void;
  logoutIn?: (ms: number) => void;
  unpack?: () => void;
  environment: string;
  actions?: ActionsConstructor;
  em?: typeof EventManager;
  keyboard?: typeof KeyboardManager;
  ecs?: EcsInstance;
  engine?: ModelEngine;
  itpr?: Interpreter<any, any>;
  depCache?: DependencyCache<any, any>;
  ModalManager?: ModalManager;
  pixi?: any; //typeof PIXI;
  org?: OrgContext;
  store?: {
    dispatch: MetraThunkDispatch<any, any, any>;
    getState: () => RootReducer;
  };
  vec?: typeof Vector2;
  setValue(name: T3DevKeys, value: any): void;
  modeLogging: boolean;
  suppressNativeAlerts: boolean;
  [name: string]: any;
};

declare global {
  interface Window {
    t3dev: T3Dev;
    engine?: ModelEngine;
    ecs?: EcsInstance;
  }
}

const dummyLog = (..._args: any[]): void => {};

const dummyLogger: T3DevLogger = {
  log: dummyLog,
  info: dummyLog,
  warn: dummyLog,
  error: dummyLog,
  trace: dummyLog,
  table: dummyLog,
};

const decorate = (
  obj: Record<string, any>,
  props: string[],
  printGet = true
): void => {
  for (let prop of props) {
    obj[`_dc_${prop}`] = obj[prop];
    Object.defineProperty(obj, prop, {
      get: function () {
        printGet && console.trace('get', prop, obj[`_dc_${prop}`]);
        return obj[`_dc_${prop}`];
      },

      set: function (val) {
        console.trace('set', prop, val);
        obj[`_dc_${prop}`] = val;
      },
    });
  }
  obj._dc = true;
};

const logoutIn = (ms = 15000) => {
  Cookies.set('session_idle_time', `${(Date.now() + ms) / 1000.0}`);
};

const defaultConsole = global.console;

let _t3dev: Option<T3Dev> = null;
function t3devInstance(): T3Dev {
  if (_t3dev != null) return _t3dev;

  _t3dev = {
    enabled: true,
    decorate: decorate,

    // this is used by selenium tests
    // which cannot interact with the native browser unsaved changes prompt
    suppressNativeAlerts: false,

    logoutIn,
    testing: FeatureFlags.TESTING,
    log: defaultConsole,
    environment: process.env.REACT_APP_SENTRY_ENV || 'unknown',
    // pixi: PIXI,
    setValue(name: T3DevKeys, value: any) {
      if (T3DEV_OPTIONAL_KEYS.includes(name) && !!_t3dev) {
        _t3dev[name] = value;
      }
    },

    modeLogging: FeatureFlags.MODE_MANAGER_LOGGING,
  } satisfies T3Dev;

  _t3dev.unpack = () => {
    const typelessWindow = window as any;
    typelessWindow.sys2 = _t3dev?.engine?.sys2;
    for (const type in _t3dev?.engine?.TYPES) {
      typelessWindow[type] = _t3dev?.engine?.TYPES?.[type];
    }
  };

  return _t3dev;
}

/**
 * If ENABLE_DEVELOPER_TOOLS is on, this will return the global t3dev object,
 * which is exposed via window.
 * Otherwise, it will return a dummy object with enabled: false, which will
 * not be exposed to the window
 **/
export function t3dev(): T3Dev {
  return FeatureFlags.ENABLE_DEVELOPER_TOOLS
    ? t3devInstance()
    : ({
        enabled: false,
        testing: FeatureFlags.TESTING,
        suppressNativeAlerts: false,
        log: dummyLogger,
        environment: process.env.REACT_APP_ENVIRONMENT,
        setValue(_name: string, _value: any) {},
      } as T3Dev);
}

window.t3dev ||
  Object.defineProperty(window, 't3dev', {
    get() {
      return t3dev();
    },
  });

window.engine ||
  Object.defineProperty(window, 'engine', {
    get() {
      return t3dev().engine;
    },
  });

window.ecs ||
  Object.defineProperty(window, 'ecs', {
    get() {
      return t3dev().ecs;
    },
  });
