import type { RootReducer } from 'types';
import type { SheetsReducerState } from './reducer';

export const pruneSheets = (state: SheetsReducerState) => {
  if (!state) return;
  // return the sheets state only if there is at least one sheet
  // otherwise save space in the JSON by returning nothing
  if (state.ids.length > 0) {
    return state;
  }

  return;
};

export function getSheetRows(sheet: string) {
  return (state: RootReducer) => {
    return state.modelReducer.sheets.byId[sheet].rows;
  };
}

export function getSheetColumns(sheet: string) {
  return (state: RootReducer) => {
    return state.modelReducer.sheets.byId[sheet].columns;
  };
}

export function getSheetEntries(sheet: string) {
  return (state: RootReducer) => {
    return state.modelReducer.sheets.byId[sheet].entries;
  };
}

export function getFirstRowEntries(sheetId: string) {
  return (state: RootReducer) => {
    const sheet = state.modelReducer.sheets.byId[sheetId];
    return sheet.entries[sheet.rows[0]];
  };
}

export function getSheetValues(sheet: string) {
  return (state: RootReducer) => {
    return state.modelReducer.sheets.byId[sheet].values;
  };
}

export function getSheetRowsLength(sheet: string) {
  return (state: RootReducer) => {
    return state.modelReducer.sheets.byId[sheet].rows.length;
  };
}

export function getSheetColumnsLength(sheet: string) {
  return (state: RootReducer) => {
    return state.modelReducer.sheets.byId[sheet].columns.length;
  };
}
