import { Container, DisplayObject, FederatedPointerEvent } from 'pixi.js';
import cloneDeep from 'clone-deep';
import {
  EventPackage,
  EventQueueHandlerContext,
  ReplayBuffers,
  EventArgs,
  EventString,
  EventUnsubFunc,
  PixiEventTypes,
} from 'types';
import { EVENT, KEY } from 'utils/constants';
import { isNone, isSome } from 'helpers/utils';
import { EventManager } from './manager';
import { t3dev } from 't3dev';
import { buildOsModifier } from 'utils/utils-extra';

/**
 * EventQueue constructor arguments
 */
export interface EventQueueArgs {
  stage: Container;
}

export type KBEventListener = {
  listener: EventListener;
  baseElement: HTMLElement;
};

export declare type MetraPixiEventListener = (
  evt: FederatedPointerEvent
) => void;

export type RegisteredPixiEmitters<E extends DisplayObject = any> = Record<
  PixiEventTypes,
  Record<UUID, [E, MetraPixiEventListener]>
>;

export class EventQueue {
  private _buffers: ReplayBuffers<any[]>;
  private _locked: boolean;
  private _registeredPixiEmitters: RegisteredPixiEmitters<any>;
  private _registeredKeyboardListeners: Record<
    EventString,
    Record<UUID, KBEventListener>
  >;
  private _registeredElementListeners: Record<
    EventString,
    Record<UUID, { boundListener: EventListener; element: HTMLElement }>
  >;
  private _registeredManagerUnsubs: Record<
    EventString,
    Record<UUID, EventUnsubFunc>
  >;
  // private _clearBuffer: (owner: UUID) => void;

  ignoreStageEvents = false;

  // only used by engineRig to fix some buggy legacy testing logic
  __neverIgnoreStageEvents = false;

  props: EventQueueArgs;

  /**
   * create an EventQueue
   * @param props - `EventQueueArgs`
   */
  constructor(props: EventQueueArgs) {
    this.props = props;
    this._buffers = {};
    this._locked = false;
    this._registeredPixiEmitters = {} as RegisteredPixiEmitters<any>;
    this._registeredKeyboardListeners = {};
    this._registeredElementListeners = {};
    this._registeredManagerUnsubs = {};
  }

  get buffers(): ReplayBuffers<any[]> {
    return this._buffers;
  }

  /**
   * destroy the `EventQueue`
   */
  destroy(): void {
    Object.values(this._registeredPixiEmitters).forEach((eventTypeEmitters) => {
      Object.entries(eventTypeEmitters).forEach(
        ([eventString, [emitter, listener]]) => {
          emitter.removeListener(eventString, listener);
        }
      );
    });
    Object.values(this._registeredKeyboardListeners).forEach(
      (eventTypeListeners) =>
        Object.entries(eventTypeListeners).forEach(
          ([eventString, kblistener]) =>
            kblistener.baseElement.removeEventListener(
              eventString,
              kblistener.listener
            )
        )
    );
    Object.values(this._registeredElementListeners).forEach(
      (eventTypeListeners) =>
        Object.entries(eventTypeListeners).forEach(
          ([eventString, { boundListener, element }]) =>
            element.removeEventListener(eventString, boundListener)
        )
    );
    Object.values(this._registeredManagerUnsubs).forEach((eventTypeUnsubs) => {
      Object.values(eventTypeUnsubs).forEach((unsub) => {
        unsub();
      });
    });
  }

  lock(): void {
    this._locked = true;
  }

  unlock(): void {
    this._locked = false;
  }

  get isLocked(): boolean {
    return this._locked;
  }

  /**
   * clear the owner's buffer
   * @param owner - the owner
   */
  clearBuffer(owner: string): void {
    this._buffers[owner] = [];
  }

  /**
   * clear all buffers except the listed owner's
   * @param holdOwner - the buffer to keep
   */
  inverseClearBuffer(holdOwner: string): void {
    Object.forEach(this._buffers, ([owner, buffer]) => {
      if (owner !== holdOwner && isSome(buffer)) this._buffers[owner] = [];
    });
  }

  /**
   * clear all the buffers holding off clearing any of the provided
   * @param holdBuffers - the buffers NOT to clear
   */
  clearAllBuffers(holdBuffers: Record<string, boolean> = {}): void {
    Object.forEach(this._buffers, ([owner, buffer]) => {
      if (isNone(holdBuffers[owner] && isSome(buffer))) {
        this._buffers[owner] = [];
      }
    });
  }

  clearEvent(owner: string, eventType: EventString): void {
    if (isNone(this._buffers[owner])) return;
    this._buffers[owner] = this._buffers[owner].filter(
      (e) => e.baseEventType !== eventType
    );
  }

  /**
   * Clear all events except the listed one, for the specified owner
   * @param owner
   * @param eventType
   */
  inverseClearEvent(owner: string, eventType: EventString): void {
    if (isNone(this._buffers[owner])) return;
    this._buffers[owner] = this._buffers[owner].filter(
      (e) => e.baseEventType === eventType
    );
  }

  /**
   * Clear all events except the listed type, regardless of owner
   * @param eventType
   */
  inverseClearEventType(eventType: EventString): void {
    Object.forEach(this._buffers, ([owner, buffer]) => {
      this._buffers[owner] = buffer.filter(
        (e) => e.baseEventType === eventType
      );
    });
  }

  /**
   * creates a pixi event, merging it into the buffer
   * @param event the `EventData` of the event
   * @param owner the owner string
   * @param baseEventType the event type at registration
   * @param eventTypeAtReceipt the event type at event receipt
   */
  createPixiEqEvent(
    event: FederatedPointerEvent,
    owner: UUID,
    baseEventType: EventString,
    eventTypeAtReceipt: EventString,
    related?: string
  ): void {
    this.mergeWithPrevious({
      event,
      owner,
      baseEventType,
      eventTypeAtReceipt,
      related,
      _isPixiEvent: true,
      timestamp: performance.now(),
    });
  }

  /**
   * creates a pixi event, merging it into the buffer
   * @param managerArgs the `EventArgs` of the event
   * @param owner the owner string
   * @param baseEventType the event type at registration
   */
  createManagerEqEvent<T extends any[]>(
    managerArgs: EventArgs<T>,
    owner: UUID,
    baseEventType: EventString
  ): void {
    this.mergeWithPrevious({
      managerArgs,
      owner,
      baseEventType,
      eventTypeAtReceipt: baseEventType,
      timestamp: performance.now(),
    });
  }

  /**
   * creates a pixi event, merging it into the buffer
   * @param keyboard the `KeyboardEvent` data of the event
   * @param owner the owner string
   * @param baseEventType the event type at registration
   * @param eventTypeAtReceipt the event type at event receipt
   */
  createKeyboardEqEvent(
    keyboard: KeyboardEvent,
    owner: UUID,
    baseEventType: EventString,
    eventTypeAtReceipt: EventString
  ): void {
    this.mergeWithPreviousKeyboard({
      keyboard,
      owner,
      baseEventType,
      eventTypeAtReceipt,
      timestamp: performance.now(),
    });
  }

  createWindowEqEvent(
    element: Event,
    owner: UUID,
    baseEventType: EventString,
    eventTypeAtReceipt: EventString
  ): void {
    this.mergeWithPrevious({
      element,
      owner,
      baseEventType,
      eventTypeAtReceipt,
      timestamp: performance.now(),
    });
  }

  getEventsByOwner(owner: UUID): EventPackage<any[]>[] {
    return this._buffers[owner];
  }

  /**
   * merges a keyboard event into the current buffers
   * @param newPackage the incoming `EventPackage` to be merged
   */
  mergeWithPreviousKeyboard(newPackage: EventPackage<any[]>): void {
    const owner = newPackage.owner;
    if (!this._buffers[owner]) {
      this._buffers[owner] = [];
    }

    // IF we see certain modifier keys,
    // THEN we want to capture them and insert certain data
    let metaDown = false,
      ctrlDown = false,
      altDown = false,
      shiftDown = false;
    if (isSome(newPackage.keyboard)) {
      altDown = !!newPackage.keyboard.altKey;
      metaDown = !!newPackage.keyboard.metaKey;
      ctrlDown = !!newPackage.keyboard.ctrlKey;
      shiftDown = !!newPackage.keyboard.shiftKey;
    }

    if (ctrlDown) {
      this._buffers[owner].push({
        ...newPackage,
        baseEventType: `${newPackage.baseEventType}+`,
        keyboard: {
          key: KEY.CTRL,
          type: newPackage?.keyboard?.type ?? '',
        } as KeyboardEvent,
      });
    }

    if (metaDown) {
      this._buffers[owner].push({
        ...newPackage,
        baseEventType: `${newPackage.baseEventType}+`,
        keyboard: {
          key: KEY.META,
          type: newPackage?.keyboard?.type ?? '',
        } as KeyboardEvent,
      });
    }

    if (altDown) {
      this._buffers[owner].push({
        ...newPackage,
        baseEventType: `${newPackage.baseEventType}+`,
        keyboard: {
          key: KEY.ALT,
          type: newPackage?.keyboard?.type ?? '',
        } as KeyboardEvent,
      });
    }

    if (shiftDown) {
      this._buffers[owner].push({
        ...newPackage,
        baseEventType: `${newPackage.baseEventType}+`,
        keyboard: {
          key: KEY.SHIFT,
          type: newPackage?.keyboard?.type ?? '',
        } as KeyboardEvent,
      });
    }

    // we only want to splice out an older event
    // IF it exists
    // AND has a matching keycode
    // OTHERWISE just push it onto the end
    if (
      isNone(
        this._buffers[owner].findReplace(
          newPackage,
          (epkg) =>
            epkg.baseEventType === newPackage.baseEventType &&
            epkg?.keyboard?.key === newPackage?.keyboard?.key
        )
      )
    ) {
      this._buffers[owner].push(newPackage);
    }
  }

  mergeWithPrevious(newPackage: EventPackage<any[]>): void {
    const owner = newPackage.owner;
    if (!this._buffers[owner]) {
      this._buffers[owner] = [];
    }
    if (
      isNone(
        this._buffers[owner].findReplace(
          newPackage,
          (epkg) => epkg.baseEventType === newPackage.baseEventType
        )
      )
    ) {
      this._buffers[owner].push(newPackage);
    }
  }

  /**
   * receives registered pixi events
   * @this the event queue context
   * @param evt - the `PixiEvent` received
   */
  handlePixiEvent(
    this: EventQueueHandlerContext,
    event: FederatedPointerEvent
  ): void {
    // check non-matching events for 'similarness'
    // if (this.baseEventType !== event.type) {
    //   if (event.type === 'click' && this.baseEventType !== EVENT.CLICK) return;
    //   if (
    //     event.type === 'pointerdown' &&
    //     this.baseEventType !== EVENT.MOUSEDOWN_CAPTURE
    //   )
    //     return;
    // }
    event.stopPropagation();

    if (
      !this.context.__neverIgnoreStageEvents &&
      this.context.ignoreStageEvents &&
      this.owner === 'stage'
    ) {
      return;
    }
    // pixi or window event
    this.context.createPixiEqEvent(
      Object.clone(event),
      this.owner,
      this.baseEventType,
      event.type,
      this.related
    );
  }

  /**
   * receives registered pixi events
   * @this `EventQueueHandlerContext`
   * @param event the keyboard `Event` received
   */
  handleKeyboardEvent(this: EventQueueHandlerContext, event: Event): void {
    // HACK: prevent print menu from opening / editor from engaging
    // THIS NEEDS TO BE REPLACED WITH A CONFIGURABLE SOLUTION
    const keyEvent = cloneDeep(event) as KeyboardEvent;
    const osKey = buildOsModifier();
    const modifierKey = (event as KeyboardEvent)[osKey.modifier];
    const shiftKey = (event as KeyboardEvent).shiftKey;

    if (
      modifierKey &&
      shiftKey &&
      [KEY.ZERO].includes((event as KeyboardEvent).key)
    ) {
      event.preventDefault();
    }

    if (
      [KEY.B, KEY.P, KEY.E].includes((event as KeyboardEvent).key) &&
      modifierKey
    ) {
      event.preventDefault();
    }

    this.context.createKeyboardEqEvent(
      keyEvent,
      this.owner,
      this.baseEventType,
      event.type
    );
  }

  /**
   * receives registered pixi events
   * @this `EventQueueHandlerContext`
   * @param args the `EventArgs` received
   */
  handleManagerEvent<T extends any[]>(
    this: EventQueueHandlerContext,
    ...args: EventArgs<T>
  ): void {
    this.context.createManagerEqEvent(
      cloneDeep(args),
      this.owner,
      this.baseEventType
    );
  }

  handleElementEvent(this: EventQueueHandlerContext, event: Event): void {
    this.context.createWindowEqEvent(
      cloneDeep(event),
      this.owner,
      this.baseEventType,
      event.type
    );
  }

  /**
   * registers a pixi event
   * @param eventString event string to register
   * @param emitter the emitter of the event
   * @param owner the owner of the event
   */
  registerWithPixi(
    eventString: PixiEventTypes,
    emitter: DisplayObject,
    owner: UUID,
    related?: string
  ): void {
    const listener = this.handlePixiEvent.bind({
      context: this,
      owner,
      baseEventType: eventString,
      related,
    });
    emitter.on(eventString as any, listener);

    if (!(eventString in this._registeredPixiEmitters))
      this._registeredPixiEmitters[eventString] = {};
    this._registeredPixiEmitters[eventString][owner] = [emitter, listener];
  }

  /**
   * registers a keyboard event
   * @param eventString event string to register
   * @param owner the owner of the event
   */
  registerWithKeyboard(
    baseElement: HTMLElement,
    eventString: EventString,
    owner: UUID
  ): void {
    const boundListener = this.handleKeyboardEvent.bind({
      context: this,
      owner,
      baseEventType: eventString,
    });
    baseElement.addEventListener(eventString, boundListener);
    if (!this._registeredKeyboardListeners[eventString])
      this._registeredKeyboardListeners[eventString] = {};
    this._registeredKeyboardListeners[eventString][owner] = {
      listener: boundListener,
      baseElement,
    };
  }

  /**
   * registers a manager event
   * @param eventString event string to register
   * @param owner the owner of the event
   */
  registerWithManager(eventString: EventString, owner: UUID): void {
    const unsub = EventManager.on(eventString, this.handleManagerEvent, {
      context: this,
      owner,
      baseEventType: eventString,
    });
    if (!this._registeredManagerUnsubs[eventString])
      this._registeredManagerUnsubs[eventString] = {};
    this._registeredManagerUnsubs[eventString][owner] = unsub;
  }

  registerWithElement(
    element: HTMLElement,
    eventString: EventString,
    owner: string
  ): void {
    const boundListener = this.handleElementEvent.bind({
      context: this,
      owner,
      baseEventType: eventString,
    });
    element.addEventListener(eventString, boundListener);

    if (!this._registeredElementListeners[eventString])
      this._registeredElementListeners[eventString] = {};
    this._registeredElementListeners[eventString][owner] = {
      boundListener,
      element,
    };
  }

  /**
   * deregisters a pixi event
   * @param eventString event string of event
   * @param owner owner of the event
   */
  deregisterWithPixi(eventString: PixiEventTypes, owner: UUID): void {
    const registeredEmitter =
      this._registeredPixiEmitters[eventString]?.[owner];
    if (!registeredEmitter) return;
    const [emitter, listener] = registeredEmitter;
    emitter && emitter.removeListener(eventString as any, listener);
  }

  /**
   * deregisters a manager event
   * @param eventString event string of event
   * @param owner owner of the event
   */
  deregisterWithManager(eventString: EventString, owner: UUID): void {
    const unsub = this._registeredManagerUnsubs[eventString]?.[owner];
    unsub && unsub();
  }

  /**
   * deregisters a keyboard event
   * @param eventString event string of event
   * @param owner owner of the event
   */
  deregisterWithKeyboard(eventString: EventString, owner: UUID): void {
    const kblistener = this._registeredKeyboardListeners[eventString]?.[owner];
    if (kblistener) {
      kblistener.baseElement.removeEventListener(
        eventString,
        kblistener.listener
      );
      delete this._registeredKeyboardListeners[eventString][owner];
    }
  }

  deregisterWithElement(eventString: EventString, owner: UUID): void {
    const registeredEmitter =
      this._registeredElementListeners[eventString]?.[owner];
    if (!registeredEmitter) return;
    const { boundListener, element } = registeredEmitter;

    if (boundListener) {
      element.removeEventListener(eventString, boundListener);
      delete this._registeredElementListeners[eventString][owner];
    }
  }

  getBufferPackage(): ReplayBuffers<any[]> {
    return this._buffers;
  }
}
