import { isEqual } from 'lodash';
import { PrunedModel } from 'types';

export type ModelHistory = InstanceType<typeof ModelHistorySingleton>;

class ModelHistorySingleton {
  private present?: PrunedModel;
  private pastStack: any[];
  private futureStack: any[];

  constructor(undoStack: any[], redoStack: any[]) {
    this.pastStack = undoStack;
    this.futureStack = redoStack;
  }

  private setState(state: PrunedModel | undefined) {
    const prunedModelState = state || {};
    const prunedPresentState = this.present || {};

    if (!isEqual(prunedModelState, prunedPresentState)) {
      this.pastStack.push(this.present);
      this.present = state;
    }
  }

  set state(state: PrunedModel | undefined) {
    this.setState(state);
  }

  get state() {
    return this.present;
  }

  get pastSize() {
    return this.pastStack.length;
  }

  get futureSize() {
    return this.futureStack.length;
  }

  undo() {
    const previous = this.pastStack.pop();

    if (previous) {
      this.futureStack.push(this.present);
      this.present = previous;
    }
  }

  redo() {
    const next = this.futureStack.pop();

    if (next) {
      this.pastStack.push(this.present);
      this.present = next;
    }
  }

  clear() {
    this.pastStack = [];
    this.futureStack = [];
    this.present = undefined;
  }
}

const undoStack: any[] = [];
const redoStack: any[] = [];

export const modelHistory = new ModelHistorySingleton(undoStack, redoStack);
