import { PROPERTY_SIZE } from 'modules/common';
import { MODEL, NODE, SET, EDGE, PROPERTY_SCHEMA, GRID } from 'utils/constants';

export const initialPropSizesState = {
  columnSizes: {},
  rowSizes: {},
  cellSize: {
    height: 35,
    width: 100,
  },
};

export const initPropertySizes = (propSizes) => ({
  type: PROPERTY_SIZE.INITIALIZE,
  payload: propSizes,
});

/*******************
 * creates/updates column size for a given column
 * @param {string} col - column to be resized
 * @param {number} val - new size of column
 * @returns {Thunk}
 *******************/
export const updateColumnSize = (col, val) => (dispatch) => {
  dispatch({
    type: PROPERTY_SIZE.UPDATE.COLUMN,
    payload: { [col]: val },
  });
  dispatch({ type: MODEL.HISTORY_REMEMBER });
};

/*******************
 * creates/updates row size for a given row
 * @param {string} row - row to be resized
 * @param {number} val - new size of row
 * @param {boolean} allowUndo - true (default) to create a savepoint for
 *                             undo-redo; false otherwise.
 * @returns {Thunk}
 *******************/
export const updateRowSize =
  (row, val, allowUndo = true) =>
  (dispatch) => {
    dispatch(updateRowSizes([row], val, allowUndo));
  };

/*******************
 * creates/updates row sizes for given rows
 * @param {string[]} row - rows to be resized
 * @param {number} val - new size of rows
 * @param {boolean} allowUndo - true (default) to create a savepoint for
 *                             undo-redo; false otherwise.
 * @returns {Thunk}
 *******************/
export const updateRowSizes =
  (rows, val, allowUndo = true) =>
  (dispatch) => {
    const payload = {};
    rows.forEach((row) => {
      payload[row] = val;
    });
    dispatch({
      type: PROPERTY_SIZE.UPDATE.ROW,
      payload,
    });
    if (allowUndo) {
      dispatch({ type: MODEL.HISTORY_REMEMBER });
    }
  };

/*******************
 * updates size of all cells
 * @param {UUID[]} rows - array of row ids
 * @param {UUID[]} columns - array of column ids
 * @param {number} height - new height of rows
 * @param {number} width - new width of columns
 * @returns {Thunk}
 *******************/
export const updateAllCellSizes = (height, width) => (dispatch, getState) => {
  const modelReducer = getState().modelReducer;
  const rowIds = Object.keys(modelReducer.nodes)
    .concat(Object.keys(modelReducer.edges))
    .concat(Object.keys(modelReducer.sets))
    .concat(Object.keys(modelReducer.modelProps))
    .concat(Object.keys(modelReducer.modelCalcs))
    .concat(Object.keys(modelReducer.paths));

  // including the name columns so they are updated as well
  const columnIds = [
    GRID.NAME_COLUMN.SETS,
    GRID.NAME_COLUMN.NODES,
    GRID.NAME_COLUMN.EDGES,
    GRID.NAME_COLUMN.MODEL_PROPS,
    GRID.NAME_COLUMN.MODEL_CALCS,
    GRID.NAME_COLUMN.PATHS,
  ];
  columnIds.push(...Object.keys(modelReducer.propSchemas));

  const rowSizes = {};
  for (const key of rowIds) {
    rowSizes[key] = height;
  }
  const columnSizes = {};
  for (const key of columnIds) {
    columnSizes[key] = width;
  }
  dispatch({
    type: PROPERTY_SIZE.UPDATE.ALL_CELLS,
    payload: { rowSizes, columnSizes, height, width },
  });
  dispatch({ type: MODEL.HISTORY_REMEMBER });
};

export const handleDelete = (sizes, deleted) => {
  deleted.forEach((element) => {
    delete sizes[element.id];
  });
  return sizes;
};

/*******************
 * Reducer
 *******************/

export const propSizesReducer = (state = initialPropSizesState, action) => {
  switch (action.type) {
    case PROPERTY_SIZE.INITIALIZE:
      return {
        ...initialPropSizesState,
        ...action.payload,
      };

    case PROPERTY_SIZE.UPDATE.COLUMN:
      return {
        ...state,
        columnSizes: {
          ...state.columnSizes,
          ...action.payload,
        },
      };
    case PROPERTY_SIZE.UPDATE.ALL_CELLS:
      return {
        ...state,
        columnSizes: {
          ...action.payload.columnSizes,
        },
        rowSizes: {
          ...action.payload.rowSizes,
        },
        cellSize: {
          height: action.payload.height,
          width: action.payload.width,
        },
      };
    case PROPERTY_SIZE.UPDATE.ROW:
      return {
        ...state,
        rowSizes: {
          ...state.rowSizes,
          ...action.payload,
        },
      };
    case PROPERTY_SCHEMA.DELETE:
      return {
        ...state,
        columnSizes: handleDelete(state.columnSizes, action.payload),
      };
    case NODE.DELETE:
    case EDGE.DELETE:
    case SET.DELETE:
      return {
        ...state,
        rowSizes: handleDelete(state.rowSizes, action.payload),
      };
    default:
      return state;
  }
};

export default propSizesReducer;
