import { hydrateModel } from '../actions';
import { gid, type GID } from '../gid';
import {
  createSheet,
  _deleteSheet,
  _renameSheet,
  addColumns,
  addRows,
  setValue,
} from './actions';
import { createReducer } from '@reduxjs/toolkit';

export interface ValueEntry {
  raw: string;
  evaluated?: string;
  error?: string;
}

export interface SheetEntry {
  name: string;
  type: string;
  rows: string[];
  columns: string[];
  rowKey?: GID;
  columnKey?: GID;
  entries: Record<string, Record<string, GID>>;
  values: Record<GID, ValueEntry>;
}

export interface SheetsReducerState {
  ids: GID[];
  byId: Record<GID, SheetEntry>;
}

export const initialState: SheetsReducerState = {
  ids: [],
  byId: {},
};

export const reducer = createReducer(initialState, (builder) => {
  // --- HYDRATION
  builder.addCase(hydrateModel, (state, { payload: model }) => {
    if (!model.sheets) return;

    state.byId = { ...model.sheets.byId };
    state.ids = [...model.sheets.ids];
  });

  builder.addCase(setValue, (state, { payload }) => {
    const sheet = state.byId[payload.id];

    sheet.entries[payload.rowId] ||= {};
    const valId = sheet.entries[payload.rowId][payload.columnId] || gid();

    sheet.entries[payload.rowId][payload.columnId] = valId;
    sheet.values[valId] = { raw: payload.value };
  });

  builder.addCase(addRows, (state, action) => {
    const sheet = state.byId[action.payload.id];
    sheet.rows.push(...action.payload.rows);
  });

  builder.addCase(addColumns, (state, action) => {
    const sheet = state.byId[action.payload.id];
    sheet.columns.push(...action.payload.columns);
  });

  builder.addCase(createSheet, (state, action) => {
    const id = gid();
    const sheet = {
      name: action.payload.name,
      type: action.payload.type,
      rows: [],
      columns: [],
      entries: {},
      values: {},
    };
    state.ids.push(id);
    state.byId[id] = sheet;
  });

  builder.addCase(_renameSheet, (state, action) => {
    const sheet = state.byId[action.payload.id];
    sheet.name = action.payload.name;
  });

  builder.addCase(_deleteSheet, (state, action) => {
    delete state.byId[action.payload.id];
    state.ids = state.ids.filter((id) => id !== action.payload.id);
  });
});
