import difference from 'lodash/difference';
import {
  MediaQuery,
  MetraApiAction,
  NormalizedResult,
  MetraThunkAction,
  RootReducer,
  LibraryReducer,
  MetraSimpleAction,
  ThunkActionFunc,
  ThunkAction,
  CollectionKey,
  MetraThunkDispatch,
  MetraMedia,
} from 'types';
import { METRA } from 'utils/constants';
import { getBelongsTo, getIdsOnPage } from 'utils/utils';
import { LIBRARY_VIEW_TYPE, isApiResponseAction } from 'modules/common';
import { getMedia } from 'modules/media/actions';
import { getMediaFilesize } from 'modules/media/media-extra';
import { LIBRARY } from './constants';
import { mapCollectionToEntities } from 'modules/entities/collections';
import { isSome } from 'helpers/utils';
import OrgContext from 'utils/OrganizationContext';

export const setSelected =
  (filesArr: Numberish[]): ThunkAction<void> =>
  async (dispatch, getState) => {
    const { media } = getState().entityReducer;
    const files = mapCollectionToEntities({ ids: filesArr }, media);
    await dispatch({
      type: LIBRARY.UPDATE.SELECTED,
      payload: {
        selected: {
          ids: filesArr,
          files: files,
        },
      },
    });
    const state = getState();
    const { pagination, selected } = state.libraryReducer;
    const fileIds = getActiveFileIds(state);
    dispatch(setSelectedFileIdNotOnPage(fileIds, pagination, selected.ids));
  };

export const setSelectedFileIdNotOnPage =
  (
    fileIds: Numberish[] | readonly Numberish[],
    pagination: LibraryReducer['pagination'],
    selectedIds: PropertyKey[]
  ): ThunkAction<void> =>
  (dispatch) => {
    const selectedFileIdNotOnPage =
      difference(selectedIds, getIdsOnPage(fileIds, pagination)).length > 0;
    dispatch({
      type: LIBRARY.UPDATE.SELECTED_FILE_ID_NOT_ON_PAGE,
      payload: { selectedFileIdNotOnPage },
    });
  };

const _immutablePermArray: readonly Numberish[] = [];

export function getActiveFileIds(state: RootReducer): readonly Numberish[] {
  if (state.searchReducer.displaySearchResults) {
    return (
      state.collectionReducer.mediaSearchResults?.ids ?? _immutablePermArray
    );
  }
  return state.collectionReducer.projectLibrary?.ids ?? _immutablePermArray;
}

export function resetSelected(): ThunkAction<void> {
  return (dispatch, getState) => {
    dispatch({
      type: LIBRARY.UPDATE.SELECTED,
      payload: {
        selected: {
          ids: [],
          files: [],
        },
      },
    });
    const state = getState();
    const { pagination, selected } = state.libraryReducer;
    const fileIds = getActiveFileIds(state);
    dispatch(setSelectedFileIdNotOnPage(fileIds, pagination, selected.ids));
  };
}

export function setPagination(
  paginate: Partial<LibraryReducer['pagination']>
): MetraThunkAction<unknown, void, void> {
  return (dispatch, getState) => {
    dispatch({
      type: LIBRARY.UPDATE.PAGINATION,
      payload: paginate,
    });
    const state = getState();
    const { pagination, selected } = state.libraryReducer;
    const fileIds = getActiveFileIds(state);
    dispatch(setSelectedFileIdNotOnPage(fileIds, pagination, selected.ids));
  };
}

export function setLoading(
  loading: boolean
): MetraSimpleAction<{ loading: boolean }> {
  return {
    type: LIBRARY.UPDATE.LOADING,
    payload: { loading },
  };
}

export const fetchAllNoteIds =
  (projectId: string, modelId: Numberish) =>
  async (dispatch: MetraThunkDispatch) => {
    let totalNameCount = 1;
    let fetchedIds: Numberish[] = [];
    while (fetchedIds.length < totalNameCount) {
      const notesListResponse = await dispatch(
        fetchNotesList(projectId, Number.parseInt(modelId), fetchedIds.length)
      );
      if (isSome(notesListResponse) && isApiResponseAction(notesListResponse)) {
        Object.values(
          (notesListResponse.payload as NormalizedResult).entities.media ?? {}
        ).forEach((media) => {
          const noteName = (media as MetraMedia).name.substring(
            0,
            (media as MetraMedia).name.length - METRA.NOTE_EXTENSION.length
          );
          fetchedIds.push(media.id);
        });
        totalNameCount = notesListResponse.payload.count;
      }
    }
    return fetchedIds;
  };

export const fetchNotesList: ThunkActionFunc<
  [projectId: string, parentModelId: number, startIndex: number],
  Promise<MetraApiAction<NormalizedResult>>
> =
  (projectId, parentModelId, startIndex = 0) =>
  async (dispatch) => {
    /* eslint-disable camelcase */
    const queryCriteria = {
      name__endswith: METRA.NOTE_EXTENSION,
      belongs_to: parentModelId,
      limit: 50,
      offset: startIndex,
    };
    /* eslint-enable camelcase */

    const getMediaArgs = {
      projectId,
      query: queryCriteria,
      collection: null,
    };
    const result = await dispatch(getMedia(getMediaArgs));
    return result;
  };

/**
 *
 * @param resetPagination - whether or not to reset pagination
 * @returns an api normalized result
 */
export const fetchMedia: ThunkActionFunc<
  [resetPagination?: boolean],
  Promise<MetraApiAction<NormalizedResult>>
> =
  (resetPagination = true) =>
  async (dispatch, getState) => {
    const state = getState();
    const { currentFolder, sort, tableView } = state.libraryReducer;
    dispatch(setLoading(true));
    const { projectId } = state.projectReducer;

    // eslint-disable-next-line camelcase
    const criteriaModelType = { name__endswith: METRA.EXTENSION };
    const criteriaInCurrentFolder = getBelongsTo(currentFolder);
    const queryCriteria =
      tableView === LIBRARY_VIEW_TYPE.MODELS
        ? criteriaModelType
        : criteriaInCurrentFolder;

    const ordering = `${sort.ascending ? '' : '-'}${sort.column}`;
    const getMediaArgs = {
      projectId,
      query: {
        ordering,
        // eslint-disable-next-line camelcase
        show_hidden: true,
        ...queryCriteria,
      } as Partial<MediaQuery>,
      guildCName: OrgContext.guild!,
    };

    if (resetPagination) {
      dispatch(setPagination({ numberOfRows: 50, startIndex: 0 }));
      getMediaArgs.query.limit = 50;
    } else {
      // we aren't going to completely reset pagination, but
      // we need to make sure it is in a valid state.
      const itemsInLibrary =
        state.collectionReducer?.projectLibrary?.count ?? 0;
      const oldDisplayOffset = state.libraryReducer.pagination.startIndex;
      let newDisplayOffset = oldDisplayOffset;
      const pageSize = state.libraryReducer.pagination.numberOfRows;
      while (newDisplayOffset >= itemsInLibrary) {
        // current page for display is past the end of the items
        newDisplayOffset = Math.max(newDisplayOffset - pageSize, 0);
        if (newDisplayOffset === 0) {
          break;
        }
      }
      if (newDisplayOffset !== oldDisplayOffset) {
        dispatch(
          setPagination({
            numberOfRows: pageSize,
            startIndex: newDisplayOffset,
          })
        );
      }
      getMediaArgs.query.limit = pageSize;
    }
    /*
     * this causes the loading to appear to be slow because this also
     * loads all of the tags
     */
    dispatch(resetSelected());
    const result = await dispatch(getMedia(getMediaArgs));
    dispatch(setLoading(false));

    // We brought back the media without file_size; make an async call to go
    // back and populate file_size
    if (isApiResponseAction(result)) {
      const media = result.payload.entities?.media ?? {};
      const returnedIds = Object.keys(media);
      if (returnedIds.length > 0) {
        dispatch(getMediaFilesize({ ids: returnedIds }));
      }
    }

    return result;
  };
