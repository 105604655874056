import { MetraVoidAction } from 'types';

export const MODEL_HISTORY = {
  CLEAR: 'model_history/CLEAR',
  CREATE: 'model_history/CREATE',
  REDO: 'model_history/REDO',
  UNDO: 'model_history/UNDO',
  UNIVERSAL: 'model_history/UNIVERSAL',
};

export function undo(): MetraVoidAction {
  return {
    type: MODEL_HISTORY.UNDO,
  };
}

export function redo(): MetraVoidAction {
  return {
    type: MODEL_HISTORY.REDO,
  };
}

export function clear(): MetraVoidAction {
  return {
    type: MODEL_HISTORY.CLEAR,
  };
}
