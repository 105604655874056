/* eslint no-restricted-globals: "off" */
import { isNone, isSome, pathElementAfter } from 'helpers/utils';
import { t3dev } from 't3dev';

export class OrgContext {
  static _organization: Option<string> = null;
  static _guild: Option<string> = null;

  static get organization(): Option<string> {
    if (isSome(OrgContext._organization)) return OrgContext._organization;
    OrgContext.parse(location.pathname);
    return OrgContext._organization;
  }

  static get guild(): Option<string> {
    if (isSome(OrgContext._guild)) return OrgContext._guild;
    OrgContext.parse(location.pathname);
    return OrgContext._guild;
  }

  static set guild(value: Option<string>) {
    OrgContext._guild = value;
  }

  static update(): void {
    OrgContext.parse(location.pathname);
  }

  static parse(urlPath: string): void {
    // NOTE: we currently do not change orgs, so we should never overwrite the org if
    // it is already set, if we allow this, if anyone were to call `parse` or `update`
    // we have no idea what it could be updated to
    if (isNone(OrgContext._organization)) {
      OrgContext._organization = pathElementAfter('org', urlPath);
    }
    if (isSome(OrgContext._organization)) {
      OrgContext._guild = pathElementAfter('guild', urlPath);
    }
  }

  static _reset() {
    OrgContext._organization = null;
    OrgContext._guild = null;
  }
}

export default OrgContext;

t3dev().setValue('org', OrgContext);
