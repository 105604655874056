import { Middleware, configureStore } from '@reduxjs/toolkit';
import { setGlobalStore } from 'utils/utils';
import { setGlobalGetState, setDispatcher } from 'utils/utils-extra';
import {
  createActionIdMiddleware,
  createTrackableThunkMiddleware,
} from 'middleware/trackableThunks';
import { createSentryLoggingMiddleware } from 'middleware/sentry';
import { initialState, rootReducer } from 'modules';
import { EventManager } from 'metra-events/manager';
import { createMetraThunkMiddleware } from 'middleware/metra-thunk';
import { history } from 'middleware/history';

/**
 * the Metra redux Store
 */
export const metraStore = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  // we ignore the "default" middleware since it causes alot of problems
  // we're not ready to fix
  middleware: (_getDefaultMiddleware) => {
    if (!EventManager.isInitialized) {
      EventManager.init();
    }
    return [
      createMetraThunkMiddleware({ emit: EventManager.emit }),
      createActionIdMiddleware(),
      createTrackableThunkMiddleware(),
      createSentryLoggingMiddleware(),
      history,
    ] as Middleware[];
  },
  devTools: {
    name: 'metra-redux-devtools',
    latency: 0,
    maxAge: 50,
    trace: true,
    traceLimit: 10,
    autoPause: true,
  },
});

// All cloud environments are flagged as PROD causing devTools
// to be disabled.  Leaving this code here if decide to revisit
// and attempt to disable on PROD only.
// export const metraStore = FeatureFlags.IS_PROD
//   ? configureStore({
//       reducer: rootReducer,
//       preloadedState: initialState,
//       // we ignore the "default" middleware since it causes alot of problems
//       // we're not ready to fix
//       middleware: (_getDefaultMiddleware) => {
//         if (!EventManager.isInitialized) {
//           EventManager.init();
//         }
//         return [
//           createMetraThunkMiddleware({ emit: EventManager.emit }),
//           createActionIdMiddleware(),
//           createTrackableThunkMiddleware(),
//           createSentryLoggingMiddleware(),
//         ] as Middleware[];
//       },
//       devTools: false,
//     })
//   : configureStore({
//       reducer: rootReducer,
//       preloadedState: initialState,
//       // we ignore the "default" middleware since it causes alot of problems
//       // we're not ready to fix
//       middleware: (_getDefaultMiddleware) => {
//         if (!EventManager.isInitialized) {
//           EventManager.init();
//         }
//         return [
//           createMetraThunkMiddleware({ emit: EventManager.emit }),
//           createActionIdMiddleware(),
//           createTrackableThunkMiddleware(),
//           createSentryLoggingMiddleware(),
//         ] as Middleware[];
//       },
//       devTools: {
//         name: 'metra-redux-devtools',
//         latency: 0,
//         maxAge: 50,
//         trace: true,
//         traceLimit: 10,
//         autoPause: true,
//       },
//     });

setGlobalStore(metraStore);
setDispatcher(metraStore.dispatch);
setGlobalGetState(metraStore.getState);

export default metraStore;
