import { TOASTS } from 'modules/common/constants';
import { v4 as uuid } from 'uuid';

/**
 * [createToastMessage create a toast message]
 * @param {string} type -type of toast to create: error, info, or notification
 * @param {string} text -the text of the notification
 * @param {number} [autoClose]
 * @param {() => void} [undo]
 * @return {import('types').MetraSimpleAction<import('types').ToastData> } a create toast message action
 */
export const createToastMessage = (type, text, autoClose, undo) => ({
  type: type,
  payload: { id: uuid(), type, text, autoClose, undo },
});

/**
 * [dismissToastMessage dismiss a specific toast message]
 * @param  {[type]} id [the id of the toast message to dismiss]
 * @return {[type]}       [a dismiss toast action]
 */
export const dismissToastMessage = (id) => ({
  type: TOASTS.DISMISS,
  payload: id,
});

/*
 * REDUCER
 */

/**
 * @type {import('types').ToastsReducer}
 */
export const initialState = {};

/**
 * @type {import('redux').Reducer<import('types').ToastsReducer>}
 */
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOASTS.SUCCESS:
    case TOASTS.ERROR:
    case TOASTS.INFO:
    case TOASTS.WARNING: {
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    }
    case TOASTS.DISMISS: {
      const newState = { ...state };
      delete newState[action.payload];
      return newState;
    }
    default:
      return { ...state };
  }
};
