import {
  MetraApiAction,
  ThunkActionFunc,
  NormalizedResult,
  UploadFileArgs,
  UploadFileProps,
  MetraMedia,
  GetMediaProps,
  MultiPartUploadArgs,
  MetraModelUploadResult,
  MetraFileUploadResult,
  UploadFileReturnValue,
  UploadTypes,
  MultiPartDoUploadResponse,
  MetraDispatch,
  UploadResult,
  GetRecentMediaProps,
} from 'types';
import {
  ENTITIES,
  MEDIA,
  apiGet,
  apiPost,
  apiPut,
  isApiErrorAction,
  isApiResponseAction,
} from 'modules/common';
import { ACTIVITY_PING_SECONDS, API_URL } from 'utils/settings';
import { buildOrgUrl } from 'utils/url-builders';
import {
  SCHEMA_NAME,
  MAX_FILE_SIZE,
  MAX_MODEL_FILE_SIZE,
  MAX_NON_MULTI_PART_SIZE,
} from './constants';
import { MESSAGES } from 'modules/ui/messages';
import {
  isMediaEntry,
  isFolder,
  isNone,
  isFileEntry,
  isStandardFile,
  isMedia,
} from 'helpers/utils';
import { fetchMediaTags } from './tags';
import { tickUploadingCount } from 'modules/library/other';
import { isSome } from 'helpers/utils';
import { MultiPartUpload } from './multiPartUpload';
import { METRA } from 'utils/constants';
import { getFileName } from 'utils/utils';
import { getTenantState } from 'modules/auth/auth';
import { formatISO } from 'date-fns';
import {
  makeCreateSuccess,
  makeNormalizedResults,
  makeUpdateManySuccess,
  makeUpdateSuccess,
} from 'modules/entities/utils';
import { makeResponse, makeXhr } from 'helpers/api';
import { finishUploadFailedNotesEditor } from 'modules/model/notesEditor/actions';

const handleAbort: ThunkActionFunc<
  [UploadFileArgs['onAbort'], MetraApiAction<UploadTypes, void>, boolean],
  void
> = (onAbort, upload, isMetraNote) => async (dispatch) => {
  if (isSome(onAbort) && upload) {
    await onAbort(upload);
    dispatch(tickUploadingCount(-1));
    if (isMetraNote) dispatch(finishUploadFailedNotesEditor());
  }
};

const makeXhrUploader: ThunkActionFunc<
  [
    args: UploadFileProps,
    upload: MetraApiAction<UploadTypes, void>,
    isMetraNote: boolean,
    setHttpMessaging: (value: boolean) => void
  ],
  typeof fetch
> =
  (args, upload, isMetraNote, setHttpMessaging) =>
  (dispatch) =>
  async (
    url: RequestInfo | URL,
    options?: RequestInit | undefined
  ): Promise<Response> => {
    const opt = options as RequestInit;
    const file = opt.body;

    const [send, xhr] = makeXhr(url, opt);

    if (isSome(args.signal))
      args.signal.addEventListener('abort', () => xhr.abort());

    if (isSome(args.onProgress))
      xhr.upload.addEventListener('progress', args.onProgress);

    if (isSome(args.onUploaded))
      xhr.addEventListener('loadend', args.onUploaded);

    try {
      if (isSome(args.signal) && args.signal.aborted && isSome(args.onAbort)) {
        dispatch(handleAbort(args.onAbort, upload, isMetraNote));
        // FIXME: this is incorrect, what should it be?
        dispatch({ type: MEDIA.CREATE_CANCELLED });
        throw new Error('aborted...');
      }
      // we should always pass a Blob (File) object
      if (isSome(file) && file instanceof Blob) {
        // xhr.withCredentials = true;
        return await send(file);
      } else {
        dispatch({ type: MEDIA.CREATE_FAILURE });
        throw new Error('no-file');
      }
    } catch (e) {
      const err = e as Response;
      // if (
      //   (err.status === 400 && err.statusText === 'Bad Request') ||
      //   err.status === 415
      // ) {
      // gather up the response content so we can return it later -
      // it will have a message about what went wrong for the user
      const responseContent = await err.json();
      setHttpMessaging(true);
      // useHttpMessaging = true;
      return makeResponse({
        // bodyUsed: true,
        // error: true,
        status: err.status,
        statusText: responseContent.message ?? responseContent.error,
        // type: 'basic',
        // url: request.responseURL,
        // getResponseHeader: request.getResponseHeader,// () => undefined,
      } as XMLHttpRequest);
      // }
    }
  };

const doPostRequest = async (
  dispatch: MetraDispatch,
  type: string,
  formData: FormData
): Promise<MetraApiAction<UploadTypes, void>> => {
  return dispatch(
    apiPost<MetraFileUploadResult | MetraModelUploadResult, void>({
      /* eslint-disable camelcase */
      entity: 'uploads',
      body: formData,
      types: [MEDIA.UPLOAD_SUCCESS, MEDIA.UPLOAD_FAILURE],
      /* eslint-enable camelcase */
    })
  );
};

const doPutRequest = async (
  dispatch: MetraDispatch,
  explicit: string,
  uploadingFile: File,
  signal: AbortSignal | undefined,
  fetcher: {
    (
      input: RequestInfo | URL,
      init?: RequestInit | undefined
    ): Promise<Response>;
  }
): Promise<MetraApiAction<Partial<UploadResult>>> => {
  return dispatch(
    apiPut<{ etag: Option<string> }>({
      explicit,
      body: uploadingFile,
      types: [
        {
          type: MEDIA.CREATE_SUCCESS,
          payload: async (_action, _state, response) => {
            let etag = response.headers.get('ETag');
            // fix "null" coersion
            if (isSome(etag) && etag === 'null') {
              etag = null;
            }
            return { etag };
          },
        },
        MEDIA.CREATE_FAILURE,
      ],
      // updating this to false to support .metra uploads
      forceDisableAuth: true,
      signal,
      fetcher,
    })
  );
};

/**
 *
 * @param  props
 * @return
 */
export const uploadFile: ThunkActionFunc<
  [args: UploadFileProps],
  Promise<UploadFileReturnValue>
> = (args) => {
  args.projectId ||= '0';
  args.belongsTo ||= null;
  args.overwriteExisting ||= false;
  args.activityPingSeconds ||= ACTIVITY_PING_SECONDS;
  const {
    file,
    projectId,
    belongsTo,
    overwriteExisting,
    activityPingSeconds,
    signal,
    targetSignedUrl,
    onAbort,
    onProgress,
    onUploaded,
    deleteInvalid,
    savingModel,
  } = args;
  // default to false if not explicitly set to true
  let isMultiPartRequest = args.isMultiPartRequest;
  isMultiPartRequest ||= false;

  let uploadingFile!: File;
  if (isMediaEntry(file) && isFileEntry(file)) {
    uploadingFile = new File([file.file], file.name);
  } else if (isStandardFile(file)) {
    uploadingFile = file;
  } else {
    return async (dispatch) =>
      dispatch({
        type: MEDIA.CREATE_FAILURE,
        payload: 'cannot-upload-folders',
      });
  }

  const isMetraNote = uploadingFile.name.endsWith(METRA.NOTE_EXTENSION);
  const type =
    uploadingFile.name.split('.').pop() === 'metra' ? 'model' : 'file';
  const metraNoteFileName = !isMetraNote
    ? ''
    : uploadingFile.name.substring(
        0,
        uploadingFile.name.length - METRA.NOTE_EXTENSION.length
      );

  return async (dispatch, getState) => {
    let activityPing;
    // we don't know if we are uploading a file or a model so we need to handle both
    let upload!: MetraApiAction<UploadTypes, void>;

    let formData: FormData;
    const oldMedia = getState().entityReducer.media;

    try {
      if (targetSignedUrl) {
        // this is [probably] a MP upload (could be org/guild logo instead)
        if (args.mpur) {
          upload = args.mpur;
        }
      } else {
        if (
          uploadingFile.size > MAX_FILE_SIZE ||
          (type === 'model' && uploadingFile.size > MAX_MODEL_FILE_SIZE)
        ) {
          return dispatch({
            type: MEDIA.CREATE_FAILURE,
            payload: 'file-too-large',
          });
        }
        if (
          uploadingFile.size > MAX_NON_MULTI_PART_SIZE &&
          !isMultiPartRequest &&
          isSome(signal) &&
          isSome(onProgress) &&
          isSome(onUploaded) &&
          isSome(projectId)
        ) {
          return dispatch(
            chunkUploadFile({
              file: uploadingFile,
              projectId,
              belongsTo,
              overwriteExisting,
              activityPingSeconds,
              signal,
              onProgress,
              onUploaded,
            })
          );
        }

        formData = new FormData();
        formData.append('project', `${projectId}`);
        formData.append('name', getFileName(file));
        formData.append('belongs_to', belongsTo ? `${belongsTo}` : '');
        formData.append('overwrite_existing', `${overwriteExisting}`);
        formData.append('file_size', `${uploadingFile.size}`);
        // Whenever you upload JSON data for a model, set delete_invalid to True.
        // This will make Nucleo continue to process the invalid versions as we do now.
        formData.append('delete_invalid', `${deleteInvalid ? true : false}`);

        // FIXME: this is broken and will DDOS nucleo for mass uploads
        // Ping 'hello!' before logging out from inactivity. default to one minute
        // before logout
        activityPing = setInterval(
          () => dispatch(getTenantState()),
          activityPingSeconds * 1000
        );
        dispatch(tickUploadingCount(1));

        upload = await doPostRequest(dispatch, type, formData);
        if (isApiErrorAction(upload)) {
          if (upload.payload.status < 500) {
            dispatch(tickUploadingCount(-1));
            return dispatch({
              ...upload,
              type: MEDIA.CREATE_FAILURE,
            });
          }
          if (upload.payload.status >= 500) {
            // Retry once if status is 500+
            upload = await doPostRequest(dispatch, type, formData);
          }
        }

        // if the abort already happened, we need to invoke immediately
        if (signal && signal.aborted && isSome(onAbort)) {
          dispatch(handleAbort(onAbort, upload, isMetraNote));
          return dispatch({ type: MEDIA.CREATE_CANCELLED });
        }
      }

      if (isNone(upload) && !targetSignedUrl) {
        return dispatch({
          type: MEDIA.CREATE_FAILURE,
          payload: 'empty-upload',
        });
      }

      let useHttpMessaging = false;
      const setHttpMessaging = (value: boolean) => {
        useHttpMessaging = value;
      };

      if (!targetSignedUrl && isApiErrorAction(upload)) {
        dispatch(tickUploadingCount(-1));
        return dispatch({
          ...upload,
          type: MEDIA.CREATE_FAILURE,
        });
      }

      let explicit: string;
      if (targetSignedUrl) {
        explicit = targetSignedUrl;
      } else if ('signed_url' in upload.payload) {
        explicit = upload.payload.signed_url;
      } else {
        // NO destination url
        return dispatch({
          ...upload,
          type: MEDIA.CREATE_FAILURE,
        });
      }

      const fetcher = dispatch(
        makeXhrUploader(args, upload, isMetraNote, setHttpMessaging)
      );

      let result = await doPutRequest(
        dispatch,
        explicit,
        uploadingFile,
        signal,
        fetcher
      );
      if (isApiErrorAction(result)) {
        if (result.payload.status >= 500) {
          // Retry once if status is 500+
          result = await doPutRequest(
            dispatch,
            explicit,
            uploadingFile,
            signal,
            fetcher
          );
        }
      }

      if (isSome(signal) && signal.aborted && isSome(onAbort)) {
        dispatch(handleAbort(onAbort, upload, isMetraNote));
        return { type: MEDIA.CREATE_CANCELLED };
      }

      if (targetSignedUrl) {
        ///
      } else if ('media' in upload.payload) {
        // If we are saving the model, do not trigger these redux updates.
        // The save is just being kicked off and the model load logic has no awareness,
        // so when the new media is fetched, the URL is out of sync when grabbing the
        // version (LoadedModelBoundary).  There will be a future story to rewrite the
        // model loading useEffects so this savingModel flag will no longer be needed.
        if (
          isApiResponseAction(upload) &&
          upload.payload.media &&
          !savingModel
        ) {
          //update the entities store with this upload's media record
          const payload = {
            ...upload.payload.media,
            created: formatISO(new Date()),
          };
          if (overwriteExisting) {
            dispatch(
              makeUpdateSuccess(
                payload,
                'media',
                belongsTo !== null ? 'modelMedia' : 'projectLibrary'
              )
            );
          } else {
            dispatch(
              makeCreateSuccess(
                payload,
                'media',
                belongsTo !== null ? 'modelMedia' : 'projectLibrary'
              )
            );
          }

          // dispatch({
          //   type: ENTITIES.ACTION_SUCCESS,
          //   payload: {
          //     ...upload.payload.media,
          //     created: formatISO(new Date()),
          //   },
          //   meta: {
          //     mutation,
          //     schema: SCHEMA_NAME,
          //     collection: belongsTo !== null ? 'modelMedia' : 'projectMedia',
          //   },
          // });
        }
        dispatch(tickUploadingCount(-1));

        if (isApiErrorAction(upload)) {
          /// FIXME: what should we do here?
        } else {
          //
          const overwritten =
            oldMedia?.[upload.payload.media.id.toString()] ?? null;
          if (isSome(overwritten)) {
            const value = overwritten.asMutable();
            if (isSome(value) && isMedia(value)) {
              upload.payload = {
                ...upload.payload,
                overwritten: value,
              };
            }
          }
        }
      }

      if (isApiErrorAction(result)) {
        dispatch({
          ...result,
          type: MEDIA.UPLOAD_FAILURE,
        });
        return result;
      }

      // if this was a model-upload error we captured useful information to
      // return, a user-level message.
      if (useHttpMessaging) {
        dispatch({
          ...result,
          type: MEDIA.UPLOAD_FAILURE,
        });
        return result;
      }

      return {
        ...result,
        payload: {
          upload: upload?.payload,
          etag: result.payload.etag,
        },
      };
    } finally {
      clearInterval(activityPing);
    }
  };
};

export const chunkUploadFile: ThunkActionFunc<
  [MultiPartUploadArgs],
  Promise<MultiPartDoUploadResponse>
> = (args) => async (dispatch) => {
  const mpUpload = new MultiPartUpload(args, uploadFile);
  return await dispatch(mpUpload.doUpload());
};

/**
 * @param args - the media query arguments
 * @returns a metra thunk action
 */
export const getMedia: ThunkActionFunc<
  [args?: Partial<GetMediaProps>],
  Promise<MetraApiAction<NormalizedResult<'media'>>>
> = ({
  projectId = 0,
  belongsTo = null,
  query = {},
  collection = 'projectLibrary',
  guildCName = undefined,
} = {}) => {
  return async (dispatch, getState) => {
    const results = await dispatch(
      apiGet<NormalizedResult<'media'>>({
        explicit: guildCName
          ? `${API_URL}${buildOrgUrl()}/guild/${guildCName}/${SCHEMA_NAME}`
          : `${API_URL}${buildOrgUrl()}/${SCHEMA_NAME}`,
        entity: SCHEMA_NAME,
        params: {
          project: projectId,
          show_archived: false,
          belongs_to: belongsTo,
          ...query,
        },
        collection,
        types: [ENTITIES.ACTION_SUCCESS, MEDIA.GET_FAILURE],
        error: MESSAGES.ERROR.GET.MEDIA,
        meta: {
          schema: SCHEMA_NAME,
        },
      })
    );
    if (isApiErrorAction(results)) {
      return results;
    } else {
      // fetch tags for all folders and "inject" them into redux action
      // FIXME: this is a terrible idea...
      const promises = await Promise.all(
        Object.map(results.payload.entities.media, async ([_key, value]) => {
          // This line is only needed to QE-test
          //   https://gitlab.t3/MeTRA/megalith/-/issues/2700
          // Once the follow-on story https://gitlab.t3/MeTRA/nucleo/-/issues/1018
          // goes in we expect file_size to come back undefined from nucleo
          value.file_size = undefined;

          if (!isFolder(value)) return null;
          const tagResult = await dispatch(fetchMediaTags(value.id));
          return {
            id: value.id,
            tagResult,
          };
        })
      );

      const updates = promises.reduce((updates, result) => {
        if (isNone(result)) return updates;
        if (isNone(result.tagResult)) return updates;
        const media = Object.clone(getState().entityReducer.media?.[result.id]);
        if (isNone(media)) return updates;
        if (!isMedia(media)) return updates;
        return {
          ...updates,
          [media.id]: {
            ...media,
            tags: result.tagResult.tags,
          },
        };
      }, {} as Record<Numberish, MetraMedia>);

      // Object.forEach(updates, ([key, value]) => {
      dispatch(
        makeUpdateManySuccess(makeNormalizedResults(updates, 'media'), 'media')
      );
      // });
      return results;
    }
  };
};

export const getRecentMedia: ThunkActionFunc<
  [args?: Partial<GetRecentMediaProps>],
  Promise<MetraApiAction<NormalizedResult<'media'>>>
> = ({ query = {}, collection = 'projectLibrary', signal } = {}) => {
  return async (dispatch, getState) => {
    const results = await dispatch(
      apiGet<NormalizedResult<'media'>>({
        explicit: `${API_URL}${buildOrgUrl()}/${SCHEMA_NAME}/recents`,
        entity: SCHEMA_NAME,
        params: {
          ...query,
        },
        signal,
        collection,
        types: [ENTITIES.ACTION_SUCCESS, MEDIA.GET_FAILURE],
        error: MESSAGES.ERROR.GET.MEDIA,
        meta: {
          schema: SCHEMA_NAME,
        },
      })
    );
    if (isApiErrorAction(results)) {
      return results;
    } else {
      const tags = results.payload.entities.tags;

      if (tags) {
        // update entityReducer.tags
        // update collectionReducer.recentTags
        dispatch(
          makeUpdateManySuccess(
            makeNormalizedResults(tags, 'tags'),
            'tags',
            'recentTags'
          )
        );
      }

      return results;
    }
  };
};
