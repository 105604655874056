import { GID, newestGid } from '../gid';
import {
  addCategoryMembers,
  createCategory,
  createProperty,
  deleteCategory,
  renameCategory,
  renameProperty,
} from '../schema/actions';
import type { ThunkActionFunc } from 'types';
import { createAction } from '@reduxjs/toolkit';
import { remember } from '../base/actions';

export const createCategorySheet: ThunkActionFunc<[string], GID> =
  (name) => (dispatch, _getState) => {
    dispatch(createSheet({ name, type: 'category' }));
    const sheet = newestGid();
    dispatch(createCategory({ name, sheet }));
    const categoryId = newestGid();
    dispatch(addRows({ id: sheet, rows: [categoryId] }));
    dispatch(remember());
    return sheet;
  };

export const setCategorySheetValue: ThunkActionFunc<
  [string, string, string, string]
> = (id, rowId, columnId, value) => (dispatch, getState) => {
  const { schema } = getState().modelReducer;
  dispatch(setValue({ id, rowId, columnId, value }));

  // if this is the category row (IE first row)
  // then we want to set the property name for this column as well
  if (schema.categories.bySheet[id] === rowId) {
    dispatch(renameProperty({ id: columnId, name: value }));
  }
  dispatch(remember());
};

export const addCategorySheetProperty: ThunkActionFunc<[string, string]> =
  (sheetId, name) => (dispatch, getState) => {
    const { schema } = getState().modelReducer;
    const category = schema.categories.bySheet[sheetId];

    // create property on the schema side
    dispatch(createProperty({ name, category }));
    const propid = newestGid();

    // add the property as a column
    dispatch(addColumns({ id: sheetId, columns: [propid] }));

    // set the property name as a row value. Name row is keyed by category ID
    dispatch(setCategorySheetValue(sheetId, category, propid, name));
    dispatch(remember());
  };

export const addCategorySheetRows: ThunkActionFunc<[GID, string[]]> =
  (sheetId, entryIds) => (dispatch, getState) => {
    const { schema } = getState().modelReducer;
    const category = schema.categories.bySheet[sheetId];
    dispatch(addCategoryMembers({ id: category, members: entryIds }));
    dispatch(addRows({ id: sheetId, rows: entryIds }));
    dispatch(remember());
  };

export const renameSheet: ThunkActionFunc<[string, string]> =
  (id, name) => (dispatch, getState) => {
    const {
      sheets,
      schema: { categories },
    } = getState().modelReducer;
    if (sheets.byId[id].type === 'category') {
      dispatch(renameCategory({ id: categories.bySheet[id], name }));
    }
    dispatch(_renameSheet({ id, name }));
    dispatch(remember());
  };

export const deleteSheet: ThunkActionFunc<[string]> =
  (id) => (dispatch, getState) => {
    const {
      sheets,
      schema: { categories },
    } = getState().modelReducer;
    if (sheets.byId[id].type === 'category') {
      dispatch(deleteCategory({ id: categories.bySheet[id] }));
    }
    dispatch(_deleteSheet({ id }));
    dispatch(remember());
  };

export const createSheet = createAction<{ name: string; type: string }>(
  'model/sheet/CREATE'
);
export const _deleteSheet = createAction<{ id: GID }>('model/sheet/DELETE');
export const _renameSheet = createAction<{ id: GID; name: string }>(
  'model/sheet/RENAME'
);

export const setValue = createAction<{
  id: GID;
  rowId: GID;
  columnId: GID;
  value: string;
}>('model/sheet/value/SET');
export const addRows = createAction<{ id: GID; rows: string[] }>(
  'model/sheet/row/ADD_MANY'
);
export const addColumns = createAction<{ id: GID; columns: string[] }>(
  'model/sheet/column/ADD_MANY'
);
